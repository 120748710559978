export const enRecuperarPresupuesto = { 
    translation: {
        "Ingresa el ID del presupuesto que puedes encontrar en la": "Enter the ID of the budget that you can find in the ",
        "base de datos": "database",
        "para recuperar el presupuesto, modificarlo y/o enviarlo a firma.": " to recover the budget, modify it and/or send it for signature.",
        "ID del presupuesto": "Budget ID",
        "Siguiente": "Next",
        "Cargando...": "Loading...",
        "Fraccionamiento": "Subdivision",
        "Puesto": "Position",
        "¿Es el banco beneficiario?": "Is the bank the beneficiary?",
        "Entidad del préstamo": "Loan entity",
        "Número del préstamo": "Loan number",
        "Importe del préstamo": "Loan amount",
        "Fecha de vencimiento del préstamo": "Loan maturity date",
        "Tipo de préstamo": "Loan type",
        "Hipotecario": "Mortgage",
        "Consumo": "Consumption",
        "Personal": "Personal",
        "Beneficiarios": "Beneficiaries",
        "Cláusula estándar": "Standard clause",
        "Beneficiarios designados": "Designated beneficiaries",
        "Personas designadas": "Designated people",
        "Formato: nombre;dni;porcentaje. El porcentaje total debe ser 100%": "Format: name;dni;percentage. The total percentage must be 100%",
        "Dirección": "Address",
        "Código postal": "Postal code",
        "Carta Verde (Certificado de registro de ciudadano de la unión)": "Green Card (Certificate of registration of citizen of the union)",
        "Pasaporte": "Passport",
        "DNI nacional (Del país de origen)": "National ID (From the country of origin)",
        "TIE (Permiso de residencia)": "TIE (Residence permit)",
        "Fecha de caducidad del DNI/Documento": "Expiration date of the ID/Document",
        "Recibo de la póliza:": "Policy receipt:",
        "Póliza": "Policy",
        "Enviar solicitud": "Send request",
        "Volver": "Back",
        "Enviando...": "Sending...",
        "Si desea cambiar algún dato," : "If you want to change any data,",
        "vuelva a crear el presupuesto.": "create the budget again.",
        "Seguro de": "Insurance",
        "Precio": "Price",
        "Suma asegurada": "Insured amount",
        "Presupuesto": "Budget",
        "Enviar al cliente": "Send to the client",
        "Descargar": "Download",
        "Ver" : "View",
        "Solicitud enviada correctamente" : "Request sent successfully",
        "En breve el cliente recibirá por email los documentos para firmar": "The client will soon receive the documents to sign by email",
        "Fallecimiento por cualquier causa": "Death from any cause",
        "Fallecimiento e Invalidez por cualquier causa": "Death and Disability from any cause",
        "Recibo de la póliza": "Policy receipt",
        "Anualmente": "Annually",
        "Semestralmente": "Semi-annually",
        "Trimestralmente": "Quarterly",
        "Mensualmente": "Monthly",
        "Beneficiario": "Beneficiary",
        "Nombre del beneficiario": "Beneficiary name",
        "DNI del beneficiario": "Beneficiary ID",
        "Porcentaje del beneficiario": "Beneficiary percentage",
        "Añadir beneficiario": "Add beneficiary",
        "Eliminar beneficiario": "Delete beneficiary",
        "No puedes eliminar el último beneficiario": "You can't delete the last beneficiary",
        "No puedes añadir más de ": "You can't add more than ",
        " beneficiarios": " beneficiaries",
    }
};