export const enRootLayout = {
    translation: {
        "Ajustes" : "Settings",
        "Cerrar sesión" : "Log out",
        "Cargando..." : "Loading...",
        "Funcionando correctamente" : "Working properly",
        "No funciona" : "Not working",
        "Lento" : "Slowly",
        "Ver más" : "See more",
        "Inicio" : "Home",
        "Crear Presupuesto" : "Create Budget",
        "Recuperar Presupuesto" : "Recover Budget",
        "Base de Datos" : "Database",
        "Simuladores" : "Simulators",
        "Generar Documentos" : "Generate Documents",
        "Esta nueva versión está en fase de pruebas, por lo que puede contener errores. Si encuentras alguno, por favor, repórtalo a" : "This new version is in the testing phase, so it may contain errors. If you find any, please report it to",
        "nuestro equipo técnico" : "our technical team",
        "¡Entendido!" : "Got it!",
        "Novedades" : "News",
        "Mantenimiento" : "Maintenance",
        "Mantenimiento en" : "Maintenance in",
        "Alerta" : "Alert",
        "Notificaciones" : "Notifications",
        "No tienes notificaciones" : "You don't have notifications",
    }
};