//Festivos nacionales y locales de Valencia a 2024

const festivos = [
    {
        fecha: '2024-01-01',
        nombre: 'Año Nuevo',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-01-06',
        nombre: 'Epifanía del Señor',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-03-19',
        nombre: 'San José',
        tipo: 'Sustituible',
    },
    
    {
        fecha: '2024-03-29',
        nombre: 'Viernes Santo',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-04-01',
        nombre: 'Lunes de Pascua',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-06-24',
        nombre: 'San Juan',
        tipo: 'Local',
    },

    {
        fecha: '2024-08-15',
        nombre: 'Asunción de la Virgen',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-10-09',
        nombre: 'Día de la Comunidad Valenciana',
        tipo: 'Local',
    },

    {
        fecha: '2024-10-12',
        nombre: 'Fiesta Nacional de España',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-11-01',
        nombre: 'Todos los Santos',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-12-06',
        nombre: 'Día de la Constitución',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-12-08',
        nombre: 'Inmaculada Concepción',
        tipo: 'Nacional',
    },

    {
        fecha: '2024-12-25',
        nombre: 'Navidad',
        tipo: 'Nacional',
    },
];

export default festivos;

    


