export const enHome = {
    translation : {
        "Bienvenido de nuevo" : "Welcome back",
        "Número de registros según estado" : "Number of records by status",
        "Calcular presupuesto" : "Calculate budget",
        "Pendiente de firma" : "Pending signature",
        "Firmado" : "Signed",
        "Póliza creada" : "Policy created",
        "Últimos 5 registros pendientes de firma" : "Last 5 records pending signature",
        "Nombre" : "Name",
        "Teléfono" : "Phone",
        "No hay resultados" : "No results",

        //randomWelcomeMessages
        "Nos alegra verte de nuevo en tu panel de Coventia Office": "We are glad to see you again in your Coventia Office panel",
        "¿Qué tal estás hoy?": "How are you today?",
        "¿Cómo te va?": "How are you doing?",
        "lunes" : "monday",
        "martes" : "tuesday",
        "miércoles" : "wednesday",
        "jueves" : "thursday",
        "viernes" : "friday",
        "sábado" : "saturday",
        "domingo" : "sunday",
        "¿Qué tal va el" : "How is your",

    }
};