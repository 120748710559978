import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import propTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
    const { user, userPublicData } = useAuth();
    
    if (!user) {
        return <Navigate to="/auth/login" />;
    }

    if (userPublicData && userPublicData.onboarding == true && window.location.pathname != "/auth/onboarding") {
        return <Navigate to="/auth/onboarding" />;
    }
    return <>{children}</>
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
    children: propTypes.node.isRequired
}