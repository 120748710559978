export const enMsgbox = {
    translation : {
        'Responder a:':'Reply to:',
        'Contactar con:':'Contact with:',
        'Responder':'Reply',
        'Cliente':'Client',
        'Responder al mensaje de plantilla':'Reply to template message',
        '¡Alerta de advertencia!' :'Warning alert!' ,
        'El mensaje no se pudo enviar porque han pasado más de 24 horas desde la última vez que el cliente respondió a este número.':'Message failed to send because more than 24 hours have passed since the customer last replied to this number.',
        'Seleccione una conversación': 'Select a conversation',
    }
};