/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { supabaseClient } from "../config/supabase-clients";
import { GetCorreduria } from "../data/GetCorreduria";
import PropTypes from "prop-types";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState();
    const [user, setUser] = useState();
    const [userPublicData, setUserPublicData] = useState();
    const [correduria, setCorreduria] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setData = async () => {
            try {
                const getData = async () => {
                    var user_id;
                    await supabaseClient.auth.getSession().then(({ data: { session } }) => {
                        setSession(session)
                        setUser(session?.user ?? undefined);
                        user_id = session?.user.id;
                    });
                    await supabaseClient.from('users').select('*').filter('user_id', 'eq', user_id).single().then(({ data: userPublicData }) => {
                        setUserPublicData(userPublicData);
                    });
                    await supabaseClient.from('corredurias').select('*').single().then(({ data: correduria }) => {
                        setCorreduria(correduria);
                    });
                }
                await getData();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        setData();
    }, []);

    useEffect(() => {
        const channels = supabaseClient.channel('custom-update-channel')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'users', filter: 'user_id=eq.' + user?.id },
                (payload) => {
                    if (payload.new.user_id === user.id) {
                        setUserPublicData(payload.new);
                    }
                }
            )
            .subscribe()
    }, [user]);

    supabaseClient.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
            localStorage.removeItem("supabase.auth.token");
            localStorage.removeItem("supabase.auth.user");
            window.location.href = "/auth/login";
        }
    })

    const value = {
        session,
        user,
        userPublicData,
        correduria,
        signOut: async () => {
            await supabaseClient.auth.signOut();
        }
    };


    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};
