/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import { createClient } from "@supabase/supabase-js";
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Onboarding = () => {
    const { t, i18n } = useTranslation();
    const { user, userPublicData } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    document.title = "Coventia Office | Onboarding"

    async function handleSubmit(event) {
        event.preventDefault()
        setLoading(true)
        // Comprobar que las contraseñas coinciden
        if (event.target["profile-lock"].value != event.target["profile-lock-confirm"].value) {
            toast.error(t("Las contraseñas no coinciden"))
            setLoading(false)
            return false;
        }
        // Comprueba que tenga al menos 8 caracteres
        if (event.target["profile-lock"].value.length < 8) {
            toast.error(t("La contraseña debe tener al menos 8 caracteres"))
            setLoading(false)
            return false;
        }
        var supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        );
        // Actualizar la contraseña
        const { data, error } = await supabaseClient.auth.updateUser({
            password: event.target["profile-lock"].value
        })
        if (error) {
            toast.error(error.message)
            setLoading(false)
            return false;
        }
    }

    useEffect(() => {
        var supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        );
        supabaseClient.auth.onAuthStateChange(async (event, session) => {
            if (event === "USER_UPDATED") {
                var supabaseClient = createClient(
                    'https://uoclqlfzojdjrhcqhgef.supabase.co',
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                );
                const { data, error } = await supabaseClient.from('users').update({ onboarding: false }).eq('user_id', user.id).select();
                if (error) {
                    toast.error(error.message)
                    setLoading(false)
                    return false;
                }
                setTimeout(() => {
                    navigate("/", {state: {onboarding: true} });
                }, 2000);
            }
        });
    }, [])

    return (
        <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
            <div className="w-full max-w-md bg-white rounded-lg shadow md:mt-0 xl:p-0 dark:bg-gray-800">
                <div className="w-full p-6 sm:p-8">
                    <div className="flex space-x-4">
                        <h2 className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">¡Hola {userPublicData.first_name}! 👋🏻</h2>
                    </div>
                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        {t('Bienvenido a Coventia Office. Por motivos de seguridad, necesitamos que actualices tu contraseña. Esto solo lo tendrás que hacer una vez.')}
                    </p>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="profile-lock" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Nueva contraseña')}</label>
                            <input type="password" name="profile-lock" id="profile-lock" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required></input>
                        </div>
                        <div>
                            <label htmlFor="profile-lock-confirm" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Confirmar contraseña')}</label>
                            <input type="password" name="profile-lock-confirm" id="profile-lock-confirm" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required></input>
                        </div>
                        <button type="submit" className={"inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (loading ? "hidden" : "")}>
                            <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"></path></svg>
                            {t('Completar')}
                        </button>
                        <button disabled className={"inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 " + (loading ? "" : "hidden")}>
                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                            </svg>
                            {t('Guardando...')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};