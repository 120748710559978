/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { createClient } from "@supabase/supabase-js";
import axios from 'axios';

import { saveAs } from "file-saver";
import { CheckCircleIcon, XCircleIcon, DocumentIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { validarDNIoNIF } from '../../utils/validarDNIoNIF';
import { validarTelefono } from '../../utils/validarTelefono';
import { buscarDNIEnBaserow } from '../../utils/buscarDNIEnBaserow';
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next";
import { Dialog } from '@headlessui/react';
import { set } from 'lodash';




export const FormApud = ({ step3Loading, setStep3Loading, setCurrentStep }) => {
    const { t } = useTranslation();
    const [isDocumentCorrect, setIsDocumentCorrect] = useState('yes');
    const [fileName, setFileName] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [cantidadPersonasGE, setCantidadPersonasGE] = useState(1);
    const [file, setFile] = useState(null);
    const [fileApud, setFileApud] = useState({ name: '', blob: null });  // Estado para el archivo Apud.
    const [successSign, setSuccessSign] = useState(false);

    const [step, setStep] = useState(1);
    const inputRef = useRef();
    const postalCodeRef = useRef();
    const provinciaRef = useRef();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [autoFill, setAutoFill] = useState({
        'numero_negocio_gastos': '',
        'cantidad_personas_gastos': 1,
        'nombre_y_apellidos_gastos': '',
        'dni_gastos': '',
        'provincia_gastos': '',
        'email_gastos': '',
        'telefono_gastos': '',
        'telefono2_gastos': '',
        'nombre_y_apellidos2_gastos': '',
        'dni2_gastos': '',
    });
    const validacionDNIoNIF = validarDNIoNIF;

    const downloadFile = async (response, index, setFileFunction) => {
        const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[index];
        const resp = await fetch(url);
        const blob = await resp.blob();
        const fileName = response.data.documentos_generados[index];
        saveAs(blob, fileName);
        setFileFunction({ name: fileName, blob: blob });
        //SetFileApud del documento que es PDF
        if (index === 1) {
            setFileApud({ name: fileName, blob: blob });
        }

    }

    //Use Effect para usar la api google places
    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.setComponentRestrictions({
            'country': ['es']
        });
        autocomplete.setFields(['address_component']);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var city = '';
            var country = '';
            var province = '';
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == 'locality') {
                    city = place.address_components[i]['long_name'];
                }
                if (addressType == 'country') {
                    country = place.address_components[i]['long_name'];
                }
                if (addressType == 'administrative_area_level_2') {
                    province = place.address_components[i]['long_name'];
                }
            }
            var address = inputRef.current.value;
            var index = address.indexOf(city);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            index = address.indexOf(country);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            inputRef.current.value = address;
            provinciaRef.current.value = province;
            setAutoFill(prevState => ({ ...prevState, direccion_gastos: address, provincia_gastos: province }));
        });


        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var postal_code = '';
            for (var i = 0; i < place.address_components.length; i++) {
                for (var j = 0; j < place.address_components[i].types.length; j++) {
                    if (place.address_components[i].types[j] == "postal_code") {
                        postal_code = place.address_components[i].long_name;
                    }
                }
            }
            postalCodeRef.current.value = postal_code;
            setAutoFill(prevState => ({ ...prevState, codigo_postal_gastos: postal_code }));
        });
    }, [step]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStep3Loading(true);
        const dataApud = {
            'cantidad_personas_gastos': cantidadPersonasGE,
            'nombre_y_apellidos_gastos': autoFill.nombre_y_apellidos_gastos,
            'dni_gastos': autoFill.dni_gastos,
            'provincia_gastos': autoFill.provincia_gastos,
            'email_gastos': autoFill.email_gastos,
            'telefono_gastos': autoFill.telefono_gastos,
            'telefono2_gastos': autoFill.telefono2_gastos,
            'nombre_y_apellidos2_gastos': autoFill.nombre_y_apellidos2_gastos,
            'dni2_gastos': autoFill.dni2_gastos,
            'direccion_gastos': autoFill.direccion_gastos,
            'codigo_postal_gastos': autoFill.codigo_postal_gastos,
        };

        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.auth.getSession()
        const access_token = data.session.access_token

        //pasar a la url los datos del formulario
        try {
            axios.post("https://api.office.coventia.es/enviar-datos-apud/", dataApud, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': access_token
                }
            }).then(response => {
                try {
                    //Obtener la promesa de descarga de los documentos
                    const downloadPromise = new Promise((resolve, reject) => {
                        response.data.documentos_generados.forEach((doc, index) => {
                            downloadFile(response, index, setFile);
                        });
                        resolve();
                    });
                    //Esperar a que se descargue LOS documentos
                    downloadPromise.then(() => {
                        setStep3Loading(false);
                        setStep(2);
                        //Extraer nombre del documento y añadirlo al estado fileName
                        setFileName(response.data.documentos_generados[1]);


                    });
                } catch (error) {
                    setStep3Loading(false);
                    console.error(error);
                    setStep(1);
                }

            }).catch(error => {
                console.error(error);
                setStep3Loading(false);
                setStep(1);
            }
            );
        }
        catch (error) {
            console.error(error);
            setStep3Loading(false);
            setStep(1);
        }
    }

    if (step == 2) {

        const handleSubmitStep2 = async (e) => {
            e.preventDefault();
            setStep3Loading(true);

            let formData = new FormData();

            if (isDocumentCorrect === 'no') {
                // Si se sube un nuevo archivo
                if (!e.target.apudFileCorrected.files[0]) {
                    toast.error("Por favor, sube el documento de APUD corregido");
                    setStep3Loading(false);
                    return;
                }
                formData.append('apud', e.target.apudFileCorrected.files[0]);  // Archivo subido por el usuario.
            } else {
                // Si el archivo anterior es correcto, usa el archivo descargado previamente
                if (!fileApud.blob) {
                    toast.error("No se encontró el archivo APUD anterior.");
                    setStep3Loading(false);
                    return;
                }
                formData.append('apud', new File([fileApud.blob], fileApud.name));  // Archivo descargado previamente.
            }

            //FormData tiene que tener cantidad_personas, el nombre y el email del formulario rellenado anteriormente
            formData.append('cantidad_personas', autoFill.cantidad_personas_gastos);
            formData.append('nombre', autoFill.nombre_y_apellidos_gastos);
            formData.append('email', autoFill.email_gastos);
            formData.append('id_negocio', autoFill.numero_negocio_gastos);

            try {

                const response = await axios.post("https://api.office.coventia.es/enviar-solicitud-firma-apud/", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(response => {
                        toast.success("¡Archivo enviado correctamente para firma!");
                        if (response.status === 200) {
                            setStep3Loading(false);
                            setSuccessSign(true);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        toast.error("Ha ocurrido un error inesperado");
                        setStep3Loading(false);
                    });

            } catch (error) {
                console.error(error);
                toast.error("Ha ocurrido un error inesperado");
                setStep3Loading(false);
            }
        };


        const handleRadioChange = (event) => {
            setIsDocumentCorrect(event.target.value);
        };

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                setFileName(file.name);
                setFile(file);
            }
        };

        const handleDrop = (event) => {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file) {
                setFileName(file.name);
                setFileApud({ name: file.name, blob: file });
            }
        };

        const handleDragOver = (event) => {
            event.preventDefault();

        };
        return (
            <>
                <div className="flex flex-col md:flex-row items-end justify-end space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                    <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                        <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setStep(1) }}>
                            Volver atrás
                        </button>
                    </div>
                </div>

                <div className={`max-w-2xl mx-auto mt-10 p-6 bg-white rounded-xl shadow-lg ${successSign ? "hidden" : ""}`}>
                    <h1 className="text-3xl font-bold mb-6 text-gray-800">Enviar documento a firmar</h1>

                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-2 text-gray-700">Resumen del documento</h2>
                        <div className="flex items-center p-4 bg-gray-50 rounded-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            <div>
                                <p className="font-medium text-gray-800">{fileName}</p>
                            </div>
                        </div>
                    </div>

                    <form className="space-y-6" onSubmit={handleSubmitStep2}>
                        <div>
                            <label className="text-lg font-medium text-gray-700">¿Está correcto el documento?</label>
                            <p className="text-sm text-gray-500 mb-3">Por favor, confirma si el documento está listo para ser enviado.</p>
                            <div className="flex space-x-4">
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="documentCorrect"
                                        value="yes"
                                        checked={isDocumentCorrect === 'yes'}
                                        onChange={handleRadioChange}
                                        className="form-radio h-5 w-5 text-green-600"
                                    />
                                    <span className="ml-2 text-gray-700">Sí, está correcto</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="documentCorrect"
                                        value="no"
                                        checked={isDocumentCorrect === 'no'}
                                        onChange={handleRadioChange}
                                        className="form-radio h-5 w-5 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700">No, necesita cambios</span>
                                </label>
                            </div>
                        </div>

                        {isDocumentCorrect === 'no' && (
                            <div
                                className="transition-all duration-300 ease-in-out"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <label htmlFor="fileInput" className="block text-lg font-medium text-gray-700 mb-2">
                                    Subir nuevo documento
                                </label>
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="fileInput"
                                                className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                            >
                                                <span>Seleccionar archivo</span>
                                                <input id="apudFileCorrected" name="apudFileCorrected" type="file" className="absolute opacity-0 top-0 left-0" onChange={handleFileChange} />
                                            </label>
                                            <p className="pl-1">o arrastrar y soltar</p>
                                        </div>
                                        <p className="text-xs text-gray-500">PDF hasta 10MB</p>
                                    </div>
                                </div>
                                {fileName && (
                                    <p className="mt-2 text-sm text-gray-600">
                                        Archivo seleccionado: {fileName}
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Enviar a firmar
                            </button>
                        </div>
                    </form>
                </div>

                <div className={"col-span-2 sm:col-span-3 " + (successSign ? "" : "hidden")}>
                    <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-10 "}>
                        <div className="flex flex-col items-center justify-center mt-12">
                            <svg className="w-12 h-12 text-gray-800 dark:text-white mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <h3 className="text-2xl font-bold text-gray-800 dark:text-white">{t('Solicitud enviada correctamente')}</h3>
                            <p className="text-gray-600 dark:text-gray-400">{t('En breve el cliente recibirá por email los documentos para firmar')}</p>
                        </div>
                        <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-center  flex-shrink-0">
                            <button className="w-64 flex justify-center mt-10 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setStep(1); setSuccessSign(false); }}>
                                {t('¡Listo!')}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    return (
        <>

            <form onSubmit={handleSubmit} >
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                    <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        <>
                            🖋  Rellenando documento de
                            <span className=" underline decoration-primary-500"> APUD Acta</span>
                        </>
                    </h2>
                    <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                        <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setCurrentStep(1) }}>
                            Volver atrás
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">

                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="cantidad_personas_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Cantidad de personas")} *</label>
                        <select disabled={step3Loading} id="cantidad_personas_gastos" name="cantidad_personas_gastos" autoComplete="cantidad_personas_gastos" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={(e) => { setCantidadPersonasGE(e.target.value) }}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="dni_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI *</label>
                        <input
                            disabled={step3Loading}
                            type="text"
                            value={autoFill.dni_gastos}
                            name="dni_gastos"
                            id="dni_gastos"
                            autoComplete="dni_gastos"
                            className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="DNI"
                            required
                            onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                            onBlur={async (e) => {
                                if (validacionDNIoNIF(e.target.value)) {
                                    const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');
                                    if (datos.length > 0) {
                                        if (datos[1].length === 1 || datos[1].length === 0) {
                                            // Rellenar el formulario con los datos
                                            const updatedAutoFill = {
                                                ...autoFill,
                                                codigo_postal_gastos: datos[0]?.['Codigo Postal'],
                                                dni_gastos: datos[0]?.['DNI'],
                                                telefono_gastos: datos[0]?.['Telefono'],
                                                direccion_gastos: datos[0]?.['Direccion'],
                                                email_gastos: datos[0]?.['Email'],
                                                provincia_gastos: datos[0]?.['Provincia'],
                                                nombre_y_apellidos_gastos: datos[0]?.['Nombre Y Apellidos'],
                                            };

                                            // Copiar valores de campos `_gastos` a los correspondientes `_apud`
                                            Object.keys(updatedAutoFill).forEach((key) => {
                                                if (key.endsWith('_gastos')) {
                                                    const apudKey = key.replace('_gastos', '_apud');
                                                    if (updatedAutoFill[apudKey] === "") {
                                                        updatedAutoFill[apudKey] = updatedAutoFill[key];
                                                    }
                                                }
                                            });

                                            setAutoFill(updatedAutoFill);

                                            // handleChangeFechaInicioPrestamo({ target: { name: 'fecha_inicio_prestamo_gastos', value: datos[1]?.['Fecha Inicio Prestamo'] } });
                                        }

                                        if (datos[1].length > 1) {
                                            setOpen(true);
                                            // Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                            let datosCompletos = [];
                                            datos[1].forEach((element) => {
                                                let { Email, ...rest } = element; // Excluir Email de datos[1] (provisional) de cada elemento
                                                datosCompletos.push({ ...datos[0], ...rest }); // Combinar datos[0] con el resto de cada elemento
                                            });
                                            setData(datosCompletos);
                                        }
                                    }
                                } else {
                                    toast.error("El DNI introducido no es válido");
                                }
                            }}
                        >
                        </input>
                    </div>
                    {/* DNI 2 */}
                    <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                        <label htmlFor="dni2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI 2</label>
                        <input
                            disabled={step3Loading}
                            type="text"
                            value={autoFill.dni2_gastos}
                            name="dni2_gastos"
                            id="dni2_gastos"
                            autoComplete="dni2_gastos"
                            className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="DNI 2"
                            onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                            onBlur={async (e) => {
                                if (validacionDNIoNIF(e.target.value)) {
                                    const datos = await buscarDNIEnBaserow(e.target.value, 'Gastos');

                                    if (datos.length > 0) {

                                        if (datos[1].length == 1 || datos[1].length == 0) {
                                            // Rellenar el formulario con los datos
                                            setAutoFill({
                                                ...autoFill,
                                                dni2_gastos: datos[0]?.['DNI'],
                                                telefono2_gastos: datos[0]?.['Telefono'],
                                                nombre_y_apellidos2_gastos: datos[0]?.['Nombre Y Apellidos'],
                                            });
                                        }
                                        if (datos[1].length > 1) {
                                            setOpen(true);
                                            //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                            let datosCompletos = []
                                            datos[1].forEach((element) => {
                                                let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                            })
                                            setData(datosCompletos);
                                        }
                                    }
                                } else {
                                    toast.error(t("El DNI introducido no es válido"));
                                }
                            }}
                        >
                        </input>
                    </div>

                    <div className='col-span-2 sm:col-span-2'>
                        <label htmlFor="numero_negocio_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Número de negocio *</label>
                        <input
                            type="text"
                            value={autoFill.numero_negocio_gastos}
                            name="numero_negocio_gastos"
                            id="numero_negocio_gastos"
                            autoComplete="numero_negocio_gastos"
                            className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Número de negocio"
                            onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="nombre_y_apellidos_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos")} *</label>
                        <input disabled={step3Loading} value={autoFill.nombre_y_apellidos_gastos} type="text" name="nombre_y_apellidos_gastos" id="nombre_y_apellidos_gastos" autoComplete="nombre_y_apellidos_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre y Apellidos")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                        <label htmlFor="nombre_y_apellidos2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Nombre y apellidos 2")}</label>
                        <input disabled={step3Loading} value={autoFill.nombre_y_apellidos2_gastos} type="text" name="nombre_y_apellidos2_gastos" id="nombre_y_apellidos2_gastos" autoComplete="nombre_y_apellidos2_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder={t("Nombre y Apellidos 2")}></input>
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="email_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email *</label>
                        <input disabled={step3Loading} value={autoFill.email_gastos} type="email" name="email_gastos" id="email_gastos" autoComplete="email_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>
                    <div className='col-span-2 sm:col-span-2'>
                        <label htmlFor="telefono_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Teléfono *</label>
                        <input disabled={step3Loading} value={autoFill.telefono_gastos} type="text" name="telefono_gastos" id="telefono_gastos" autoComplete="telefono_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Teléfono" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>
                    {/* Teléfono 2 */}
                    <div className='col-span-2 sm:col-span-2' style={{ display: cantidadPersonasGE == 2 ? "block" : "none" }}>
                        <label htmlFor="telefono2_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Teléfono 2</label>
                        <input disabled={step3Loading} value={autoFill.telefono2_gastos} type="text" name="telefono2_gastos" id="telefono2_gastos" autoComplete="telefono2_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Teléfono 2" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                    </div>

                    {/* Direccion */}
                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="direccion_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Dirección")} *</label>
                        <input disabled={step3Loading} ref={inputRef} value={autoFill.direccion_gastos} type="text" name="direccion_gastos" id="direccion_gastos" autoComplete="direccion_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Dirección")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>

                    {/* Codigo Postal */}
                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="codigo_postal_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Código Postal")} *</label>
                        <input disabled={step3Loading} ref={postalCodeRef} value={autoFill.codigo_postal_gastos} type="text" name="codigo_postal_gastos" id="codigo_postal_gastos" autoComplete="codigo_postal_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Código Postal")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>

                    {/* Provincia */}
                    <div className="col-span-2 sm:col-span-2">
                        <label htmlFor="provincia_gastos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Provincia")} *</label>
                        <input disabled={step3Loading} ref={provinciaRef} value={autoFill.provincia_gastos} type="text" name="provincia_gastos" id="provincia_gastos" autoComplete="provincia_gastos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Provincia")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                    </div>

                    <div className={"col-span-2 sm:col-span-2 " + (step3Loading ? "hidden" : "")}>
                        <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                            {t("Siguiente")}
                        </button>
                    </div>
                    <div className={"col-span-2 sm:col-span-2 " + (step3Loading ? "" : "hidden")}>
                        <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                            {t('Cargando...')}
                        </button>
                    </div>

                </div>
            </form>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                transition
                className="fixed z-50 inset-0 overflow-y-auto sm:px-0 md:px-8 lg:px-16 xl:px-32 2xl:px-64 flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-500 bg-opacity-75 transition duration-300 ease-out data-[closed]:opacity-0 dark:bg-gray-900 dark:bg-opacity-75"
            >
                <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full dark:bg-gray-800 dark:text-gray-200">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
                        <div className="overflow-x-auto rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-200">
                                    <tr>
                                        <th scope="col" className="px-4 py-3"></th>
                                        <th scope="col" className="px-4 py-3">DNI</th>
                                        <th scope="col" className="px-4 py-3">Nombre y Apellidos</th>
                                        <th scope="col" className="px-4 py-3">Email</th>
                                        <th scope="col" className="px-4 py-3">Teléfono</th>
                                        <th scope="col" className="px-4 py-3">Dirección</th>
                                        <th scope="col" className="px-4 py-3">Código Postal</th>
                                        <th scope="col" className="px-4 py-3">Fecha Inicio Préstamo</th>
                                        <th scope="col" className="px-4 py-3">Fecha Reclamación Extrajudicial</th>
                                        <th scope="col" className="px-4 py-3">Notario</th>
                                        <th scope="col" className="px-4 py-3">Provincia</th>
                                        <th scope="col" className="px-4 py-3">Banco</th>
                                        <th scope="col" className="px-4 py-3">Número Negocio</th>
                                        <th scope="col" className="px-4 py-3">Número Protocolo</th>
                                        <th scope="col" className="px-4 py-3">Gastos Notaría</th>
                                        <th scope="col" className="px-4 py-3">Gastos Registro</th>
                                        <th scope="col" className="px-4 py-3">Gastos Gestoría</th>
                                        <th scope="col" className="px-4 py-3">Gastos Tasación</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700 rounded-xl">
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => setSelectedRow(item)}
                                            className={selectedRow === item ? 'bg-blue-100 dark:bg-gray-700' : 'border-b dark:border-gray-700 hover:bg-gray-50 cursor-pointer dark:hover:bg-gray-700'}
                                        >
                                            <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white flex items-center justify-center">
                                                <input type="radio" checked={selectedRow === item} className="text-primary-600" readOnly />
                                            </th>
                                            <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white">{item['DNI']}</th>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Nombre Y Apellidos']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Email']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Telefono']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Direccion']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Codigo Postal']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Fecha Inicio Prestamo']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Fecha Reclamacion Extrajudicial']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Notario']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Provincia']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Banco']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Numero Negocio']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Numero Protocolo']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Gastos Notaria']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Gastos Registro']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Gastos Gestoria']}</td>
                                            <td className="px-4 py-3 dark:text-gray-400">{item['Gastos Tasacion']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-800">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    setOpen(false);
                                    setAutoFill({
                                        ...autoFill,
                                        dni_gastos: selectedRow['DNI'],
                                        email_gastos: selectedRow['Email'],
                                        telefono_gastos: selectedRow['Telefono'],
                                        fecha_inicio_prestamo_gastos: selectedRow['Fecha Inicio Prestamo'],
                                        fecha_extrajudicial_gastos: selectedRow['Fecha Reclamacion Extrajudicial'],
                                        notario_gastos: selectedRow['Notario'],
                                        nombre_y_apellidos_gastos: selectedRow['Nombre Y Apellidos'],
                                        direccion_gastos: selectedRow['Direccion'],
                                        codigo_postal_gastos: selectedRow['Codigo Postal'],
                                        nprotocolo_gastos: selectedRow['Numero Protocolo'],
                                        provincia_gastos: selectedRow['Provincia'],
                                        banco_gastos: selectedRow['Banco'],
                                        numero_negocio_gastos: selectedRow['Numero Negocio'],
                                        gastos_notaria_gastos: selectedRow['Gastos Notaria'],
                                        gastos_registro_gastos: selectedRow['Gastos Registro'],
                                        gastos_gestoria_gastos: selectedRow['Gastos Gestoria'],
                                        gastos_tasacion_gastos: selectedRow['Gastos Tasacion'],
                                    });
                                }}
                            >
                                Seleccionar
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => setOpen(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}
