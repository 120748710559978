export const calculateChartData = (n, type) => {
    const yearlyIncrease = [
        1.13586847488493,
        1.13639442788812,
        1.13396947554079,
        1.13254071374239,
        1.1289366963584,

        1.10706975507315,
        1.10534665690075,
        1.10308611702904,
        1.10104889896381,
        1.09934048956884,

        1.09839182213153,
        1.09770595150049,
        1.09747553316277,
        1.09719493901754,
        1.097297553043,

        1.09725713135014,
        1.09743045100341,
        1.09793301145147,
        1.0984083196336,
    ];

    let currentPolicy = [n];
    let coventiaPolicy;
    if (type === 'Banco'){
        coventiaPolicy = [n * 0.7];
    }
    else coventiaPolicy = [n * 0.85];

    let yearSavings = [currentPolicy[0] - coventiaPolicy[0]];
    // Cálculo de los precios/ahorros en 20 años
    for (let i = 1; i < 20; i++) {
        currentPolicy.push(Math.round(currentPolicy[i - 1] * yearlyIncrease[i - 1]));
        coventiaPolicy.push(Math.round(coventiaPolicy[i - 1] * yearlyIncrease[i - 1]));
        yearSavings.push(Math.round(currentPolicy[i] - coventiaPolicy[i]));
    }

    let currentPolicyData = currentPolicy.map((value, index) => {
        return {
            date: (new Date().getFullYear() + index).toString(),
            value: value,
        };
    });

    let coventiaPolicyData = coventiaPolicy.map((value, index) => {
        return {
            date: (new Date().getFullYear() + index).toString(),
            value: value,
        };
    });

    let yearSavingsData = yearSavings.map((value, index) => {
        return {
            date: (new Date().getFullYear() + index).toString(),
            value: value,
        };
    });

    return {
        currentPolicy: currentPolicyData,
        coventiaPolicy: coventiaPolicyData,
        yearSavings: yearSavingsData,
    };
};