/* eslint-disable no-unused-vars */
import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react"
import toast from "react-hot-toast";
import { createClient } from "@supabase/supabase-js";
import propTypes from "prop-types";
import { formatDate as formatDateUtil, validarDNIoNIF, buscarDNIEnBaserow, validarTelefono } from '../../utils'
import { formCompanies } from '../../data/formCompanies'
import { saveAs } from "file-saver";
import { FormPrima2 } from "./FormPrima2";
import { useTranslation } from "react-i18next";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';

export const FormPrima = ({ step2Loading, setStep2Loading, setCurrentStep }) => {
    const { t } = useTranslation();
    const [cantidadPersonasPU, setCantidadPersonasPU] = useState(1);
    const [segundaFecha, setSegundaFecha] = useState("false");
    const [numeroNegocioPrima, setNumeroNegocioPrima] = useState('');
    const [formValues, setFormValues] = useState({});
    const [step, setStep] = useState(1);
    const [hubspotData, setHubspotData] = useState({});
    const [autoFill, setAutoFill] = useState({});
    const formatDate = formatDateUtil
    const companies = formCompanies
    const validacionDNIoNIF = validarDNIoNIF
    const validacionTelefono = validarTelefono;

    const inputRef = useRef();
    const postalCodeRef = useRef();
    const provinciaRef = useRef();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [contratoFile, setContratoFile] = useState(null);

    async function handleSubmitStep2(event) {
        event.preventDefault()
        setStep2Loading(true)
        try {

            setFormValues({
                cantidad_personas: event.target["cantidad_personas"].value,
                nombre: event.target["nombre_y_apellidos"].value,
                email: event.target["email"].value,
                id_negocio: event.target["numero_negocio_prima"].value,
            })

            setHubspotData({
                "tipo_formulario": "prima_unica",
                "dni": event.target["dni"].value,
                "direccion": event.target["direccion"].value,
                "banco_origen": event.target["banco"].value,
                "prima_unica": event.target["prima_unica"].value,
                "fecha_inicio": event.target["fecha_inicio"].value,
                "fecha_fin": event.target["fecha_fin"].value,
                "id_negocio": event.target["numero_negocio_prima"].value,
            });

            var formData = new FormData(event.target);
            formData.set("tipo_formulario", "prima_unica")
            formData.set("fecha_inicio", formatDate(event.target["fecha_inicio"].value))
            formData.set("fecha_fin", formatDate(event.target["fecha_fin"].value))
            if (event.target["fecha_inicio2"].value && event.target["fecha_fin2"].value) {
                formData.set("fecha_inicio2", formatDate(event.target["fecha_inicio2"].value))
                formData.set("fecha_fin2", formatDate(event.target["fecha_fin2"].value))
            }
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )
            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            // "https://api.office.coventia.es/enviar-datos-prima-unica/"
            axios.post("https://api.office.coventia.es/enviar-datos-prima-unica/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': access_token
                }
            }).then(async (response) => {
                try {

                    //Array para almacenar todas las promesas de descarga
                    let downloadPromises = []

                    //Descargar los primeros 2 archivos
                    for (let i = 0; i < 2; i++) {
                        const url = "https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[i];
                        const fileName = response.data.documentos_generados[i];
                        const blob = await fetch(url).then(r => r.blob());
                        saveAs(blob, fileName);
                    }

                    //Descargar el último archivo y almacenar las promesas en el array
                    downloadPromises.push(
                        fetch("https://api.office.coventia.es/media/generated/" + response.data.documentos_generados[2])
                            .then(r => r.blob())
                            .then(blob => {
                                const fileName = response.data.documentos_generados[2];
                                saveAs(blob, response.data.documentos_generados[2]);
                                setContratoFile({ name: fileName, blob: blob });
                            })
                    );
                    await Promise.all(downloadPromises);

                    toast.success(t("¡Datos descargados correctamente!"))
                    setStep2Loading(false)

                    if (numeroNegocioPrima !== '') {
                        setStep(2)
                    }

                } catch (error) {
                    console.log(error)
                    toast.error(t("Ha ocurrido un error inesperado"))
                    setStep2Loading(false)
                }
            }).catch((error) => {

                (error)
                toast.error(t("Ha ocurrido un error inesperado"))
                setStep2Loading(false)
            })
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setStep2Loading(false)
        }
    }


    const handleInputChangeAndSetAutoFill = (e) => {
        setNumeroNegocioPrima(e.target.value);
        setAutoFill({ ...autoFill, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.setComponentRestrictions({
            'country': ['es']
        });
        autocomplete.setFields(['address_component']);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var city = '';
            var country = '';
            var province = '';
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (addressType == 'locality') {
                    city = place.address_components[i]['long_name'];
                }
                if (addressType == 'country') {
                    country = place.address_components[i]['long_name'];
                }
                if (addressType == 'administrative_area_level_2') {
                    province = provinciaRef.current.value = place.address_components[i]['long_name'];
                }
            }
            var address = inputRef.current.value;
            var index = address.indexOf(city);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            index = address.indexOf(country);
            if (index >= 0) {
                address = address.substr(0, index);
            }
            inputRef.current.value = address;
            provinciaRef.current.value = province;
            console.log('PROVINCE: ', province)
            setAutoFill(prevState => ({ ...prevState, direccion: address, provincia: province }));
        });

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            var postal_code = '';
            for (var i = 0; i < place.address_components.length; i++) {
                for (var j = 0; j < place.address_components[i].types.length; j++) {
                    if (place.address_components[i].types[j] == "postal_code") {
                        postal_code = place.address_components[i].long_name;
                    }
                }
            }
            postalCodeRef.current.value = postal_code;
            setAutoFill(prevState => ({ ...prevState, codigo_postal_prima: postal_code }))
        });
    }, [step]);


    return (
        <>
            {step === 1 && (

                <form onSubmit={handleSubmitStep2}>
                    {/* Creamos un bloque donde van a ir dos columnas */}
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                        <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🖋&nbsp;&nbsp;{t('Rellenando documento de')} <span className=" underline decoration-primary-500">{t('Prima Única')}</span></h2>
                        <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                            {/* Aquí deben ir un botón para crear un nuevo usuario */}
                            <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setCurrentStep(1) }}>
                                {t('Volver atrás')}
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        {/* Necesitamos dividirlo en dos columnas, para que haya dos inputs por fila (solo en escritorio) */}
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="plantilla_prima_unica" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Plantilla de Prima Única')} *</label>
                            <select value={autoFill.plantilla_prima_unica} disabled={step2Loading} id="plantilla_prima_unica" name="plantilla_prima_unica" autoComplete="plantilla_prima_unica" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required>
                                <option value="defecto">{t('Por defecto')}</option>
                                <option value="caixabank">CaixaBank</option>
                                <option value="popular_santander">Popular-Santader</option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="cantidad_personas" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Cantidad de personas')} *</label>
                            <select value={autoFill.cantidad_personas} disabled={step2Loading} id="cantidad_personas" name="cantidad_personas" autoComplete="cantidad_personas" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={(e) => { setCantidadPersonasPU(e.target.value); setAutoFill({ ...autoFill, [e.target.name]: e.target.value }) }}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="dni" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI *</label>
                            <input
                                disabled={step2Loading}
                                type="text" name="dni"
                                id="dni"
                                autoComplete="dni"
                                className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="DNI"
                                required
                                value={autoFill.dni}
                                onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                onBlur={async (e) => {
                                    if (validacionDNIoNIF(e.target.value)) {
                                        const datos = await buscarDNIEnBaserow(e.target.value, 'Prima');
                                        if (datos.length > 0) {
                                            if (datos[1].length == 1 || datos[1].length == 0) {
                                                //Juntar los datos de datos[0] con el de datos[1]

                                                // Rellenar el formulario con los datos
                                                setAutoFill({
                                                    ...autoFill,
                                                    banco: datos[1][0]?.['Banco'],
                                                    codigo_postal_prima: datos[0]?.['Codigo Postal'],
                                                    dni: datos[0]?.['DNI'],
                                                    direccion: datos[0]?.['Direccion'],
                                                    email: datos[0]?.['Email'],
                                                    fecha_fin: datos[1][0]?.['Fecha Fin'],
                                                    fecha_inicio: datos[1][0]?.['Fecha Inicio'],
                                                    nombre_y_apellidos: datos[0]?.['Nombre Y Apellidos'],
                                                    pol_cert: datos[1][0]?.['POL/CERT'],
                                                    prima_unica: datos[1][0]?.['Prima Unica'],

                                                });
                                            }
                                            if (datos[1].length > 1) {
                                                setOpen(true);
                                                //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                let datosCompletos = []
                                                datos[1].forEach((element) => {
                                                    let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                    datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                })
                                                setData(datosCompletos);
                                            }
                                        }
                                    } else {
                                        toast.error(t("El DNI introducido no es válido"));
                                    }
                                }}
                            >
                            </input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label htmlFor="dni2" className="block text-sm font-medium text-gray-700 dark:text-gray-400">DNI 2 *</label>
                            <input
                                disabled={step2Loading}
                                type="text"
                                name="dni2"
                                id="dni2"
                                autoComplete="dni2"
                                className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="DNI 2"
                                value={autoFill.dni2}
                                onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                onBlur={async (e) => {
                                    if (validacionDNIoNIF(e.target.value)) {
                                        const datos = await buscarDNIEnBaserow(e.target.value, 'Prima');

                                        if (datos.length > 0) {
                                            if (datos[1].length == 1 || datos[1].length == 0) {
                                                //Juntar los datos de datos[0] con el de datos[1]
                                                let datosCompletos = { ...datos[0], ...datos[1][0] }
                                                // Rellenar el formulario con los datos
                                                setAutoFill({
                                                    ...autoFill,
                                                    dni2: datos[0]?.['DNI'],
                                                    nombre_y_apellidos2: datos[0]?.['Nombre Y Apellidos'],
                                                    prima_unica2: datos[1][0]?.['Prima Unica'],
                                                });
                                            }
                                            if (datos[1].length > 1) {
                                                setOpen(true);
                                                //Juntar los datos de datos[0] con cada uno de los datos de datos[1]
                                                let datosCompletos = []
                                                datos[1].forEach((element) => {
                                                    let { Email, ...rest } = element; // Excluir Email de cada elemento
                                                    datosCompletos.push({ ...datos[0], ...rest }) // Combinar datos[0] con el resto de cada elemento
                                                })
                                                setData(datosCompletos);
                                            }
                                        }
                                    } else {
                                        toast.error(t("El DNI introducido no es válido"));
                                    }
                                }}                                                   >
                            </input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="numero_negocio_prima" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Número de negocio')} *</label>
                            <input value={autoFill.numero_negocio_prima || ''} disabled={step2Loading} type="text" name="numero_negocio_prima" id="numero_negocio_prima" autoComplete="numero_negocio_prima" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleInputChangeAndSetAutoFill}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email *</label>
                            <input value={autoFill.email} disabled={step2Loading} type="email" name="email" id="email" autoComplete="email" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="numero_telefono" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t("Teléfono")} *</label>
                            <input disabled={step2Loading} value={autoFill.telefono} type="text" name="numero_telefono" id="numero_telefono" autoComplete="numero_telefono" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}
                                onBlur={(e) => { if (!validacionTelefono(e.target.value)) { alert("El teléfono no es válido") } }}
                            />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="nombre_y_apellidos" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre y Apellidos')} *</label>
                            <input value={autoFill.nombre_y_apellidos} disabled={step2Loading} type="text" name="nombre_y_apellidos" id="nombre_y_apellidos" autoComplete="nombre_y_apellidos" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre y Apellidos")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label htmlFor="nombre_y_apellidos2" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre y Apellidos 2')} *</label>
                            <input value={autoFill.nombre_y_apellidos2} disabled={step2Loading} type="text" name="nombre_y_apellidos2" id="nombre_y_apellidos2" autoComplete="nombre_y_apellidos2" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Nombre y Apellidos 2")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="direccion" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Dirección')} *</label>
                            <input disabled={step2Loading} value={autoFill.direccion} ref={inputRef} type="text" name="direccion" id="direccion" autoComplete="direccion" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Dirección")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>
                        {/* Campo de provincia oculta que se autorrellena depende de la direccion */}
                        <div className="col-span-2 sm:col-span-2" style={{ display: 'none' }}>
                            <label htmlFor="provincia" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Provincia')} *</label>
                            <input disabled={step2Loading} value={autoFill.provincia} ref={provinciaRef} type="text" name="provincia" id="provincia" autoComplete="provincia" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Provincia")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="codigo_postal_prima" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Código Postal')} *</label>
                            <input disabled={step2Loading} value={autoFill.codigo_postal_prima} ref={postalCodeRef} type="text" name="codigo_postal_prima" id="codigo_postal_prima" autoComplete="codigo_postal_prima" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Código Postal")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                        </div>

                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="banco" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Nombre del banco')} *</label>
                            <select value={autoFill.banco} disabled={step2Loading} id="banco" name="banco" autoComplete="banco" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required>
                                <option disabled value="">Selecciona un banco</option>
                                {companies.map((company) => (
                                    <option key={company.name} value={company.name}>{company.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="prima_unica" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Prima Única')} *</label>
                            <input disabled={step2Loading} value={autoFill.prima_unica} type="number" name="prima_unica" id="prima_unica" autoComplete="prima_unica" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required step="0.01"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label htmlFor="prima_unica2" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Prima Única')} 2*</label>
                            <input disabled={step2Loading} value={autoFill.prima_unica2} type="number" name="prima_unica2" id="prima_unica2" autoComplete="prima_unica2" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} step="0.01"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="porcentaje_cobro" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Porcentaje de cobro')} *</label>
                            <input defaultValue={12} value={autoFill.porcentaje_cobro} disabled={step2Loading} type="number" name="porcentaje_cobro" id="porcentaje_cobro" autoComplete="porcentaje_cobro" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} required></input>
                            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">{t('Cambiar cuando sea distinto al 12%')}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="pol_cert" className="block text-sm font-medium text-gray-700 dark:text-gray-400">POL/CERT</label>
                            <input disabled={step2Loading} value={autoFill.pol_cert} type="text" name="pol_cert" id="pol_cert" autoComplete="pol_cert" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} placeholder="POL/CERT"></input>
                            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">{t('En caso de ser 2 personas, introducir los dos números')}</p>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label htmlFor="segunda_fecha" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Segunda fecha')} *</label>
                            <select disabled={step2Loading} value={autoFill.segunda_fecha} id="segunda_fecha" name="segunda_fecha" autoComplete="segunda_fecha" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-600 focus:border-primary-600 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => { setSegundaFecha(e.target.value); setAutoFill({ ...autoFill, [e.target.name]: e.target.value }) }}>
                                <option value="false">No</option>
                                <option value="true">{t('Si')}</option>
                            </select>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="fecha_inicio" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de Inicio')} *</label>
                            <input disabled={step2Loading} type="date" name="fecha_inicio" id="fecha_inicio" autoComplete="fecha_inicio" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Fecha de inicio")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} value={autoFill.fecha_inicio} required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label htmlFor="fecha_fin" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de Fin')} *</label>
                            <input disabled={step2Loading} type="date" name="fecha_fin" id="fecha_fin" autoComplete="fecha_fin" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Fecha de fin")} onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} value={autoFill.fecha_fin} required></input>
                        </div>

                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 && segundaFecha == "true" ? "block" : "none" }}>
                            <label htmlFor="fecha_inicio2" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de Inicio')} 2 *</label>
                            <input disabled={step2Loading} type="date" name="fecha_inicio2" id="fecha_inicio2" autoComplete="fecha_inicio2" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} value={autoFill.fecha_inicio_2} placeholder={t("Fecha de inicio")}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 && segundaFecha == "true" ? "block" : "none" }}>
                            <label htmlFor="fecha_fin2" className="block text-sm font-medium text-gray-700 dark:text-gray-400">{t('Fecha de Fin')} 2 *</label>
                            <input disabled={step2Loading} type="date" name="fecha_fin2" id="fecha_fin2" autoComplete="fecha_fin2" className="mt-1 focus:ring-primary-600 focus:border-primary-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(e) => setAutoFill({ ...autoFill, [e.target.name]: e.target.value })} value={autoFill.fecha_fin_2} placeholder={t("Fecha de fin")}></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Front</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_front" id="dni_front" type="file" required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Front 2</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_front2" id="dni_front2" type="file"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Back</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_back" id="dni_back" type="file"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">DNI Back 2</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="dni_back2" id="dni_back2" type="file"></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Póliza')} *</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="poliza" id="poliza" type="file" required></input>
                        </div>
                        <div className="col-span-2 sm:col-span-2" style={{ display: cantidadPersonasPU == 2 ? "block" : "none" }}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Póliza')} 2*</label>
                            <input disabled={step2Loading} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="poliza2" id="poliza2" type="file"></input>
                        </div>
                        {/* Botón de enviar, el botón debe ocupar todo el ancho del formulario */}
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "hidden" : "")}>
                            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                                {t('Siguiente')}
                            </button>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "" : "hidden")}>
                            <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                {t('Cargando...')}
                            </button>
                        </div>
                    </div>
                </form>
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                transition
                className="fixed z-50 inset-0 overflow-y-auto sm:px-0 md:px-8 lg:px-16 xl:px-32 2xl:px-64 flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-900 dark:bg-opacity-75"
            >
                <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full dark:bg-gray-800 dark:text-gray-200">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
                        <div className="overflow-x-auto rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-200">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap"></th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">DNI</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Nombre y Apellidos</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Email</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Teléfono</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Dirección</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Código Postal</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Banco</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Fecha Fin</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Fecha Inicio</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">POL/CERT</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Número Negocio</th>
                                        <th scope="col" className="px-4 py-3 whitespace-nowrap">Prima Única</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700">
                                    {data.map((item, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => setSelectedRow(item)}
                                            className={selectedRow === item ? 'bg-blue-100 dark:bg-gray-700' : 'border-b dark:border-gray-700 hover:bg-gray-50 cursor-pointer dark:hover:bg-gray-700'}
                                        >
                                            <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap flex items-center justify-center">
                                                <input type="radio" checked={selectedRow === item} className="text-primary-600" readOnly />
                                            </th>
                                            <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">{item['DNI']}</th>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Nombre Y Apellidos']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Email']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Telefono']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Direccion']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Codigo Postal']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Banco']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Fecha Fin']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Fecha Inicio']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['POL/CERT']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Numero Negocio']}</td>
                                            <td className="px-4 py-3 text-gray-500 dark:text-gray-400 whitespace-nowrap">{item['Prima Unica']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-800">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    setOpen(false);
                                    setNumeroNegocioPrima(selectedRow['Numero Negocio']);
                                    setAutoFill({
                                        ...autoFill,
                                        banco: selectedRow['Banco'],
                                        codigo_postal_prima: selectedRow['Codigo Postal'],
                                        dni: selectedRow['DNI'],
                                        direccion: selectedRow['Direccion'],
                                        email: selectedRow['Email'],
                                        fecha_fin: selectedRow['Fecha Fin'],
                                        fecha_inicio: selectedRow['Fecha Inicio'],
                                        nombre_y_apellidos: selectedRow['Nombre Y Apellidos'],
                                        pol_cert: selectedRow['POL/CERT'],
                                        numero_negocio_prima: selectedRow['Numero Negocio'],
                                        prima_unica: selectedRow['Prima Unica'],
                                    });
                                }}
                            >
                                Seleccionar
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => setOpen(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
            {step === 2 && (
                <FormPrima2 setStep={setStep} setCurrentStep={setCurrentStep} step2Loading={step2Loading} contratoFile={contratoFile} formValues={formValues} hubspotData={hubspotData} />
            )}
        </>
    )
}

FormPrima.propTypes = {
    step2Loading: propTypes.bool.isRequired,
    setStep2Loading: propTypes.func.isRequired,
    setCurrentStep: propTypes.func.isRequired
}