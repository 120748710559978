export function validarDNIoNIF(dni) {
    dni = dni.toUpperCase();
    var numero, letra, letraEsperadaDNI;
    var letras = 'TRWAGMYFPDXBNJZSQVHLCKE';

    if (dni.length !== 9 || !/^[XYZ]?\d{7,8}[A-Z]$/.test(dni)) {
        return false;
    }

    if (/^[XYZ]/.test(dni)) {
        dni = dni
            .replace('X', '0')
            .replace('Y', '1')
            .replace('Z', '2');
    }

    numero = dni.substr(0, dni.length - 1);
    letra = dni.charAt(dni.length - 1);
    letraEsperadaDNI = letras.charAt(numero % 23);

    return letraEsperadaDNI === letra;
}