import { supabaseClient } from "../config/supabase-clients";

const supabase = supabaseClient;

export const ApiRequestCalendarAbsences = async () => {
    const { data: users, error } = await supabase
        .from('users')
        .select('user_id, first_name, profile_pic');

    const { data: absences, error2 } = await supabase
        .from('absence')
        .select('user_id, start_date, end_date, reason, file')

    if (error || error2) {
        console.log(error);
        console.log(error2);
    }

    // Filtrar usuarios que tienen vacaciones
    const data = users
        .map(user => {
            const userAbsence = absences
                .filter(absences => absences.user_id === user.user_id)
                .map(({ user_id, ...rest }) => rest); // Excluir user_id de absences
            return {
                ...user,
                absences: userAbsence
            };
        })
        .filter(user => user.absences.length > 0);

    return data;
}