import 'flowbite';
import { BrowserRouter as Router, } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AppRoutes } from './routes/AppRoutes';
import { ClockProvider } from './context/ClockContext';

function App() {
  return (
    <AuthProvider>
      <ClockProvider>
        <Router>
          <AppRoutes />
        </Router>
      </ClockProvider>
    </AuthProvider>
  );
}
export default App;