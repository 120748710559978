export const enFormPrima = {

    translation: {
        "Rellenando documento de": "Filling out document of",
        "Prima Única": "Single Premium",
        "Volver atrás": "Go back",
        "Plantillas de Prima Única": "Single Premium Templates",
        "Por defecto": "Default",
        "Cantidad de personas": "Number of people",
        "Número de negocio": "Business number",
        "Nombre y Apellidos": "Name and Surname",
        "Nombre Y Apellidos 2": "Name and Surname 2",
        "Dirección": "Address",
        "Código Postal": "Postal Code",
        "Nombre del banco": "Bank name",
        "Prima Única": "Single Premium",
        "Porcentaje de cobro": "Collection percentage",
        "En caso de ser 2 personas, introducir los dos números": "In case of being 2 people, enter both numbers",
        "Segunda fecha" : "Second date",
        "Fecha de Inicio": "Start date",
        "Fecha de Fin": "End date",
        "Póliza": "Policy",
        "Siguiente": "Next",
        "Cargando...": "Loading...",
        "Atrás": "Back",
    }
};