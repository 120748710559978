import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import toast, { Toaster } from 'react-hot-toast';
import './index.css'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ColorSchemeProvider } from './context/ColorSchemeContext.jsx';
import './config/i18n.js'
Sentry.init({
  dsn: "https://135297e8ba5e4a1ebc521767ddb7ace0@sentry.coventia.es/9",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <div>
        <Toaster />
        <ColorSchemeProvider>
          <App />
        </ColorSchemeProvider>
      </div>
    </Sentry.ErrorBoundary>
)