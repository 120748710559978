const formCompanies = [
    {
        "name": "Abanca Corporacion Bancaria, S.A.",
        "cif": "A-70302039",
        "address": "Calle Cantón Claudino Pita nº 2 de Betanzos CP 15300 (A Coruña)",
    },
    {
        "name": "Banca March, S.A.",
        "cif": "A-07004021",
        "address": "Av. Alejandro Rosselló, 8 07002 Palma de Mallorca",
    },

    {
        "name": "Banco Bilbao Vizcaya Argentaria, S.A",
        "cif": "A-48265169",
        "address": "Plaza de San Nicolás, 4 48005 Bilbao (Vizcaya)",
    },
    {
        "name": "Banco de Sabadell, S.A",
        "cif": "A08000143",
        "address": "Avda. Óscar Esplá, 37, 03007 Alicante",
    },
    {
        "name": "Banco Santander, S.A.",
        "cif": "A-39000013",
        "address": "Paseo de Pereda, 9-12, 39004 Santander, Cantabria",
    },
    {
        "name": "Banco Mediolanum S.A.",
        "cif": "A-58640582",
        "address": "C/ Barcas, nº10, 46002 Valencia",
    },
    {
        "name": "Banco Popular Español, Sociedad Anónima (actualmente Banco Santander, S.A.)",
        "cif": "A-39000013",
        "address": "Paseo Pereda nº9-12 de Santander CP 39004 (Cantabria)",
    },

    {
        "name": "Banco Unión de Créditos Inmobiliarios S.A. E.F.C",
        "cif": "A-39025515",
        "address": "Calle Amaltea 32, 1ª planta (Edificio Visionary), 28045 Madrid",
    },
    {
        "name": "Bankinter, S.A.",
        "cif": "A-28/157360",
        "address": "Paseo de la Castellana, 29, 28046 Madrid, España",
    },
    {
        "name": "Caixa de Crédit dels Enginyers- Caja de Crédito de los Ingenieros, S. Coop. de Crédito",
        "cif": "F08216863",
        "address": "Laietana, Nº 39 08003, Barcelona, Barcelona",
    },

    {
        "name": "Caixa Popular-Caixa Rural, S. Coop. de Crédito V.",
        "cif": "F46000013",
        "address": "VALÈNCIA PARC TECNOLÒGIC. Avda. Juan de la Cierva, 9. CP 46980 Paterna (Valencia)",
    },
    {
        "name": "Caixabank, S.A",
        "cif": "A08663619",
        "address": "Calle Pintor Sorolla, 2-4 46002 Valencia",
    },
    {
        "name": "Caja Rural de Extremadura Sociedad Cooperativa de Crédit",
        "cif": "F06002661",
        "address": "Av. Santa Marina, 15, 06005 Badajoz, España",
    },
    {
        "name": "Caja Rural de Navarra Sociedad Cooperativa de Crédito",
        "cif": "F31021611 ",
        "address": "Plaza de los Fueros 1, CP 31003. Pamplona (Navarra)",
    },
    {
        "name": "Caja Rural de Zamora Sociedad Cooperativa de Credito",
        "cif": "F49000013",
        "address": "Calle de Ramos Carrión, 16, 49001 Zamora, España",
    },
    {
        "name": "Cajamar Caja Rural Sociedad Cooperativa de Credito",
        "cif": "F-04743175",
        "address": "Plaza Puerta Puchena, 10, 04001 - Almería",
    },
    {
        "name": "Cajasur Banco, S.A.",
        "cif": "A-95622841",
        "address": " Avda. del Gran Capitán, 11-13. 14.008 Córdoba",
    },
    {
        "name": "Deutsche Bank, A.G., Sucursal en España",
        "cif": "W0041027D",
        "address": "Paseo de la Castellana, 18, 28046 Madrid, España",
    },
    {
        "name": "Evo Banco S.A.",
        "cif": "A-70386024",
        "address": "c/Don Ramón de la Cruz, número 84, 28006, Madrid",
    },
    {
        "name": "Ibercaja Banco, S.A",
        "cif": "A-99319030",
        "address": "Plaza de Basilio Paraíso, 2. 50008-Zaragoza",
    },
    {
        "name": "Ing Bank, N.V. Sucursal en España",
        "cif": "W0037986G",
        "address": "Calle Vía de los Poblados 1F, 28033 Madrid",
    },
    {
        "name": "Kutxabank, S.A",
        "cif": "A-95653077 ",
        "address": "Gran Vía, 30, 48009 Bilbao, Bizkaia",
    },
    {
        "name": "Open Bank, S.A.",
        "cif": "A-28021079",
        "address": "Plaza Santa Bárbara, 2, 28004 Madrid, España",
    },
    {
        "name": "Targobank S.A.",
        "cif": "A79223707",
        "address": "c/ Ramírez de Arellano, 29, 28043 Madrid",
    },
    {
        "name": "Unicaja Banco, S.A.",
        "cif": "A-93139053",
        "address": "Avda. de Andalucía, 10-12, 29007 Málaga",
    },

]

export { formCompanies }