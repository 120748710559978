import { useEffect, useState } from 'react';
import { supabaseClient } from '../../../config/supabase-clients';
import { apiVacationCount } from '../../../api/apiVacationCount';
import EditableTable from '../../EditableTable';
import { LoadingComponent } from '../../LoadingComponent';

const supabase = supabaseClient;

export const ManageVacationCount = () => {
    const [vacationCount, setVacationCount] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchVacationCount = async () => {
        setIsLoading(true);
        const data = await apiVacationCount();
        setVacationCount(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchVacationCount();
    }, []);


    const columns = [
        {
            label: 'Nombre',
            field: 'name',
            type: 'text',
            width: 'w-1/4',
            editable: false,
        },
        {
            label: 'Días totales',
            field: 'total_days',
            type: 'number',
            width: 'w-1/4',

        },
        {
            label: 'Días utilizados',
            field: 'used_days',
            type: 'number',
            width: 'w-1/4',
        },
        {
            label: 'Días restantes',
            field: 'remaining_days',
            type: 'number',
            width: 'w-1/4',
        },
    ];

    const handleUpdateRow = async (updatedRow) => {
        const { error } = await supabase
            .from('vacation_balance')
            .update({
                total_days: updatedRow.total_days,
                used_days: updatedRow.used_days,
                remaining_days: updatedRow.remaining_days
            })
            .eq('user_id', updatedRow.user_id);

        if (error) {
            console.error('Error updating row:', error.message);
            return;
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center w-full h-screen">
                <LoadingComponent />
            </div>
        )
    }

    return (
        <EditableTable
            columns={columns}
            initialData={vacationCount}
            deletable={false}
            onUpdateRow={handleUpdateRow}
            maxRows={vacationCount.length}
        />
    );
};