import { createClient } from "@supabase/supabase-js";

const supabaseURL = 'https://uoclqlfzojdjrhcqhgef.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0';
const supabase = createClient(supabaseURL, supabaseKey);

export const HolidaysUpdater = async () => {
    const { data: holidays, error } = await supabase
        .from('holidays')
        .select('*')
        .order('holiday_date', { ascending: true });

    if (error) {
        console.log('Error fetching holidays:', error.message);
        return;
    }

    const formattedHolidays = holidays.map(holiday => ({
        date: new Date(holiday.holiday_date),
        name: holiday.name,
        type: holiday.type,
        index: holiday.index
    }));

    const today = new Date();
    const currentYear = today.getFullYear();

    // Filtrar las fechas de este año que ya han pasado
    const pastHolidays = formattedHolidays.filter(holiday => {
        const holidayDate = new Date(holiday.date);
        return holidayDate < today && holidayDate.getFullYear() === currentYear;
    });

    // Actualizar las fechas sumándoles un año
    const updatedHolidays = pastHolidays.map(holiday => {
        const newDate = new Date(holiday.date);
        newDate.setFullYear(currentYear + 1);
        return {
            ...holiday,
            date: newDate
        };
    });

    console.log('Holidays to update:', updatedHolidays);

    if (updatedHolidays.length > 0) {
        for (const holiday of updatedHolidays) {
            const { data, error } = await supabase
                .from('holidays')
                .update({ holiday_date: holiday.date })
                .eq('index', holiday.index);
            if (error) {
                console.log('Error updating holidays:', error.message);
                return;
            }
            console.log('Holidays updated:', data);
        }
    }
};