import { createClient } from "@supabase/supabase-js";

const supabaseURL = 'https://uoclqlfzojdjrhcqhgef.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0';
const supabase = createClient(supabaseURL, supabaseKey);

export const apiVacationCount = async () => {
    const { data: vacationCount, error } = await supabase
        .from('vacation_balance')
        .select('*');
        
    if (error) {
        console.log('Error fetching vacation count:', error.message);
        return;
    }
    //Sacar user_id de vacationCount para sacar los nombres de usuarios de la tabla users
    for (let i = 0; i < vacationCount.length; i++) {
        const { data: user, error } = await supabase
            .from('users')
            .select('first_name')
            .eq('user_id', vacationCount[i].user_id)
            .single();

        if (error) {
            console.log('Error fetching user:', error.message);
            return;
        }
        vacationCount[i].name = user.first_name;
    }

    return vacationCount;
}
