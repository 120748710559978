/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetUsuarioXCorreduria } from '../../../data/GetUsuarioXCorreduria';
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import festivos from '../../../data/festivos';
import { useAuth } from '../../../hooks/useAuth';
import { Datepicker } from 'flowbite-react';
import { supabaseClient } from '../../../config/supabase-clients';
import { saveAs } from 'file-saver';
import { set } from 'lodash';


export const ManageUsersClock = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [records, setRecords] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [currentWeek, setCurrentWeek] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notAllowed, setNotAllowed] = useState(true);
    const [downloading, setDownloading] = useState(false);

    const calculateTotalHours = (record) => {
        let totalMinutes = 0;
        record.shifts.forEach(shift => {
            if (shift.start && shift.end) {
                const [startHours, startMinutes] = shift.start.split(':').map(Number);
                const [endHours, endMinutes] = shift.end.split(':').map(Number);
                const startDate = new Date(0, 0, 0, startHours, startMinutes, 0);
                const endDate = new Date(0, 0, 0, endHours, endMinutes, 0);
                const diffMinutes = (endDate - startDate) / (1000 * 60);
                totalMinutes += diffMinutes;
            }
        });
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        record.totalHours = `${hours}h ${minutes}m`;
    };

    const recalculateHours = (newRecords) => {
        newRecords.forEach(record => calculateTotalHours(record));
    };

    // Cargar datos de usuarios y gestionar permisos
    const fetchUserData = useCallback(async () => {
        try {
            const { staff } = await GetUsuarioXCorreduria();
            setNotAllowed(!staff);

            if (staff) {
                const { data, error } = await supabaseClient.auth.getSession();
                if (error) throw error;

                const response = await axios.get(
                    'https://api.office.coventia.es/api/manager/get_users/',
                    {
                        headers: {
                            'Authorization': data.session.access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.status === 200) {
                    setUsers(response.data);
                } else {
                    toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'));
                }
            }
        } catch (error) {
            toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'));
        } finally {
            setLoading(false);
        }
    }, [selectedUser, t]);

    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Obtener registros del usuario seleccionado desde Supabase
    const fetchUserRecords = useCallback(async (weekOffset, userId) => {
        try {
            const now = new Date();
            const startOfWeek = getStartOfWeek(new Date(now.setDate(now.getDate() + weekOffset * 7)));
            const endOfWeek = getEndOfWeek(startOfWeek);

            // Limitar endOfWeek al día actual si estamos en la semana actual
            const today = new Date();
            today.setHours(23, 59, 59, 999);
            const isCurrentWeek = weekOffset === 0;
            const adjustedEndOfWeek = isCurrentWeek ? new Date(Math.min(endOfWeek, today)) : endOfWeek;

            // Obtener los registros de turnos
            const { data: shifts, error: shiftError } = await supabaseClient
                .from('shifts')
                .select('*')
                .gte('work_date', startOfWeek.toISOString())
                .lte('work_date', adjustedEndOfWeek.toISOString())
                .order('work_date', { ascending: true })
                .eq('user_id', userId);

            if (shiftError) throw shiftError;

            const [vacationsResponse, absencesResponse] = await Promise.all([
                supabaseClient.from('vacations').select('*')
                    .gte('start_date', startOfWeek.toISOString())
                    .lte('end_date', adjustedEndOfWeek.toISOString())
                    .eq('user_id', userId),
                supabaseClient.from('absence').select('*')
                    .gte('start_date', startOfWeek.toISOString())
                    .lte('end_date', adjustedEndOfWeek.toISOString())
                    .eq('user_id', userId)
            ]);

            const { data: vacations = [], error: vacationError } = vacationsResponse;
            const { data: absences = [], error: absenceError } = absencesResponse;

            if (vacationError) console.error('Error fetching vacations:', vacationError);
            if (absenceError) console.error('Error fetching absences:', absenceError);

            const weekDates = generateDateRange(startOfWeek, adjustedEndOfWeek);

            const combinedRecords = weekDates.map(date => {
                const formattedDate = dayjs(date).startOf('day').toDate();
                const shiftsForDate = shifts.filter(shift => dayjs(shift.work_date).isSame(formattedDate, 'day'));

                return {
                    day: formattedDate,
                    shifts: shiftsForDate.map(({ shift_id, shift_start, shift_end }) => ({
                        shift_id, start: shift_start, end: shift_end
                    })),
                    absence: shiftsForDate.length ? shiftsForDate[0].absence : '',
                    totalHours: calculateTotalHours({ shifts: shiftsForDate })
                };
            });

            // updateAbsencesAndHolidays(combinedRecords, vacations, absences);
            recalculateHours(combinedRecords);

            setRecords(combinedRecords.reverse());
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    }, [selectedUser]);

    // Uso de la función para un usuario seleccionado
    useEffect(() => {
        if (selectedUser.id) {
            fetchUserRecords(currentWeek, selectedUser.id);
        }
    }, [selectedUser, fetchUserRecords, currentWeek]);

    useEffect(() => {
        if (user) fetchUserData();
    }, [user, fetchUserData]);

    // Gestionar los registros del usuario seleccionado
    useEffect(() => {
        if (selectedUser.id) {
            fetchUserRecords(currentWeek, selectedUser.id);
        }
    }, [selectedUser, fetchUserRecords]);

    const handleUserChange = (event) => {
        const selectedUserId = event.target.value;
        const user = users.find(user => user.id === selectedUserId);
        setSelectedUser(user || {});
    };

    const handleDateChange = (date) => {
        const selectedDate = new Date(date);
        const weekOffset = Math.floor((selectedDate.getTime() - new Date().setHours(0, 0, 0, 0)) / (7 * 24 * 60 * 60 * 1000));
        setCurrentWeek(weekOffset);
    };

    const getStartOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Ajusta para que Lunes sea el primer día (0=Domingo, 1=Lunes, etc.)
        const startOfWeek = new Date(date.setDate(diff));
        startOfWeek.setHours(0, 0, 0, 0);
        return startOfWeek;
    };

    const getEndOfWeek = (date) => {
        const startOfWeek = getStartOfWeek(new Date(date));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Sumar 6 días al inicio de la semana para obtener el domingo
        endOfWeek.setHours(0, 0, 0, 0);
        return endOfWeek;
    };

    const filterRecordsByWeek = (weekOffset) => {
        const now = new Date();
        const startOfWeek = getStartOfWeek(new Date(now.setDate(now.getDate() + weekOffset * 7)));
        const endOfWeek = getEndOfWeek(startOfWeek);
        return records.filter(record => {
            const recordDate = new Date(record.day);
            return recordDate >= startOfWeek && recordDate <= endOfWeek;
        });
    };

    const isWeekend = (date) => {
        const day = date.getDay();
        return day === 0 || day === 6;
    };

    const downloadAllShifts = async () => {
        try {
            setDownloading(true);
            // Llamar a escenario de n8n para descargar el archivo csv que genera el propio n8n
            const response = await axios.get('https://n8n.coventia.es/webhook/31ed34aa-488c-4a0a-abc2-48a2d7521cc2', {
                responseType: 'blob', // Importante para manejar el archivo binario
                params: {
                    user_id: selectedUser.id,
                },
            });

            // Descargar el archivo csv generado por n8n
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'shifts.xls');
            setDownloading(false);
            toast.success('Archivo descargado exitosamente');
        } catch (error) {
            console.error('Error descargando los turnos:', error);
            toast.error('Error descargando los turnos');
            setDownloading(false);
        }
    };

    const downloadUserShifts = async (userId) => {
        try {
            setDownloading(true);
            // Llamar a escenario de n8n para descargar el archivo csv que genera el propio n8n
            const response = await axios.get('https://n8n.coventia.es/webhook/31ed34aa-488c-4a0a-abc2-48a2d7521cc2', {
                responseType: 'blob', // Importante para manejar el archivo binario
                params: {
                    user_id: userId,
                },
            });

            // Descargar el archivo csv generado por n8n
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'shifts.xls');
            setDownloading(false);
            console.log('Archivo descargado exitosamente');
            toast.success('Archivo descargado exitosamente');

        } catch (error) {
            console.error('Error descargando los turnos:', error);
            toast.error('Error descargando los turnos');
            setDownloading(false);
        }
    };

    const handleShiftChange = async (index, shiftIndex, type, value) => {
        // if (index != 0) {
        //     index = index + 1;
        // }

        //Actualizar el estado local
        const updatedRecords = [...records];
        updatedRecords[index].shifts[shiftIndex][type] = value;
        updatedRecords.forEach(calculateTotalHours);
        setRecords(updatedRecords);

        //Actualizar el registro en supabase

        const record = updatedRecords[index];
        const shift = record.shifts[shiftIndex];
        const workDate = dayjs(record.day).format('YYYY-MM-DD');
        const shiftId = shift.shift_id;

        const { data, error } = await supabaseClient
            .from('shifts')
            .update({
                shift_start: shift.start ? shift.start : null,
                shift_end: shift.end ? shift.end : null,
            })
            .eq('shift_id', shiftId)
            .eq('work_date', workDate);


        if (error) {
            console.error('Error actualizando el turno:', error
            );
        }

        fetchUserRecords(currentWeek, selectedUser.id);

    };

    const handleAddShift = async (index) => {
        // if (index > 0) {
        //     index = index + 1;
        // }
        const newRecords = [...records];
        newRecords[index].shifts.push({ start: '', end: '' });

        // Obtener la fecha seleccionada
        const selectedDate = new Date(records[index].day);
        selectedDate.setHours(0, 0, 0, 0);

        // Formatear manualmente la fecha en el formato 'YYYY-MM-DD'
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-indexados
        const day = String(selectedDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        // Actualizar el estado local antes de la inserción
        setRecords(newRecords);

        //Seleccionar first_name de la tabla users con el user_id seleccionado
        const { data: selectedUserSupa, error: userError } = await supabaseClient
            .from('users')
            .select('first_name')
            .eq('user_id', selectedUser.id)

        if (userError) {
            console.error('Error seleccionando el usuario:', userError);
        }

        const firstName = selectedUserSupa[0].first_name;
        // Añadir un registro shift con el día del turno seleccionado y el usuario seleccionado
        const newShift = {
            user_id: selectedUser.id,
            work_date: formattedDate,
            shift_start: null,
            shift_end: null,
            employer_name: firstName,
        };

        const { data, error } = await supabaseClient
            .from('shifts')
            .insert([newShift]);

        if (error) {
            console.error('Error añadiendo el turno al usuario seleccionado:', error);
        }

        // Refrescar los registros del usuario después de la inserción
        fetchUserRecords(currentWeek, selectedUser.id);
    };

    const handleDeleteShift = async (index, shiftIndex) => {
        try {
            console.log(index, shiftIndex);
            // if (index != 0) {
            //     index = index + 0;
            // }
            const updatedRecords = [...records];
            const shiftId = updatedRecords[index].shifts[shiftIndex].shift_id;
            updatedRecords[index].shifts.splice(shiftIndex, 1);
            setRecords(updatedRecords);

            // Ahora que el estado updateRecords está correcto se procede a eliminar el turno de la base de datos
            const { data, error } = await supabaseClient
                .from('shifts')
                .delete()
                .eq('shift_id', shiftId);

            if (error) {
                console.error('Error eliminando el turno:', error);
            }

            fetchUserRecords(currentWeek, selectedUser.id);  // Refrescar los registros del usuario después de la eliminación
        } catch (err) {
            console.error('Error en handleDeleteShift:', err);
        }
    };

    const handleAbsenceChange = (index, value) => {
        const updatedRecords = [...records];
        updatedRecords[index].absence = value;
        setRecords(updatedRecords);
    };

    const handlePreviousWeek = () => setCurrentWeek(prevWeek => prevWeek - 1);
    const handleNextWeek = () => setCurrentWeek(prevWeek => prevWeek + 1);

    const currentWeekRecords = filterRecordsByWeek(currentWeek);
    const formatDate = (date) => {
        return dayjs(date).format('D MMMM YYYY');
    };

    const startOfWeek = getStartOfWeek(new Date(new Date().setDate(new Date().getDate() + currentWeek * 7)));
    const endOfWeek = getEndOfWeek(startOfWeek);

    return (
        <div>


            {selectedUser && selectedUser.id ? (
                <div className="flex justify-end mr-16 mt-4 mb-6">
                    <button
                        className={`bg-primary-600 text-white py-2 px-4 rounded ${downloading ? 'cursor-not-allowed bg-gray-400' : 'hover:bg-primary-500'}`}
                        style={{ pointerEvents: downloading ? 'none' : 'auto' }}
                        onClick={() => downloadUserShifts(selectedUser.id)}
                    >
                        {downloading ? t('Descargando...') : t('Descargar los turnos de')} {selectedUser.username}
                    </button>
                </div>
            ) : null                
            }

            <div className="flex items-center flex-col">
                <div className='text-black text-center  dark:text-gray-300'>
                    Semana del {formatDate(startOfWeek)} al {formatDate(endOfWeek)}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div className="flex justify-start ml-16">
                    <select
                        className="py-2 px-3 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        onChange={handleUserChange}
                    >
                        <option key='default' value='default'>
                            Selecciona un usuario
                        </option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.username}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-end mr-16">
                    <Datepicker
                        language='es-ES'
                        weekStart={2}
                        onSelectedDateChanged={handleDateChange}
                        labelTodayButton='Hoy'
                        labelClearButton='Limpiar'
                    />
                </div>
            </div>
            <div className="flex items-center relative">
                {selectedUser && selectedUser.id ? (
                    <>
                        <div className='hidden md:block transition ease-in-out hover:-translate-y-1 hover:scale-110 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 h-56 rounded-l-full mx-1'>
                            <button
                                onClick={handlePreviousWeek}
                                className="left-0 z-10 align flex items-center justify-center h-full px-4 focus:outline-none"
                            >
                                <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="overflow-x-auto shadow-md w-full relative mt-4 rounded-xl">
                            <div className='overflow-y-auto'>
                                <table
                                    className="w-full h-full rounded-lg text-sm text-left text-gray-500 dark:text-gray-400"
                                >
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope='col' className="py-3 px-4 md:px-6">Día</th>
                                            <th scope='col' className="py-3 px-4 md:px-6">Turnos trabajados</th>
                                            <th scope='col' className="py-3 px-4 md:px-6">Ausencias</th>
                                            <th scope='col' className="py-3 px-4 md:px-6">Horas totales</th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="bg-white divide-y divide-gray-200 rounded-xl overflow-y-auto"
                                    >
                                        {currentWeekRecords.map((record, index) => (
                                            <tr key={index} className={isWeekend(record.day) ? 'bg-gray-100 border-b dark:bg-gray-700 dark:border-gray-900' : 'bg-white border-b dark:bg-gray-800 dark:border-gray-700'}>
                                                <td className="align-top px-6 py-4 whitespace-nowrap border-b dark:border-gray-700">
                                                    <div className='text-xl text-gray-800 dark:text-gray-300'>
                                                        {`${dayjs(record.day).format('D')} ${dayjs(record.day).format('MMM').replace(/^(.)/, match => match.toUpperCase())}`}
                                                    </div>
                                                    <div className="text-sm text-gray-500">{dayjs(record.day).format('dddd')}</div>
                                                </td>

                                                <td
                                                    key={'shifts' + index}
                                                    className="px-4 py-2 border-b dark:border-gray-700"
                                                >
                                                    {record.shifts.map((shift, shiftIndex) => (
                                                        <div
                                                            key={shiftIndex + '-' + index}

                                                            className="my-2 flex flex-col md:max-w-[20rem] sm:flex-row sm:justify-between sm:items-center gap-2 py-1">
                                                            <div className="flex-1">
                                                                <div className="relative">
                                                                    <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                                            <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd" />
                                                                        </svg>
                                                                    </div>
                                                                    <input

                                                                        type="time"
                                                                        id={`start-time-${index}-${shiftIndex}`}
                                                                        value={shift.start || ''}
                                                                        onChange={(e) => handleShiftChange(index, shiftIndex, 'start', e.target.value)}
                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                        min="08:00"
                                                                        max="19:00"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex-1">
                                                                <div className="relative">
                                                                    <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                                            <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd" />
                                                                        </svg>
                                                                    </div>
                                                                    <input
                                                                        type="time"
                                                                        id={`end-time-${index}-${shiftIndex}`}
                                                                        value={shift.end || ''}
                                                                        onChange={(e) => handleShiftChange(index, shiftIndex, 'end', e.target.value)}
                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                        min="08:00"
                                                                        max="19:00"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center justify-end">
                                                                <button
                                                                    onClick={() => handleDeleteShift(index, shiftIndex)}
                                                                    className="text-red-500 hover:text-red-700 disabled:text-gray-400 py-2"
                                                                >
                                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button
                                                        onClick={() => handleAddShift(index)}
                                                        className="text-blue-500 hover:text-blue-700"
                                                    >
                                                        Añadir turno
                                                    </button>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap border-b dark:border-gray-700">
                                                    <select
                                                        value={record.absence}
                                                        onChange={(e) => handleAbsenceChange(index, e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">-</option>
                                                        <option value="Vacaciones">Vacaciones</option>
                                                        <option value="Baja médica">Baja médica</option>
                                                        <option value="Permiso retribuido">Permiso retribuido</option>
                                                        <option value="Festivo">Festivo</option>
                                                        <option value="Otras causas">Otras causas</option>
                                                    </select>

                                                </td>

                                                <td className="px-6 py-4 text-lg whitespace-nowrap border-b dark:border-gray-700">
                                                    {record.totalHours}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div >
                        <div className='hidden md:block transition ease-in-out hover:-translate-y-1 hover:scale-110 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 h-56 rounded-r-full mx-1'>
                            <button
                                onClick={handleNextWeek}
                                disabled={currentWeek == 0}
                                className="right-0 z-10 flex items-center justify-center h-full px-4 focus:outline-none"
                            >
                                <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                                </svg>
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full py-20">
                        <svg className="svg-icon w-10 h-10 text-gray-400 dark:text-gray-600" viewBox="0 0 20 20">
                            <path d="M18.344,16.174l-7.98-12.856c-0.172-0.288-0.586-0.288-0.758,0L1.627,16.217c0.339-0.543-0.603,0.668,0.384,0.682h15.991C18.893,16.891,18.167,15.961,18.344,16.174 M2.789,16.008l7.196-11.6l7.224,11.6H2.789z M10.455,7.552v3.561c0,0.244-0.199,0.445-0.443,0.445s-0.443-0.201-0.443-0.445V7.552c0-0.245,0.199-0.445,0.443-0.445S10.455,7.307,10.455,7.552M10.012,12.439c-0.733,0-1.33,0.6-1.33,1.336s0.597,1.336,1.33,1.336c0.734,0,1.33-0.6,1.33-1.336S10.746,12.439,10.012,12.439M10.012,14.221c-0.244,0-0.443-0.199-0.443-0.445c0-0.244,0.199-0.445,0.443-0.445s0.443,0.201,0.443,0.445C10.455,14.021,10.256,14.221,10.012,14.221" fill='currentColor'></path>
                        </svg>
                        <span className="text-gray-400 dark:text-gray-600">{t('Selecciona a un usuario para poder ver sus registros')}</span>
                        <span className="text-gray-400 dark:text-gray-600">
                            <a
                                onClick={downloading ? null : downloadAllShifts}
                                className={`hover:text-blue-300 cursor-pointer ${downloading ? 'cursor-not-allowed text-gray-400' : 'text-blue-400'}`}
                                style={{ pointerEvents: downloading ? 'none' : 'auto' }}
                            >
                                {downloading ? t('Descargando...') : t('Descargar todos los turnos')}
                            </a>
                        </span>
                    </div>
                )}


            </div>
            {/* Paginador para el movil justo debajo de la tabla con los 2 botones*/}
            <div className="flex justify-end items-center md:hidden mt-5">
                <div className='flex md:hidden w-1/2 h-10 bg-primary-50 dark:bg-gray-600 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 rounded-l-full mx-2 justify-center'>
                    <button
                        onClick={handlePreviousWeek}
                        onTouchEnd={handlePreviousWeek}
                        disabled={currentWeek == 0}
                        className="left-0 w-full z-10 flex items-center justify-center h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>
                </div>
                <div className='flex md:hidden w-1/2 bg-primary-50 dark:bg-gray-600 h-10 dark:hover:bg-gray-700 hover:bg-primary-50 dark:hover-bg-gray-600 duration-300 rounded-r-full mx-2  justify-center'>
                    <button
                        onClick={handleNextWeek}
                        onTouchEnd={handleNextWeek}
                        disabled={currentWeek == 0}
                        className="z-10 w-full flex justify-center mt-2 h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div >
    )
}
