import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';
import axios from 'axios';
import AudioRecorder from './AudioRecorder'
import supabaseClient from '../../utils/supabaseInit';
import { PaperAirplaneIcon, DocumentArrowUpIcon } from "@heroicons/react/20/solid";

//import Recorder2 from '../AudioRecorder2';
const { data } = await supabaseClient.auth.getSession()


const SendBar = () => {

    const access_token = data?.session.access_token
    const { t } = useTranslation(); // React-i18next hook for translation
    //Loding when send msg
    const [isLoading, setIsLoading] = useState(true);

    //Get input info
    const [input, setInput] = useState('');

    //Info in send template
    const [templateData, setTemplateData] = useState([]);

    //Current wa coversation
    const [user, setUser] = useState('');

    //Current wa covnersation date
    const [date, setDate] = useState('');

    //Loding when send media
    const [sendingMedia, setSendingMedia] = useState(false)

    //Loding when send text
    const [sendingText, setSendingText] = useState(false)

    //Select in send template 
    const [selectedOption, setSelectedOption] = useState(0);

    //Replying to this msg
    const [replyID, setReplyID] = useState('')
    const [replyBody, setReplyBody] = useState('');
    const [replyState, setReplyState] = useState(false);

    //Current agent 
    const [agent, setAgent] = useState('')

    //Ctrl send template button on/off
    const [templateButtonVisible, setTemplateButtonVisible] = useState(false)

    //Send media button ref 
    const fileInputRef = useRef(null);

    //Reply msg ref
    const replyRef = useRef(null);

    //text area ref
    const textareaRef = useRef(null);

    //List of commands visibility
    const [showTemplates, setShowTemplates] = useState(false);
    const [customTemplates, setCustomTemplates] = useState([]);

    const [isKeyboardNavigating, setIsKeyboardNavigating] = useState(false);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const templateRefs = useRef([]);

    //Api url
    const sendtemplateUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/sendtemplate`
    const sendMsgUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/sendmsg`
    const replyMsgUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/replymsg`
    const sendMediaUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/sendmedia`
    const listTemplateUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/listtemplate`

    //Pubsub : ChangeChat
    const changeChat = (_, data) => {
        setUser(data.user);
        setDate(data.date);
        setReplyID('')
        setReplyBody('')
        setReplyState('')
    };

    useEffect(() => {
        console.log("send bar mount");

        //PubSub : Subscribe
        const token = PubSub.subscribe('ChangeChat', changeChat);
        const token2 = PubSub.subscribe('Reply', replyMsg);
        const token3 = PubSub.subscribe('AgentName', agentName)

        //Global listener
        Document, addEventListener('click', handleClick)

        //Get Api /listtemplate
        const getData = async () => {
            try {
                const config = {
                    headers: {
                        'Authorization': access_token,
                    }
                }
                const response = await axios.get(listTemplateUrl, config);
                console.log('Response:', response.data);

                let templateData = response.data.map(element => ({
                    value: `${element.name}*&*${element.language}`,
                    label: `${element.name} (${element.language})`,
                    header: element.header,
                    body: element.body,
                    footer: element.footer,
                    buttons: element.buttons
                }));

                setTemplateData(templateData);
                setIsLoading(false)
            } catch (error) {
                console.error('Error:', error);
            }
        }

        getData()

        // Función para traer las plantillas de Supabase (tabla 'whatsapp_templates')
        const fetchTemplates = async () => {
            const { data, error } = await supabaseClient
                .from('whatsapp_templates')
                .select('name, text')
                .order('order', { ascending: true });
            if (error) {
                console.error('Error fetching templates:', error.message);
            } else {
                setCustomTemplates(data);
                console.log('Custom templates:', data);
            }
        }
        fetchTemplates();

        return () => {
            PubSub.unsubscribe(token);
            PubSub.unsubscribe(token2)
            PubSub.unsubscribe(token3)

        };
    }, []);

    //Pubsub : 'AgentName'
    const agentName = (_, data) => {
        setAgent(data)
    }

    //Pubsub : Reply
    const replyMsg = (_, data) => {
        //console.log(data.msg)
        setReplyID(data.msgid)
        setReplyBody(data.msg)
        setReplyState(true)
        if (replyRef.current) {
            replyRef.current.click();
        }
    }

    //msgSend
    const msgSend = async () => {
        try {
            if (replyState) {
                //send reply msg
                if (user != '' && input != '') {
                    setSendingText(true)
                    const data = {
                        'wa_id': user,
                        'text': input.toString(),
                        'msgid': replyID,
                        'agent': agent,
                    };
                    const headers = { 'Authorization': access_token, }
                    PubSub.publish('MsgSend', null)
                    const response = await axios.post(replyMsgUrl, data, { headers });
                    console.log(response)
                    setSendingText(false)
                    if (input !== '') {
                        setInput('');
                        setReplyID('')
                        setReplyBody('')
                        setReplyState('')
                    }
                }
            } else {
                //send text msg
                if (user != '' && input != '') {
                    setSendingText(true)
                    const data = {
                        'wa_id': user,
                        'text': input.toString(),
                        'agent': agent,
                    };
                    const headers = { 'Authorization': access_token, }
                    PubSub.publish('MsgSend', null)
                    const response = await axios.post(sendMsgUrl, data, { headers });
                    setSendingText(false)
                    if (input !== '') {
                        setInput('');
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    //sendMedia
    const sendMedia = async (file) => {
        setSendingMedia(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('wa_id', user);
        formData.append('agent', agent);
        try {
            PubSub.publish('MsgSend', null)
            const response = await axios.post(sendMediaUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': access_token,
                }
            });
            console.log('OK:', response.data);
            setSendingMedia(false)
        } catch (error) {
            if (error.response.status === 400) {
                alert("Bad Request: this file is not supported");
            } else if (error.response.status === 500) {
                alert("Server error");
            }
        }
    };


    //sendtemplate
    const sendtemplate = (templateData) => {
        const data = {
            wa_id: user,
            name: templateData.value.split("*&*")[0],
            language: templateData.value.split("*&*")[1],
            agent: agent,

        };
        const headers = { 'Authorization': access_token, }
        PubSub.publish('MsgSend', null)
        axios.post(sendtemplateUrl, data, { headers })
            .then(response => {
                console.log('Response:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    //Display reply msg
    const displayTemplateMsg = (templateData) => {
        let index = 0

        return (
            <div id="popover-sendTemplate" className="flex items-start justify-end gap-2.5 my-2">
                <div id="popover-sendTemplate" className="flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                    <div id="popover-sendTemplate" className="flex items-center space-x-2 rtl:space-x-reverse">
                        <span id="popover-sendTemplate" className="text-sm font-semibold text-gray-900 dark:text-white">{t('Tú')}</span>
                        <span id="popover-sendTemplate" className="text-sm font-normal text-gray-500 dark:text-gray-400">9:00</span>
                    </div>
                    <div id="popover-sendTemplate" className="text-sm font-normal py-2.5 text-gray-900 dark:text-white word-break : break-word" style={{ wordBreak: 'break-word' }}>
                        <p id="popover-sendTemplate" >{templateData.body}</p>
                        <br id="popover-sendTemplate" />
                        <p id="popover-sendTemplate" >{templateData.footer}</p>
                        <br id="popover-sendTemplate" />
                        {templateData.buttons.map(element => {
                            let ans =
                                (<button
                                    id="popover-sendTemplate"
                                    key={index}
                                    type="button"
                                    disabled
                                    style={{ marginLeft: 'auto', display: 'flex' }}
                                    className="text-white bg-primary-400 hover:bg-primary-400 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-400 dark:hover:bg-primary-400">

                                    {element}
                                </button>)
                            index++
                            return ans
                        })}
                    </div>
                    <span id="popover-sendTemplate" className="text-sm font-normal text-gray-500 dark:text-gray-400">{t('Entregado')}</span>
                </div>
            </div>
        )
    }

    //Global listener 'click'
    const handleClick = (event) => {
        if (event.target.id == 'popover-sendTemplate') {

        } else {
            if (event.target.id != 'sendTemplate') {
                setTemplateButtonVisible(false)
            }

        }
    }

    // text input
    const inputChangeHandler = (event) => {
        const value = event.target.value;
        setInput(value);
    
        if (value.startsWith('/')) {
            const query = value.slice(1);
            // Filtrar plantillas personalizadas por nombre o texto
            const filtered = customTemplates.filter(template => {
                return template.name.toLowerCase().includes(query.toLowerCase()) || template.text.toLowerCase().includes(query.toLowerCase());
            });
            setFilteredTemplates(filtered);
            setShowTemplates(true);
            setSelectedTemplateIndex(filtered.length > 0 ? 0 : -1);
            setIsKeyboardNavigating(false);
        } else {
            setShowTemplates(false);
            setSelectedTemplateIndex(-1);
        }
    };




    //Enter send text msg

    const handleKeyDown = (event) => {
        if (showTemplates) {
            setIsKeyboardNavigating(true); // Activar la navegación por teclado
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedTemplateIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % filteredTemplates.length;
                    if (templateRefs.current[nextIndex]) {
                        templateRefs.current[nextIndex].scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                        });
                    }
                    return nextIndex;
                });
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedTemplateIndex((prevIndex) => {
                    const nextIndex = (prevIndex - 1 + filteredTemplates.length) % filteredTemplates.length;
                    if (templateRefs.current[nextIndex]) {
                        templateRefs.current[nextIndex].scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                        });
                    }
                    return nextIndex;
                });
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (selectedTemplateIndex !== -1 && filteredTemplates.length > 0) {
                    setInput(filteredTemplates[selectedTemplateIndex].text);
                    setShowTemplates(false);
                    setSelectedTemplateIndex(-1);
                    setIsKeyboardNavigating(false); // Desactivar la navegación por teclado
                } else {
                    msgSend();
                }
            }
        } else {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                msgSend();
            } else if (event.key === 'Enter' && event.shiftKey) {
                event.preventDefault();
                setInput((prevInput) => prevInput + '\n');
            }
        }
    };
    
    
    



    //Get media file
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            console.log('Selected file:', fileType);
            if (fileType.startsWith('image/') || fileType.startsWith('audio/') || fileType.startsWith('application/') || fileType === 'text/plain') {
                setInput('');
                if (user) {
                    sendMedia(file);
                }
            }
        }
    };

    //Get send template option 
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    //Set visible/invisible to send template
    const handleTemplateButton = () => {
        console.log('111')
        setTemplateButtonVisible(!templateButtonVisible)
    }

    //Cancel Reply
    const clearReply = () => {
        setReplyID('')
        setReplyBody('')
        setReplyState(false)
    }

    //Display Reply msg
    const processMsg = (message) => {
        if (message.body !== null && message.body !== '') {
            return message.body
        }

        else if (message.mime_type === "image/jpeg" || message.mime_type === "image/png" || message.mime_type === "image" || message.type === "sticker") {
            return 'Image message'
        }

        else if (message.mime_type === "audio/mp3" || message.mime_type === "audio/ogg" || message.mime_type === "audio/ogg; codecs=opus") {
            return 'Audio message'
        }

        else if (message.type === "document") {
            return 'Document message'
        }

        else if (message.type === "contacts") {
            return 'Contact message'
        }

        else if (message.type === "template") {
            return 'Template message'
        }

        else if (message.type === "reaction") {
            return message.emoji
        }

    }

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        const height = textarea.scrollHeight;
        if (height > 40 && height < 200) {
            textarea.style.height = `${height}px`;
            textarea.style.overflowY = 'hidden';
        } else if (height >= 200) {
            textarea.style.height = '200px';
            textarea.style.overflowY = 'auto';
        } else {
            textarea.style.height = '40px';
            textarea.style.overflowY = 'hidden';
        }
    }, [input]);

    useEffect(() => {
        const textarea = textareaRef.current;
    
        const handleDragOver = (event) => {
            if (!user) {
                return; // No permitir arrastrar si no hay usuario
            }
            event.preventDefault();
            event.stopPropagation();
            textarea.style.border = "2px dashed #000"; // Cambia el estilo del textarea para indicar que se puede soltar el archivo
        };
    
        const handleDragLeave = (event) => {
            if (!user) {
                return; // No permitir arrastrar si no hay usuario
            }
            event.preventDefault();
            event.stopPropagation();
            textarea.style.border = ""; // Restaura el estilo original del textarea
        };
    
        const handleDrop = (event) => {
            if (!user) {
                return; // No permitir arrastrar si no hay usuario
            }
            event.preventDefault();
            event.stopPropagation();
            textarea.style.border = ""; // Restaura el estilo original del textarea
    
            const file = event.dataTransfer.files[0];
            console.log(event.dataTransfer)
            if (file) {
                const confirmSend = window.confirm("¿Quieres enviar este archivo?");
                if (confirmSend) {
                    console.log('Selected file:', file);
                    sendMedia(file);
                }
            }
        };
    
        textarea.addEventListener('dragover', handleDragOver);
        textarea.addEventListener('dragleave', handleDragLeave);
        textarea.addEventListener('drop', handleDrop);
    
        return () => {
            textarea.removeEventListener('dragover', handleDragOver);
            textarea.removeEventListener('dragleave', handleDragLeave);
            textarea.removeEventListener('drop', handleDrop);
        };
    }, [user, agent]);
    
    
    

    return (
        <div className="w-[60%] 2xl:w-[60%] xl:w-[55%] lg:w-[50%] md:w-[45%] sm:w-[40%] fixed bottom-0 right-0  py-2 pl-4 pr-10 flex items-center justify-center space-x-2 ">
            <div className="mt-auto" >
                <button id='sendTemplate' onClick={handleTemplateButton} data-popover-target="" data-popover-trigger="click" type="button" className=" text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{t('Plantilla')}</button>

                <div data-popover id="popover-sendTemplate" role="tooltip" className={`absolute z-10 w-[420px] inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 opacity-100 ${templateButtonVisible ? 'visible' : 'hidden'}`}
                    style={{
                        position: 'absolute',
                        inset: 'auto auto 0px 0px',
                        margin: '0px',
                        transform: 'translate3d(-143.2px, -59.2px, 0px)'
                    }}>
                    {isLoading ? null :
                        <>
                            <div id="popover-sendTemplate" className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                <h3 className="font-semibold text-gray-900 dark:text-white">{t('Enviar una plantilla')}</h3>
                            </div>
                            <div id="popover-sendTemplate" className="px-3 py-2">
                                <div id="popover-sendTemplate" className="max-w-sm mx-auto">
                                    <div id="popover-sendTemplate" style={{ display: 'flex' }} className="p-2 mb-2 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600" role="alert">
                                        <label id="popover-sendTemplate" htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t('Seleccione una opción')}</label>
                                        <button id="popover-sendTemplate" onClick={() => sendtemplate(templateData[selectedOption])} className="px-2 py-1 ml-auto bg-primary-600 text-white rounded-lg hover:bg-primary-800" >{t('Enviar')}</button>
                                    </div>
                                    <select id="popover-sendTemplate" onChange={handleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        {
                                            templateData.map((element, index) => {
                                                return <option key={index} value={index}>{element.label}</option>
                                            })}
                                    </select>
                                    {displayTemplateMsg(templateData[selectedOption])}
                                </div>
                            </div>
                            <div id="popover-sendTemplate" data-popper-arrow></div>
                        </>
                    }
                </div>
            </div>
            {/* <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">a</button> */}
            <div className='relative w-full'>
                {showTemplates && (
                    <div className="absolute mb-1 bottom-[100%] left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto">
                        <ul>
                            {filteredTemplates.map((template, index) => (
                                <li key={index} title={template.text}
                                    ref={(el) => templateRefs.current[index] = el}
                                    className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${index === selectedTemplateIndex ? 'bg-gray-200' : ''}`}
                                    onMouseEnter={() => {
                                        if (!isKeyboardNavigating) {
                                            setSelectedTemplateIndex(index);
                                        }
                                    }}
                                    onClick={() => {
                                        setInput(template.text);
                                        setShowTemplates(false);
                                        setSelectedTemplateIndex(-1);
                                        setIsKeyboardNavigating(false); // Desactivar la navegación por teclado
                                    }}>
                                    <div className="font-semibold text-sm">{template.name}</div>
                                    <div className="text-xs text-gray-500 truncate">{template.text}</div>
                                </li>
                            ))}
                            {filteredTemplates.length === 0 && (
                                <li className="px-4 py-2 text-gray-500">{t('No hay plantillas disponibles')}</li>
                            )}
                        </ul>
                    </div>
                )}
                <textarea
                    rows="1"
                    ref={textareaRef}
                    value={input}
                    onChange={inputChangeHandler}
                    onKeyDown={handleKeyDown}
                    type="text"
                    style={{ resize: 'none', height: 'auto', minHeight: '40px', maxHeight: '200px' }}
                    className="-mb-2 w-full max-h-[200px] min-h-[40px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                    placeholder={t('Escribe un mensaje...')}
                />
            </div>

            <div style={{ width: 'max-content' }} className={`absolute ${replyState ? '' : 'hidden'} mt-auto  bottom-[100%] left-[15%] z-10 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800`} id="popover-reply" role="tooltip">
                <div style={{ width: 'max-content' }} className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <h3 className="font-semibold text-gray-900 dark:text-white">Replying a Message: {processMsg(replyBody)}</h3>
                        <svg onClick={() => { clearReply() }} style={{ marginLeft: 'auto', transition: 'color 0.3s' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 hover:text-primary-600">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                {/* <div className="px-3 py-2">
                    {processMsg(replyBody)}
                </div> */}
                <div data-popper-arrow></div>
            </div>

            <input
                className="send-button"
                type="file"
                style={{ display: 'none', marginTop: 'auto' }}
                accept="*"
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            {replyState ? null : <>
                <button onClick={() => fileInputRef.current.click()} className="mt-auto px-2 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-800" >
                    {
                        sendingMedia
                            ?
                            <svg aria-hidden="true" className="w-6 h-6 text-white animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            :
                            <DocumentArrowUpIcon className="w-6 h-6 text-white dark:text-white" />

                    }

                </button>

                <button className="mt-auto px-2 py-2 bg-primary-600 text-white rounded-lg hover:bg-blue-800"><AudioRecorder user={user} /></button>
            </>}
            <button onClick={() => msgSend()} className="mt-auto px-2 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-800">
                {
                    sendingText
                        ?
                        <svg aria-hidden="true" className="w-6 h-6 text-white animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        :
                        <PaperAirplaneIcon className="w-6 h-6 text-white dark:text-white" />

                }
            </button>

        </div>
    );
};

export default SendBar;
