export const enToast = {
    translation : {
        "Cerrando sesión...": "Logging out...",
        "Ha ocurrido un error inesperado": "An unexpected error has occurred",
        "¡Ya casi está! Un nuevo email ha sido enviado a tu nuevo correo electrónico. Por favor, confirma tu nuevo email para aplicar el cambio de email.": "Almost there! A new email has been sent to your new email. Please confirm your new email to apply the email change.",
        "Ha ocurrido un error inesperado al cargar los datos de la página. Por favor, recarga la página.": "An unexpected error occurred while loading the page data. Please reload the page.",
        "El DNI introducido no es válido" : "The entered DNI is not valid",
        "Debes subir un archivo de reclamación de gastos" : "You must upload a claim file",
        "Solicitud enviada correctamente" : "Request sent successfully",
        "Error al enviar la solicitud" : "Error sending request",
        "Debes subir un archivo de reclamación de prima única" : "You must upload a single premium claim file",
        "¡Datos descargados correctamente!" : "Data downloaded successfully!",
        "El NIE es incompatible con la nacionalidad española" : "The NIE is incompatible with Spanish nationality",
        "La edad máxima es de 64 años" : "The maximum age is 64 years",
        "La edad mínima es de 18 años" : "The minimum age is 18 years",
        "La fecha de efecto debe ser como mínimo mañana" : "The effective date must be at least tomorrow",
        "Debes subir la carta verde" : "You must upload the green card",
        "Debes subir el TIE" : "You must upload the TIE",
        "Debes subir el pasaporte o el DNI nacional" : "You must upload the passport or the national ID",
        "Usuario creado correctamente" : "User created successfully",
        "Usuario editado correctamente" : "User edited successfully",
        "El usuario ya está registrado" : "The user is already registered",
        "Las contraseñas no coinciden" : "The passwords do not match",
        "La contraseña debe tener al menos 8 caracteres" : "The password must be at least 8 characters",
    }
};