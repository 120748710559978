/* eslint-disable no-unused-vars */

import axios from 'axios';
import { set } from 'lodash';
import { React, useState} from 'react';
import { useTranslation } from 'react-i18next';

export const DocumentManager = () => {
    const { t } = useTranslation();
    const [pagina, setPagina] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitGastos = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        const gastosHipotecariosFile = document.querySelector("input[name='mortgage_expenses']").files[0];
        const apudFile = document.querySelector("input[name='declaration_notary_public']").files[0];
        const mortgageExpensesContractFile = document.querySelector("input[name='mortgage_expenses_contract']").files[0];
        const lawsuitMortgageExpensesFile = document.querySelector("input[name='lawsuit_mortgage_expenses']").files[0];

        let formData = new FormData();
        //Introducir los datos del formulario en el formData si los hay/no son nulos
        if (gastosHipotecariosFile) {
            formData.append('mortgage_expenses', gastosHipotecariosFile);
        }
        if (apudFile) {
            formData.append('declaration_notary_public', apudFile);
        }
        if (mortgageExpensesContractFile) {
            formData.append('mortgage_expenses_contract', mortgageExpensesContractFile);
        }
        if (lawsuitMortgageExpensesFile) {
            formData.append('lawsuit_mortgage_expenses', lawsuitMortgageExpensesFile);
        }

        if (formData.entries().next().value === undefined) {
            alert('No se ha seleccionado ningún archivo');
            setIsLoading(false);
            // const response = await axios.post('https://api.office.coventia.es/actualizar-plantillas-gastos/');
            const response = await axios.post('https://api.office.coventia.es/actualizar-plantillas-gastos/');
            const response2 = await axios.post('https://api.office.coventia.es/actualizar-plantillas-judicial/');
            
            return;
        }

        try {
            const response = await axios.post('https://api.office.coventia.es/manager-upload-templates/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            const response2 = await axios.post('https://api.office.coventia.es/actualizar-plantillas-gastos/');
            alert('Archivos subidos correctamente');
            setIsLoading(false);
        }
        catch (error) {
            console.error(error);
            alert('Error al subir los archivos');
            setIsLoading(false);
        }
    }

    const handleSubmitPrima = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const singlePremiumDefaultFile = document.querySelector("input[name='single_premium_default']").files[0];
        const singlePremiumCaixaFile = document.querySelector("input[name='single_premium_caixa']").files[0];
        const singlePremiumSantanderFile = document.querySelector("input[name='single_premium_santander']").files[0];
        const singlePremiumContractFile = document.querySelector("input[name='single_premium_contract']").files[0];

        let formData = new FormData();
        //Introducir los datos del formulario en el formData si los hay/no son nulos
        if (singlePremiumDefaultFile) {
            formData.append('single_premium_default', singlePremiumDefaultFile);
        }
        if (singlePremiumCaixaFile) {
            formData.append('single_premium_caixa', singlePremiumCaixaFile);
        }
        if (singlePremiumSantanderFile) {
            formData.append('single_premium_santander', singlePremiumSantanderFile);
        }
        if (singlePremiumContractFile) {
            formData.append('single_premium_contract', singlePremiumContractFile);
        }

        if (formData.entries().next().value === undefined) {
            alert('No se ha seleccionado ningún archivo');
            const response = await axios.post('https://api.office.coventia.es/actualizar-plantillas-prima/');
            setIsLoading(false);
            return;
        }
        try {
            const response = await axios.post('https://api.office.coventia.es/manager-upload-templates/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const response2 = await axios.post('https://api.office.coventia.es/actualizar-plantillas-prima/');
            alert('Archivos subidos correctamente');
            setIsLoading(false);
        }
        catch (error) {
            console.error(error);
            alert('Error al subir los archivos');
            setIsLoading(false);
        }
    }

    const activeClass = "inline-block p-4 text-primary-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-primary-500";
    const noActiveClass = "inline-block p-4 rounded-t-lg hover:text-gray-600 bg-gray-50 dark:bg-gray-800 dark:hover:text-gray-300";

    return (
        <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800" id="defaultTab" data-tabs-toggle="#defaultTabContent" role="tablist">
                <li className="me-2">
                    <button id="gastos-hipotecarios-tab" data-tabs-target="#gastos-hipotecarios" type="button" role="tab" aria-controls="gastos-hipotecarios" aria-selected="true" className={ pagina == 0 ? activeClass : noActiveClass} onClick={() => { setPagina(0) }}>{t('Gastos hipotecarios')}</button>
                </li>
                <li className="me-2">
                    <button id="prima-unica-tab" data-tabs-target="#prima-unica" type="button" role="tab" aria-controls="prima-unica" aria-selected="false" className={ pagina == 1 ? activeClass : noActiveClass} onClick={() => { setPagina(1) }}>{t('Prima única')}</button>
                </li>
            </ul>
            <div id="defaultTabContent">

                <div className={"p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 " + (pagina == 0 ? "" : "hidden")} id="gastos-hipotecarios" role="tabpanel" aria-labelledby="gastos-hipotecarios-tab">
                    <div className="flex flex-col space-y-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Reclamación gastos hipotecarios')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='mortgage_expenses' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/6d0c5458-a01c-11ee-8c90-0242ac120002-RECLAMACION-EXTRAJUDICIAL-GASTOS.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Declaración APUD')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='declaration_notary_public' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/6d0c5458-a01c-11ee-8c90-0242ac120002-DECLARACION-APUD.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Contrato gastos hipotecarios')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='mortgage_expenses_contract' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/6d0c5458-a01c-11ee-8c90-0242ac120002-CONTRATO-GASTOS.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4">
                    <hr className='my-4 border-gray-200 dark:border-gray-700' />
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Demanda de gastos')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='lawsuit_mortgage_expenses' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/6d0c5458-a01c-11ee-8c90-0242ac120002-DEMANDA-GASTOS.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <button onClick={handleSubmitGastos} disabled={isLoading} className="w-full mt-8 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:bg-primary-700">
                        {t('Enviar')}
                    </button>
                </div>
                <div className={"p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 " + (pagina == 1 ? "" : "hidden")} id="prima-unica" role="tabpanel" aria-labelledby="prima-unica-tab">
                    <div className="flex flex-col space-y-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Prima única (Por defecto)')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='single_premium_default' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/174aaee7-0785-4fd2-92d1-04181e3b94ba-RECLAMACION-PRIMA-UNICA.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Prima única (CaixaBank)')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='single_premium_caixa' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/174aaee7-0785-4fd2-92d1-04181e3b94ba-RECLAMACION-PRIMA-UNICA-CAIXABANK.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Prima única (Santander)')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='single_premium_santander' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/174aaee7-0785-4fd2-92d1-04181e3b94ba-RECLAMACION-PRIMA-UNICA-POPULAR-SANTANDER.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-4">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('Contrato prima única')}</label>
                        <div className="flex items-center space-x-2">
                            <input name='single_premium_contract' className="flex-grow text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" accept='.docx' />
                            <a href="https://coventia-files-s3.s3.eu-west-3.amazonaws.com/public/formularios-word/174aaee7-0785-4fd2-92d1-04181e3b94ba-CONTRATO-PRESTACION-SERVICIOS.docx" download>
                                <button className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m0 0l-8-8m8 8l8-8" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <button onClick={handleSubmitPrima} disabled={isLoading} className="w-full mt-8 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:bg-primary-700">
                        {t('Enviar')}
                    </button>
                </div>
            </div>
        </div>
    );
}
