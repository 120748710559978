import axios from "axios";

export async function buscarDNIEnBaserow(dni, form) {
    try {
        let filter = encodeURIComponent(`{"filter_type":"AND","filters":[{"type":"equal","field":"DNI","value":"${dni}"}],"groups":[]}`);
        const urlDatosPersonales = `https://db.coventia.es/api/database/rows/table/871/?user_field_names=true&filters=${filter}`;

        const response = await axios({
            method: "GET",
            url: urlDatosPersonales,
            headers: {
                Authorization: `Token ${import.meta.env.VITE_BASEROW_AUTH_TOKEN}`,
                "Content-Type": "application/json"
            },
        });

        if (response.data.results.length > 0) {
            const confirmar = window.confirm('El DNI se encuentra en la base de datos. ¿Quieres rellenar el formulario con los datos almacenados?');
            if (confirmar) {
                // Get linked data
                let linkedIds = [];
                let url = '';
                if (form == 'Prima') {
                    linkedIds = response.data.results[0]['Prima Unica'].map(item => item['id']);
                    url = `https://db.coventia.es/api/database/rows/table/869/`;
                } else if (form == 'Gastos') {
                    linkedIds = response.data.results[0]['Gastos Hipotecarios'].map(item => item['id']);
                    url = `https://db.coventia.es/api/database/rows/table/870/`;
                }

                let response2Data = [];

                for (const id of linkedIds) {
                    const response2 = await axios({
                        method: "GET",
                        url: `${url}${id}/`,
                        params: {
                            user_field_names: true
                        },
                        headers: {
                            Authorization: `Token ${import.meta.env.VITE_BASEROW_AUTH_TOKEN}`,
                            "Content-Type": "application/json"
                        },
                    });

                    for (const key in response2.data) {
                        if (response2.data[key] === null || response2.data[key] === undefined) {
                            delete response2.data[key];
                        }
                    }

                    response2Data.push(response2.data);
                }

                //Retornar ambos objetos
                console.log('Datos encontrados en Baserow:', response.data.results[0], response2Data);
                return [response.data.results[0], response2Data];
            } else {
                return false;
            }
        }
    } catch (error) {
        console.error('Error al buscar el DNI en Baserow:', error);
        return false;
    }
}