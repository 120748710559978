import { createClient } from "@supabase/supabase-js";
export const GetUsuarioXCorreduria = async () => {
    const supabaseClient = createClient(
        'https://uoclqlfzojdjrhcqhgef.supabase.co',
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
    )

    const { data: correduria, error } = await supabaseClient.from('usuarioxcorreduria').select('*').single();
    if (error) {
        return error;
    }
    return correduria;
}