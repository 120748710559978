import { useEffect, useRef, useState } from 'react'
import ApexCharts from 'apexcharts';
import { calculateChartData } from '../../utils';
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from 'react-i18next';

export const BancoGraph = () => {
    const { t } = useTranslation();
    const chartRef = useRef(null);
    const [sliderValue, setSliderValue] = useState(1000);
    const [chartData, setChartData] = useState({
        currentPolicy: [],
        coventiaPolicy: [],
        yearSavings: [],
    });


    const handleSliderChange = (event) => { //Introducir a chartData el valor del slider en currentPolicy
        setSliderValue(event.target.value);
    };

    let currentYear = new Date().getFullYear();
    let years = Array.from({ length: 21 }, (_, i) => currentYear + i);

    useEffect(() => {
        const calculatedData = calculateChartData(sliderValue, 'Banco');
        setChartData(calculatedData);
    }, [sliderValue]);

    useEffect(() => {

        if (chartRef.current) {
            chartRef.current.updateSeries([
                {
                    name: t("Precio actual"),
                    data: chartData.currentPolicy.map(item => item.value),
                },
                {
                    name: t("Precio con Coventia"),
                    data: chartData.coventiaPolicy.map(item => item.value),
                },
                {
                    name: t("Ahorro"),
                    data: chartData.yearSavings.map(item => item.value),
                }
            ]);
            chartRef.current.updateOptions({
                xaxis: {
                    categories: chartData.currentPolicy.map(item => item.date),
                },
            });
        } else {

            let options = {
                chart: {
                    height: "100%",
                    maxWidth: "100%",
                    type: "area",
                    fontFamily: "Inter, sans-serif",
                    dropShadow: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false,
                    },
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.55,
                        opacityTo: 0,
                        shade: "#1C64F2",
                        gradientToColors: ["#1C64F2"],
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 4,
                },
                grid: {
                    show: false,
                    strokeDashArray: 4,
                    padding: {
                        left: 2,
                        right: 2,
                        top: 0
                    },
                },
                series: [
                    {
                        name: "Precio actual",
                        data: chartData.currentPolicy.map((data) => data.value),
                        color: "#a0beff",
                    },
                    {
                        name: "Precio con Coventia",
                        data: chartData.coventiaPolicy.map((data) => data.value),
                        color: "#5b73f9",
                    },
                    {
                        name: "Ahorro",
                        data: chartData.yearSavings.map((data) => data.value),
                        color: "#2933aa"
                    }
                ],
                xaxis: {
                    categories: years,
                    labels: {
                        show: true,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    show: false,
                    labels: {
                        formatter: function (value) {
                            return Math.round(value);
                        }
                    },
                },
            }

            if (document.getElementById("area-chart") && typeof ApexCharts !== 'undefined') {
                chartRef.current = new ApexCharts(document.getElementById("area-chart"), options);
                chartRef.current.render();
            }
        }
    }, [chartData]);

    return (
        <>
            <div className="flex flex-col">
                <h1 className="leading-none mb-1 text-2xl font-bold text-gray-900 dark:text-white pb-2">{t('Selecciona la prima de origen')}</h1>
                <div className="flex items-center text-sm mb-5 ">
                    <InformationCircleIcon className="h-5 w-5 mr-2 text-gray-400" />
                    <p className='text-gray-700 dark:text-gray-400'>{t('Ahorro estimado para una persona de 45 años y que mantiene su seguro hasta los 65 años')}.</p>
                </div>
            </div>

            <div className="relative mb-6">
                <label htmlFor="default-range" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">{sliderValue}€</label>
                <input id="default-range" type="range" value={sliderValue} min="10" max="2000" onChange={handleSliderChange} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">{t('Mínimo')} (10€)</span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">1000€</span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">{t('Máximo')} (2000€)</span>
            </div>

            <div id="area-chart"></div>
        </>
    )
}
