export const enDatabase = {
    translation : {
        "Base de datos" : "Database",
        "Buscar" : "Search",
        "Mostrar" : "Show",
        "Acciones" : "Actions",
        "Cargando..." : "Loading...",
        "Cargando" : "Loading",
        "No hay resultados" : "No results",
        "Email" : "Email",
        "Estado" : "State",
        "Fecha de creación" : "Creation date",  
        "Teléfono" : "Phone",
        "¿Debo pedir la póliza?" : "Should I ask for the policy?",
        "Nombre" : "Name",
        "Apellido 1" : "Surname 1",
        "Apellido 2" : "Surname 2",
        "DNI" : "DNI",
        "Fecha de nacimiento" : "Date of birth",
        "Fecha fin del seguro" : "End date of the insurance",
        "Edad" : "Age",
        "Dirección" : "Address",
        "Código postal" : "Postal code",
        "Código Postal" : "Postal Code",
        "Import póliza de origen" : "Import original policy",
        "Importe póliza de origen" : "Import original policy",
        "Importe nueva póliza" : "Amount of new policy",
        "Suma asegurada" : "Insured sum",
        "Prima de muerte" : "Death premium",
        "Prima de invalidez" : "Disability premium",
        "Tipo de pago" : "Type of payment",
        "Fraccionamiento" : "Fractionation",
        "IBAN" : "IBAN",
        "Cobertura" : "Coverage",
        "Archivo Presupuesto" : "Budget File",
        "Archivo DNI" : "DNI File",
        "Archivo DNI Trasera" : "DNI Back File",
        "Archivo Póliza Actual" : "Current Policy File",
        "Archivo Recibo" : "Receipt File",
        "Archivo SEPA" : "SEPA File",
        "Archivo solicitud" : "Request File",
        "Archivo cancelación" : "Cancellation File",
        "Archivo CCPP" : "CCPP File",
        "Propietario" : "Owner",
        "Abrir registro" : "Open record",
        "Archivo no subido" : "File not uploaded",
        "Ver archivo" : "View file",
        "Cerrar" : "Close",
        "Importe póliza de origen" : "Amount of original policy",
        "Buscar" : "Search",
        "Mostrando página" : "Showing page",
        "de" : "of",
        "Recuperar presupuesto" : "Recover budget",
        "Web de origen" : "Source website",
        "Solicitar un CSV completo por email" : "Request a full CSV by email",
        "Te enviaremos al email que nos indiques un archivo CSV con todos los registros de la base de datos.": "We will send you to the email you indicate a CSV file with all the records of the database.",
        "Depende del tamaño de tu base de datos, puedes tardar unos minutos en recibir el correo.": "Depending on the size of your database, you may take a few minutes to receive the email.",
        "Cancelar" : "Cancel",
        "Solicitar CSV": "Request CSV",
        "Se ha solicitado el CSV": "The CSV has been requested",
        "Ha ocurrido un error inesperado al enviar el CSV": "An unexpected error occurred while sending the CSV",
        "Donde" : "Where",
        "Y" : "And",
        "contiene" : "contains",
        "no contiene" : "not contains",
        "es igual a" : "is equal to",
        "no es igual a" : "is not equal to",
        "no está vacío" : "not empty",
        "está vacío" : "is empty",
        "es hoy" : "is today",
        "es antes de hoy" : "is before today",
        "es después de hoy" : "is after today",
        "Seleccione una opción" : "Select an option",
        "Si, Pedir Poliza" : "Yes, ask for policy",
        "Fallecimiento por cualquier causa" : "Death from any cause",
        "Fallecimiento e invalidez por cualquier causa" : "Death and disability from any cause",
        "Anualmente" : "Annually",
        "Semestralmente" : "Semi-annually",
        "Trimestralmente" : "Quarterly",
        "Mensualmente" : "Monthly",
        "Añadir Filtro" : "Add Filter",
        "Limpiar Filtros" : "Clean Filters",
        "Valor" : "Value",
        "Filtro" : "Filter",
        "Eliminar registro" : "Delete record",
        "Ha ocurrido un error inesperado al eliminar el registro": "An unexpected error occurred while deleting the record",
        "Registro eliminado, recargando...": "Record deleted, reloading...",
    }
};