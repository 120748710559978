export const enSidebar = {
    translation : {   
        "Leido": "Read",
        "Enviado": "Sent",
        "Llegado":"Delivered",
        "Fallado":"Failed",
        'Mensaje de imagen':'Image message',
        'Mensaje de audio' : 'Audio message',
        'Mensaje de documento': 'Document message',
        'Mensaje de contacto' :'Contact message',
        'Mensaje de plantilla' : 'Template message',
        'Respuesta marcada' : 'Reply checked',
        'Mensaje no admitido' : 'Unsupported message',
        'Error al enviar el mensaje': "Message send failed",
        "Reacción":'Reaction',
        'Ninguno':'None',
        'Conexión perdida. Vuelve a intentarlo más tarde.':'Connection lost, Try again later',
        'Buscar':"Search",
        'Buscar por número':'Search by number',
        'Volver':'Back',
        'Marcar como no leído':'Mark as unread',
        'Cambiar agente':'Change agent',
        'Agregar nueva conversación':'Add new conversation',
        'Agreger un numero de telefono':'Add a phone number',
        'Mostrar solo no leídos':'Show only unread',
        'Nadie':'Nobody',
        'Agentes':'Agents',
        'Borrar selección':'Clear selected',
        'Sin asignar':'Not assigned',
        "Buscar usuario": "Search user",
    }
};