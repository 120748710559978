export const enProfile = {

    translation : {
        "Ajustes del usuario": "User settings",
        "Grupo" : "Group",
        "Ver Id de usuario" : "View user ID",
        "Cambiar idioma" : "Change language",
        "Español" : "Spanish",
        "Inglés" : "English",
        "Información general" : "General information",
        "Nombre completo" : "Full name",
        "Guardar" : "Save",
        "Guardando..." : "Saving...",
        "Cambiar contraseña" : "Change password",
        "Nueva contraseña" : "New password",
        "Confirmar nueva contraseña" : "Confirm new password",
        "Cambiando contraseña..." : "Changing password...",
        "Cambiar" : "Change",
        "Tu ID de usuario es: " : "Your user ID is",
        "Perfil" : "Profile",
        "Ajustes de WhatsApp" : "WhatsApp settings",
        "Cambiando ajustes..." : "Changing settings...",
        "La configuración de WhatsApp ha sido cambiada correctamente" : "WhatsApp settings have been changed successfully",
    }
};