export const enMsgSearch = {
    translation:{
        'Todo':'All',
        'Mensaje':'Message',
        'Audio':'Audio',
        'Documento':'Document',
        'Buscar':'Search',
        'Imagen':'Image',
        'es':'en',
        'Lu':'Mo',
        'Ma':'Tu',
        'Mi':'We',
        'Ju':'Th',
        'Vi':'Fr',
        'Sa':'Sa',
        'Do':'Su',
    }
}