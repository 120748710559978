import { Routes, Route } from "react-router-dom";
import { Home, Login, ForgotPassword, Onboarding, CrearPresupuesto, RecuperarPresupuesto, SimularAhorro, GenerarDocumentos, Database, Profile, Manager, Page404, Whatsapp} from '../pages';
import { RootLayout } from "../components/RootLayout";
import ProtectedRoute from "../components/ProtectedRoute";
import { EmployerManager } from "../pages/EmployerManager";

export function AppRoutes() {
    return (
        <Routes>
          <Route path="/auth/profile" element={<ProtectedRoute><RootLayout><Profile /></RootLayout></ProtectedRoute>} />
          <Route path="/" element={<ProtectedRoute><RootLayout active="inicio"><Home /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/crear_presupuesto" element={<ProtectedRoute><RootLayout active="crear_presupuesto"><CrearPresupuesto /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/recuperar_presupuesto" element={<ProtectedRoute><RootLayout active="recuperar_presupuesto"><RecuperarPresupuesto /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/simuladores" element={<ProtectedRoute><RootLayout active="simuladores"><SimularAhorro /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/generar_documentos" element={<ProtectedRoute><RootLayout active="generar_documentos"><GenerarDocumentos /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/db" element={<ProtectedRoute><RootLayout active="db"><Database /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/manager" element={<ProtectedRoute><RootLayout active="manager"><Manager /></RootLayout></ProtectedRoute>} />
          <Route path="/:empresa/whatsapp" element={<ProtectedRoute><RootLayout active="whatsapp"><Whatsapp /></RootLayout></ProtectedRoute>} />
          <Route path="/auth/onboarding" element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />
          <Route path="/panel_empleado" element={<ProtectedRoute><RootLayout><EmployerManager /></RootLayout></ProtectedRoute>} />
          {/* Unprotected routes */}
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot_password" element={<ForgotPassword />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      );
    }
