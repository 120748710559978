import React, { Component } from 'react'
import PubSub, { publish } from 'pubsub-js'
import getData from '../../api/ApiRequestWhatsappGetData'; // 导入函数
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { formatDate, formatTime, formatTimestamp, formatClock } from '../../utils/convertTimestamp'
import { LoadingComponent } from '../LoadingComponent';
import MsgSearch from './MsgSearch';
import GoBottom from './GoBottom';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from "framer-motion";
import supabaseClient from '../../utils/supabaseInit';
import { map } from 'lodash';
//https://smiling-peaceful-prawn.ngrok-free.app
//localhost:3000
const socketOnMessageUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + '/userdate?wa_id='
const listTemplateUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + '/listtemplate'
const getMsgUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}` + '/getmsg'
const webSocketUrl = `wss://${import.meta.env.VITE_COVENTIA_WA_URL}`
const socketUUID = uuidv4()
const { data } = await supabaseClient.auth.getSession()
//'https://smiling-peaceful-prawn.ngrok-free.app'

export default class MsgBox extends Component {
    id = 0
    index = 0
    indexBottom = 0
    initIndex = 0
    updating = false
    lastScrollToHeight = 0
    lastMsgTime = 0
    searchMode = false
    replyRequestedList = {}
    contextMenuRef = React.createRef();
    messagesRef = React.createRef();
    replyingID = {}
    noSrcoll = false
    intervalId
    socket
    reconnectInterval
    constructor(props) {
        super(props);
        this.state = {
            'updating': false,
            searchDate: ['', ''],
            searchItem: 'All',
            searchText: '',
            reply: {},
            date: [],
            needupdate: false,
            templateData: [],
            replyCheck: false,
            haveNewMsg: false,
            goToBot: false
        }
        this.t = props.t
    }

    getData = getData
    formatTimestamp = formatTimestamp
    formatClock = formatClock
    formatDate = formatDate
    formatTime = formatTime

    //#region Life cycle
    componentDidMount() {
        const access_token = data.session.access_token
        console.log('Component is mounted.');
        document.addEventListener("contextmenu", this.showContextMenu);
        document.addEventListener("click", this.click);
        //ChangeChat: called from App.js when user has changed a chat
        this.token = PubSub.subscribe('ChangeChat', this.changeChat);

        //MsgSearch: called from MsgSearch/index.jsx when user want search some messages
        this.token2 = PubSub.subscribe('MsgSearch', this.MsgSearch)
        this.token3 = PubSub.subscribe('MsgSend', this.msgSend)
        this.token4 = PubSub.subscribe('GoToBottom', this.goToButtom)

        //relocation 
        this.scroll();

        //timer 
        this.timerId = setInterval(async () => {
            if (!this.messagesRef.current) {
                return
            }

            if (this.messagesRef.current.scrollHeight - this.messagesRef.current.scrollTop > 3000) {
                PubSub.publish('GoBottomVisible', { state: true, haveNewMsg: this.state.haveNewMsg })
            } else if (Math.abs(this.messagesRef.current.scrollHeight - this.messagesRef.current.scrollTop - this.messagesRef.current.clientHeight) <= 30) {
                if (this.state.haveNewMsg == true) {
                    this.setState({ haveNewMsg: false })
                }

                PubSub.publish('GoBottomVisible', { state: null, haveNewMsg: false })
            } else {
                PubSub.publish('GoBottomVisible', { state: false, haveNewMsg: this.state.haveNewMsg })
            }
            if (this.state.updating == false) {
                await this.getLastMsgs();
            }
            //forceupdate as need 
            if (this.state.needupdate) {
                this.setState({ needupdate: !this.state.needupdate })
            }

        }, 400);

        //Get api /listtemplate
        const config = {

            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                "ngrok-skip-browser-warning": "69420",
                'Authorization': access_token,
            },

        };

        axios.get(listTemplateUrl, config)
            .then(response => {
                this.setState({ templateData: response.data })
            })
            .catch(error => {
                console.error('Error:', error);
            });
        const connectWebSocket = () => {
            this.socket = new WebSocket(webSocketUrl + '?userId=' + socketUUID);
            //Create Sokect
            this.socket.onopen = async () => {
                console.log("Connection established");
                if (this.reconnectInterval) {
                    clearInterval(this.reconnectInterval);
                }
                this.intervalId = setInterval(() => {
                    this.socket.send(`ping@${socketUUID}`)
                }, 20 * 1000);
            };

            this.socket.onmessage = async (event) => {
                this.noSrcoll == false
                const access_token = data.session.access_token
                //Confirm wa_id current is equal to wa_id event
                const blob = event.data;
                if (blob == 'pong') {
                    return
                }
                const [msg, target] = JSON.parse(blob)

                if (target !== this.state.user) {
                    return
                }

                //if date empty
                if (this.state.date && this.state.date.length === 0) {
                    //Get api /userdate?wa_id=

                    const config = {

                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                            "ngrok-skip-browser-warning": "69420",
                            'Authorization': access_token,
                        },
                    };
                    axios.get(socketOnMessageUrl + this.state.user, config)
                        .then(response => {
                            this.setState({ date: response.data[0]?.message_date })
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                } else {
                    //Get new msg
                    PubSub.publish('MsgSend', null)
                    await this.getNewMsgs();
                    this.scroll()
                }
            };

            this.socket.onclose = () => {
                console.log("Connection closed");
                clearInterval(intervalId);
                if (!this.reconnectInterval) {
                    this.reconnectInterval = setInterval(() => {
                        console.log('Attempting to reconnect...');
                        connectWebSocket();
                    }, 5000);
                }
            };
        }
        connectWebSocket()
    }

    componentWillUnmount() {
        //clear all listener and timer
        PubSub.unsubscribe(this.token);
        PubSub.unsubscribe(this.token2)
        clearInterval(this.timerid)
        document.removeEventListener('click', this.click);
        document.removeEventListener("contextmenu", this.showContextMenu);
    }

    componentDidUpdate() {
        console.log('Component is updated.');
        //relocation
        this.lastMsgTime = 0
        if (this.searchMode == false) {
            if (this.noSrcoll == false) {
                this.scroll();
            }

        }

    }
    //#endregion 

    //#region function (PubSub , listeners)

    //Right click in MsgBox for reply a msg
    showContextMenu = (event) => {
        if (event.target.id.split('.')[0] === 'wamid') {
            event.preventDefault();
            const x = event.clientX + 'px';
            const y = event.clientY + 'px';
            if (this.contextMenuRef.current) {
                this.contextMenuRef.current.style.display = 'block';
                this.contextMenuRef.current.style.position = 'fixed';
                this.contextMenuRef.current.style.top = y;
                this.contextMenuRef.current.style.left = x;
                this.replyingID = { msgid: event.target.id, msg: this.state.msg.messages.find(message => message[1].id === event.target.id)[1] }
            }
        }
    }
    goToButtom = () => {

        if (this.messagesRef.current) {
            this.lastScrollToHeight = 0
            this.setState({ haveNewMsg: false })
            this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeight

        }
    }
    //Pubsub : MsgSend
    msgSend = () => {
        const today = new Date();

        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;
        if (!this.state.date.includes(formattedDate)) {
            this.state.date.push(formattedDate)
        }
    }

    //Global listener for cancel right click
    click = (event) => {
        if (this.contextMenuRef.current) {
            this.contextMenuRef.current.style.display = 'none';
        }
    }

    //Reply is selected
    checkReply = () => {
        PubSub.publish('Reply', this.replyingID)
    }

    //PubSub : ChangeChat
    changeChat = async (_, data) => {

        if (this.state.user != data.user) {

            while (this.state.updating) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            //this.updating = true
            this.setState({ updating: true })
            this.setState({
                'updating': false,
                searchDate: ['', ''],
                searchItem: 'All',
                searchText: '',
                date: [],
                needupdate: false,
                replyCheck: false,
                haveNewMsg: false,
            })
            delete this.state.msg
            //dont use setState here
            this.state = { user: data.user, date: data.date, }
            this.id = 0
            this.index = 0
            this.initIndex = data.date.length
            this.indexBottom = 0
            this.updating = false
            this.lastScrollToHeight = 0
            this.lastMsgTime = 0
            await this.getLastMsgs();
            this.setState({ updating: false })
        }
        this.setState({ user: data.user, date: data.date })
        this.lastScrollToHeight = 0
        this.lastMsgTime = 0

        this.scroll()
    }

    //Pubsub : MsgSearch
    MsgSearch = async (_, data) => {

        //find a date
        this.setState(data)
        this.searchMode = true
        const targetDate = new Date(data.searchDate[0]);
        const targetTimestamp = targetDate.getTime();
        function findClosestDateIndex(dateString, dateArray) {
            const dates = dateArray.map((dateStr, index) => ({
                date: new Date(dateStr.split('-').reverse().join('-')),
                index: index
            }));
            let closestDateIndex = -1;
            let closestDate = null;

            for (let { date, index } of dates) {

                if (date.getTime() === targetDate.getTime()) {
                    return index;
                }

                if (date < targetDate && (!closestDate || date > closestDate)) {
                    closestDate = date;
                    closestDateIndex = index;
                }
            }
            return closestDateIndex;
        }


        let matchingIndex = findClosestDateIndex(targetDate, this.state.date)

        if (this.state.date[matchingIndex] == undefined) {
            //Clear search param
            if (data.searchDate.toString() == ['', ''].toString() && data.searchItem == 'All') {
                this.setState({ updating: true })
                delete this.state.msg
                this.setState({ searchDate: ['', ''] })
                this.index = 0
                this.initIndex = this.state.date.length
                this.id = 0
                this.index = 0
                this.indexBottom = 0
                this.updating = false
                this.lastScrollToHeight = 0
                this.lastMsgTime = 0
                await this.getLastMsgs();
                this.setState({ updating: false })
            }
            //Cant find any date
            else {
                matchingIndex = 0
                this.index = 0
                this.indexBottom = 1
                this.setState({ updating: true })
                delete this.state.msg
                this.lastScrollToHeight = this.messagesRef.current.scrollHeight
                this.setState({ searchDate: ['', ''] })
                this.initIndex = matchingIndex
                this.noSrcoll == false
                this.setState(await this.getData(matchingIndex, 'last', this.state))
                this.setState({ updating: false })
                this.messagesRef.current.scrollTop = 0
            }

        }
        //search with date
        else {
            this.index = 0
            this.initIndex = 0
            this.indexBottom = 1
            this.setState({ updating: true })
            delete this.state.msg
            this.lastScrollToHeight = this.messagesRef.current.scrollHeight
            this.setState({ searchDate: ['', ''] })
            this.noSrcoll == false
            this.setState(await this.getData(matchingIndex, 'last', this.state))
            this.initIndex = matchingIndex
            this.setState({ updating: false })
            this.messagesRef.current.scrollTop = 0
        }

    }
    //#endregion

    //#region Add message

    //use when component is updated (message added)
    scroll = () => {
        if (this.messagesRef.current) {

            if (this.lastScrollToHeight === 'top') {
                console.log('2')
                this.messagesRef.current.scrollTop = 0;
            }
            else if (this.lastScrollToHeight === 'reset') {
                console.log('3')
                this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeight
            }
            else if (this.lastScrollToHeight === 'stay') {
                console.log('4')
                return
            } else if (this.lastScrollToHeight === 'newmsg') {
                if (this.messagesRef.current.scrollHeight - this.messagesRef.current.scrollTop <= this.messagesRef.current.clientHeight + 500) {
                    const scrollToHeight = this.messagesRef.current.scrollHeight
                    this.messagesRef.current.scrollTop = scrollToHeight;
                }
            }
            else {
                console.log('6')
                const scrollToHeight = this.messagesRef.current.scrollHeight - this.lastScrollToHeight
                this.messagesRef.current.scrollTop = scrollToHeight;

            }

        }
    };

    //Get old msg
    getLastMsgs = async () => {

        this.lastMsgTime = 0
        if (this.state.searchDate != ['', '']) {

        }
        if (this.messagesRef.current == null || this.messagesRef.current == undefined) {
            return
        }

        if (this.messagesRef.current.scrollTop < 10 == false) {
            if (this.messagesRef.current.scrollHeight - this.messagesRef.current.scrollTop <= this.messagesRef.current.clientHeight + 10 == false) {
                return
            }

        }

        if (!(this.state.date !== undefined && this.state.date !== null && this.index < this.state.date.length)) {
            return
        }

        if (!(this.state.updating === false && this.state.date.length !== 0)) {
            return
        }

        //this.updating = true

        if (this.state.searchDate[0] == null || this.state.searchDate[0] == undefined || this.state.searchDate[0] == '') {
            if (this.messagesRef.current.scrollTop < 10 && this.state.date[this.initIndex - this.index - 1] !== undefined) {
                if (this.state.date[this.initIndex - this.index - 1] === undefined) {
                    return
                }
                this.lastScrollToHeight = this.messagesRef.current.scrollHeight
                this.setState({ updating: true })
                this.searchMode = false
                this.noSrcoll == false
                this.setState(await this.getData(this.initIndex - this.index - 1, 'last', this.state))
                if (this.state.date[this.initIndex - this.index - 1] !== undefined) {
                    this.index++
                } else {
                    this.index = this.state.date.length + 1
                }
                this.setState({ updating: false })
            }
            else if (this.messagesRef.current.scrollHeight - this.messagesRef.current.scrollTop <= this.messagesRef.current.clientHeight + 10) {
                console.log(this.initIndex, this.indexBottom)
                if (this.state.date[this.initIndex - 1 + this.indexBottom] === undefined) {
                    //console.log(this.initIndex ,this.indexBottom)
                    return
                }

                this.setState({ updating: true })
                this.searchMode = false
                this.lastScrollToHeight = 'stay'
                this.noSrcoll == false
                let data = await this.getData(this.initIndex - 1 + this.indexBottom, 'last', this.state)

                this.setState(data)
                this.setState({ updating: false })


                this.indexBottom++


            }

        }



        // const dateString = this.state.date[this.state.date.length - this.index - 1];
        // const [day, month, year] = dateString.split('-').map(Number);
        // const date = new Date(year, month - 1, day);
        // const timestamp = date.getTime() / 1000;


        // if (timestamp <= formatTimestamp(this.state.searchDate[0])) {
        //     return
        // }
        // this.setState({ updating: true })
        // this.lastScrollToHeight = this.messagesRef.current.scrollHeight
        // //console.log(this.state.date[this.state.date.length - this.index - 1]===this.searchDate[0])
        // this.setState(await this.getData(this.state.date.length - this.index - 1, 'last', this.state))
        // this.setState({ updating: false })



    }

    //Get new msg
    getNewMsgs = async () => {
        if (this.state.date !== undefined) {
            this.setState({ updating: true })
            this.noSrcoll == false
            this.lastScrollToHeight = 'newmsg'
            this.setState(await this.getData(this.state.date.length - 1, 'new', this.state))
            if (this.state.msg && this.state.msg.messages[this.state.msg.messages.length - 1][1].from === this.state.user) {
                this.setState({ haveNewMsg: true })
            }

            //this.lastScrollToHeight=this.messagesRef.current.scrollHeight
            this.setState({ updating: false })
        }
    }
    //#endregion

    //#region Process messages

    //Check msg type , add time hint
    processMsg = (message, isReplyMsg) => {

        let timestamp = parseInt(message.timestamp)
        let timemsg
        if (this.lastMsgTime === 0) {
            this.lastMsgTime = message.timestamp

        }
        if (timestamp > this.lastMsgTime) {

            timemsg = (<p className='mb-3 text-center text-gray-500 dark:text-gray-400' >{this.formatTime(timestamp)}<br /></p>)
            this.lastMsgTime = timestamp + 600
        }
        if (message.status === 'failed') {

            if (this.checkMsg(message, this.state.searchDate, 'Message', this.state.searchItem, this.state.searchText)) {

                return (
                    <div key={message.id}>
                        {timemsg === undefined ? null : timemsg}
                        {/* <p className='mb-3 text-center text-gray-500 dark:text-gray-400'></p> */}
                        <div className="p-2 mb-4 mt-4 text-sm text-center text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
                            <span className="font-medium">{this.t('¡Alerta de advertencia!')}</span> {this.t('El mensaje no se pudo enviar porque han pasado más de 24 horas desde la última vez que el cliente respondió a este número.')}
                        </div>
                    </div>)
            }
        }

        if (message.body !== null && message.body !== '' && 'body' in message && message.mime_type !== "image/jpeg" && message.mime_type !== "image/png" && message.mime_type !== "image" && message.type !== "sticker") {
            let returnmsg = this.textMsg(message, isReplyMsg)
            if (returnmsg !== -1) {

                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else if (message.mime_type === "image/jpeg" || message.mime_type === "image/png" || message.mime_type === "image" || message.type === "sticker") {
            let returnmsg = this.imgMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }

        } else if (message.mime_type === "audio/mp3" || message.mime_type === "audio/ogg" || message.mime_type === "audio/ogg; codecs=opus") {
            let returnmsg = this.audioMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }

        } else if (message.type === "document") {
            let returnmsg = this.documentMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else if (message.type === "contacts") {
            let returnmsg = this.contactMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else if (message.type === "template") {
            let returnmsg = this.templateMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else if (message.type === "button" && message.replyTo !== "") {
            message.body = this.t('Responder al mensaje de plantilla')
            let returnmsg = this.textMsg(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else if (message.type === "reaction") {
            let returnmsg = this.reactionMessage(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        } else {
            let returnmsg = this.unsupportedMessage(message, isReplyMsg)
            if (returnmsg !== -1) {
                return <div key={message.id}>{timemsg === undefined ? null : timemsg}{returnmsg}</div>
            }
        }

    }

    //Get msg for Replyed msg
    getMsgById = async (id) => {

        const access_token = data.session.access_token
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                "ngrok-skip-browser-warning": "69420",
                'Authorization': access_token,
            },

        };
        let apiUrl = getMsgUrl + '?wa_id=' + this.state.user + '&id=' + id.split(".")[1];

        try {
            //this.setState({ updating: true })
            const response = await axios.get(apiUrl, config);
            this.replyRequestedList[id] = 'ok'
            //if(this.state.reply[id])
            let oldreply = this.state.reply[id];
            if (JSON.stringify(oldreply) !== JSON.stringify(response.data)) {
                let newreply = { ...this.state.reply };
                newreply[id] = response.data;
                this.setState({ reply: newreply, });
                //this.setState({needupdate:true})
            }
            //this.setState({ updating: false })
            return response.data.body;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    //Check msg with search param 
    checkMsg = (message, dates, msgType, searchType, searchText, isReplyMsg) => {
        const checkDate = () => {

            //return searchTime1 < parseInt(msgTime) && searchTime2 > parseInt(msgTime)
            return true
        }
        const checkText = () => {

            if (isReplyMsg) {
                return true
            }
            if (searchText === "") {
                return true
            } else if (message.body !== null && message.body.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                return true
            } else {
                return false
            }

        }
        let msgTime = message.timestamp
        let searchTime1 = null
        let searchTime2 = null
        if (dates && dates[0] !== null && dates[0] !== undefined && dates[0] !== "") {
            searchTime1 = formatTimestamp(dates[0])
            searchTime2 = formatTimestamp(dates[1])
        }

        if (isReplyMsg && searchType == 'Message') {
            return true
        }
        if (checkDate() || searchTime1 == null) {
            if (searchType === 'All' && checkText()) {
                return true
            }
            else if (searchType === 'Image' && msgType === 'Image') {
                checkText()
                return true
            }
            else if (searchType === 'Document' && msgType === 'Document') {
                return true
            }
            else if (searchType === 'Audio' && msgType === 'Audio') {
                return true
            }
            else if (searchType === 'Contact' && msgType === 'Contact') {
                checkText()
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }

    //Display status of msges
    displayStatus = (status) => {
        switch (status) {
            case "delivered":
                return (
                    <span style={{ display: 'flex', alignItems: 'center' }} title='Entregado'>

                        {/* {t("Llegado")} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 12 12" className='text-gray-300'>
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 6l2 2 4-4m0 4l4-4" />
                        </svg>
                    </span>
                )

            case "read":
                return (
                    <span style={{ display: 'flex', alignItems: 'center' }} title='Leído'>

                        {/* {t("Leido")} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 12 12" className='text-white'>
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 6l2 2 4-4m0 4l4-4" />
                        </svg>
                    </span>
                )

            case "sent":
                return (
                    <span style={{ display: 'flex', alignItems: 'center' }} title='Enviado'>
                        {/* {t("Enviado")} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 12 12" className='text-gray-300'>
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l2 2 4-4" />
                        </svg>
                    </span>
                )

            case "failed":
                return (
                    <span style={{ display: 'flex', alignItems: 'center' }} title='Error'>
                        {/* {t("Fallado")} */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 12 12">
                            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M3.5 8.5l5-5m0 5l-5-5" />
                        </svg>
                    </span>
                )
            case "system":
                return (
                    <span style={{ display: 'flex', alignItems: 'center' }} title='Sistema'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" focusable="false" viewBox="0 0 12 12">
                            <path fill="currentColor" d="M10.5 6c0-.31-.03-.62-.09-.92l1.06-.78c.14-.1.19-.28.13-.44-.23-.6-.55-1.15-.95-1.64a.371.371 0 00-.44-.1L9 2.65c-.46-.41-1-.73-1.59-.92L7.26.41a.353.353 0 00-.3-.33C6.64.03 6.33 0 6 0c-.32 0-.64.03-.95.07-.16.03-.28.17-.3.34L4.6 1.72c-.6.2-1.14.52-1.59.93L1.8 2.11a.368.368 0 00-.44.1C.95 2.7.63 3.26.4 3.85c-.06.16 0 .34.13.44l1.07.78c-.07.3-.1.61-.1.93 0 .31.03.62.09.92L.53 7.7c-.14.1-.19.28-.13.44.23.6.55 1.15.95 1.64.11.13.29.17.44.1L3 9.35c.46.41 1 .73 1.59.92l.14 1.32c.02.17.14.3.31.33.32.05.63.08.96.08.32 0 .64-.03.95-.07.16-.03.29-.16.31-.33l.15-1.32c.6-.2 1.14-.51 1.59-.92l1.21.53c.15.07.34.03.44-.1.4-.49.72-1.04.95-1.64a.39.39 0 00-.13-.44l-1.07-.78c.07-.3.1-.61.1-.93zM6 7.5c-.83 0-1.5-.67-1.5-1.5S5.17 4.5 6 4.5s1.5.67 1.5 1.5S6.83 7.5 6 7.5z" />
                        </svg>
                    </span>
                )
            default:
                break;
        }
    };

    //Display textMsg
    textMsg = (message, isReplyMsg) => {
        //let id = message.from
        let isLeft = message.send_to_id !== ""
        let positionDiv = isLeft ? "left" : "right"
        //let reply
        if (message.replyTo !== undefined && message.replyTo !== null) {
            if (this.state.reply[message.replyTo] === undefined && this.replyRequestedList[message.replyTo] == undefined) {
                this.replyRequestedList[message.replyTo] = 'requested'
                this.getMsgById(message.replyTo)

            }

        }
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }

        if (this.checkMsg(message, this.state.searchDate, 'Message', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            const processMessageBody = (body) => {
                return body.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ));
            };
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2 " : "flex items-start justify-end gap-2.5 my-2 "}>
                            <div id={message.id} className={`${color} flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-400 rounded-[20px] duration-500 dark:${darkColor} `} >
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span title={formatTime(message.timestamp)} id={message.id} className={`text-sm font-normal dark:text-white ${text}`}>{formatClock(message.timestamp)}</span>
                                </div>

                                <div id={message.id} className={`text-sm font-normal py-2.5 ${text} dark:text-white word-break : break-word`} style={{ wordBreak: 'break-word' }}>
                                    {processMessageBody(message.body)}

                                    {message.replyTo !== undefined && message.replyTo !== null
                                        ? this.state.reply[message.replyTo] !== undefined
                                            ? isReplyMsg
                                                ? null
                                                : <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-primary-400 ${color} rounded-[20px] dark:${darkColor}`}>
                                                    {<p id={message.id} >{this.t('Responder a: ')}</p>}
                                                    {this.processMsg(this.state.reply[message.replyTo], true)}
                                                </div>
                                            : ''

                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>

                    </motion.div>
                </AnimatePresence>



            )
        } else {
            return -1
        }
    }

    //Display reaction message
    reactionMessage = (message, isReplyMsg) => {
        //let id = message.from
        let isLeft = message.send_to_id !== ""
        let positionDiv = isLeft ? "left" : "right"
        //let reply
        if (message.reactionTo !== undefined && message.reactionTo !== null) {
            if (this.state.reply[message.reactionTo] === undefined && this.replyRequestedList[message.reactionTo] == undefined) {
                this.replyRequestedList[message.reactionTo] = 'requested'
                this.getMsgById(message.reactionTo)

            }

        }
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }

        if (this.checkMsg(message, this.state.searchDate, 'Message', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2 " : "flex items-start justify-end gap-2.5 my-2 "}>
                            <div id={message.id} className={`${color} flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-400 rounded-[20px] duration-500 dark:${darkColor} `} >
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span title={formatTime(message.timestamp)} id={message.id} className={`text-sm font-normal dark:text-white ${text}`}>{formatClock(message.timestamp)}</span>
                                </div>

                                <div id={message.id} className={`text-sm font-normal py-2.5 ${text} dark:text-white word-break : break-word`} style={{ wordBreak: 'break-word' }}>
                                    {message.emoji}

                                    {message.reactionTo !== undefined && message.reactionTo !== null
                                        ? this.state.reply[message.reactionTo] !== undefined
                                            ? isReplyMsg
                                                ? null
                                                : <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-primary-400 ${color} rounded-[20px] dark:${darkColor}`}>
                                                    {<p id={message.id} >{this.t('Responder a: ')}</p>}
                                                    {this.processMsg(this.state.reply[message.reactionTo], true)}
                                                </div>
                                            : ''

                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>

                    </motion.div>
                </AnimatePresence>



            )
        } else {
            return -1
        }


    }

    //Display un support message
    unsupportedMessage = (message, isReplyMsg) => {
        //let id = message.from
        let isLeft = message.send_to_id !== ""
        let positionDiv = isLeft ? "left" : "right"
        //let reply
        if (message.replyTo !== undefined && message.replyTo !== null) {
            if (this.state.reply[message.replyTo] === undefined && this.replyRequestedList[message.replyTo] == undefined) {
                this.replyRequestedList[message.replyTo] = 'requested'
                this.getMsgById(message.replyTo)

            }

        }
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }

        if (this.checkMsg(message, this.state.searchDate, 'Message', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2 " : "flex items-start justify-end gap-2.5 my-2 "}>
                            <div id={message.id} className={`${color} flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-400 rounded-[20px] duration-500 dark:${darkColor} `} >
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span title={formatTime(message.timestamp)} id={message.id} className={`text-sm font-normal dark:text-white ${text}`}>{formatClock(message.timestamp)}</span>
                                </div>

                                <div id={message.id} className={`text-sm font-normal py-2.5 ${text} dark:text-white word-break : break-word`} style={{ wordBreak: 'break-word' }}>
                                    Unsupported message - Type: {message.type}

                                    {message.replyTo !== undefined && message.replyTo !== null
                                        ? this.state.reply[message.replyTo] !== undefined
                                            ? isReplyMsg
                                                ? null
                                                : <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-primary-400 ${color} rounded-[20px] dark:${darkColor}`}>
                                                    {<p id={message.id} >{this.t('Responder a: ')}</p>}
                                                    {this.processMsg(this.state.reply[message.replyTo], true)}
                                                </div>
                                            : ''

                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>

                    </motion.div>
                </AnimatePresence>



            )
        } else {
            return -1
        }
    }

    //Display img msg
    imgMsg = (message, isReplyMsg) => {
        const downloadImage = (url) => {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        }
        let isLeft = message.send_to_id !== ""
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }

        const openImageInNewTab = () => {
            const newWindow = window.open('/', '_blank');
            newWindow.document.write(`<img src="${message.urls3}" alt="image" />`);
        };
        if (this.checkMsg(message, this.state.searchDate, 'Image', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2" : "flex items-start justify-end gap-2.5 my-2"}>
                            <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-gray-200 ${color} rounded-[20px] dark:${darkColor}`}>
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span id={message.id} title={formatTime(message.timestamp)} className={`text-sm font-normal ${text} dark:text-white`}>{formatClock(message.timestamp)}</span>
                                </div>
                                <div id={message.id} className={`text-sm font-normal py-2.5 ${text} dark:text-white`}>
                                    {message.body}
                                    <div id={message.id} className="flex items-start gap-2.5">
                                        <div id={message.id} className="group relative my-2.5">
                                            <div id={message.id} className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                                                <button id={message.id} data-tooltip-target="download-image" className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50">
                                                    <svg id={message.id} onClick={() => downloadImage(message.urls3)} className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                                    </svg>
                                                </button>
                                                <button id={message.id} onClick={openImageInNewTab} data-tooltip-target="open-image" className="ml-3 inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50">
                                                    <svg id={message.id} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor" className="w-5 h-5 text-white">
                                                        <path fillRule="evenodd" d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <figure className="max-w-lg">
                                                <div id={`placeholder-${message.id}`} style={{ height: '400px',width:'400px', backgroundColor: '#f0f0f0' }} className="flex items-center justify-center">
                                                    <span>Loading...</span>
                                                </div>
                                                <img id={message.id} src={message.urls3} className="rounded-lg" style={isReplyMsg ? { maxWidth: '100%', maxHeight: '100%' } : { maxWidth: '400px', maxHeight: '400px' }} onLoad={() => document.getElementById(`placeholder-${message.id}`).style.display = 'none'} />
                                            </figure>
                                        </div>
                                    </div>
                                    {message.replyTo !== undefined && message.replyTo !== null ? this.state.reply[message.replyTo] === undefined ? "" :
                                        <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-gray-200 ${color} rounded-[20px] dark:${darkColor}`}>
                                            {<p id={message.id}>Reply to: </p>}
                                            {this.processMsg(this.state.reply[message.replyTo], true)}
                                        </div>
                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>


            )
        } else {
            return -1
        }


    }

    //Display audio msg
    audioMsg = (message, isReplyMsg) => {
        let isLeft = message.send_to_id !== ""
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }
        if (this.checkMsg(message, this.state.searchDate, 'Audio', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2" : "flex items-start justify-end gap-2.5 my-2"} key={message.id}>
                            <div id={message.id} className={`flex flex-col gap-2.5 w-full max-w-[320px] leading-1.5 p-4 border-gray-200 ${color} rounded-[20px]  dark:${darkColor}`}>
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span id={message.id} title={formatTime(message.timestamp)} className={`text-sm font-normal ${text} dark:text-white`}>{formatClock(message.timestamp)}</span>
                                </div>
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">

                                    <audio id={message.id} controls key={message.id} className={`color`}>
                                        <source src={message.urls3} type={message.mime_type} />
                                    </audio>

                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>


            )
        } else {
            return -1
        }

    }

    //Display document msg
    documentMsg = (message, isReplyMsg) => {
        const downloadFile = (url) => {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        }
        let isLeft = message.send_to_id !== ""
        let color, text, colorFile, darkColor

        if (isLeft) {
            color = 'bg-gray-200'
            colorFile = 'bg-gray-400 '
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            colorFile = 'bg-primary-400 '
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }

        if (this.checkMsg(message, this.state.searchDate, 'Document', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2" : "flex items-start justify-end gap-2.5 my-2"} key={message.id}>

                            <div id={message.id} className="flex flex-col gap-1">
                                <div id={message.id} className={`flex flex-col w-full max-w-[326px] leading-1.5 p-4 border-gray-200 ${color} rounded-[20px] dark:${darkColor}`}>
                                    <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                        <span id={message.id} className={`text-sm font-semibold ${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                        <span id={message.id} title={formatTime(message.timestamp)} className={`text-sm font-normal ${text} dark:text-white`}>  {formatClock(message.timestamp)}</span>
                                    </div>
                                    <div id={message.id} className={`flex items-start my-2.5 ${colorFile} rounded-[20px] p-2`}>
                                        <div id={message.id} className="me-2">
                                            <span id={message.id} className={`flex items-center gap-2 text-sm font-medium ${text} dark:text-white pb-2`}>
                                                {message.filename}
                                            </span>

                                        </div>
                                        <div id={message.id} className="inline-flex self-center items-center">
                                            <button id={message.id} onClick={() => downloadFile(message.urls3)} className={`inline-flex self-center items-center p-2 text-sm font-medium text-center text-primary-600 ${colorFile} rounded-lg hover:bg-primary-600 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-600`} type="button">
                                                <svg id={message.id} className="w-4 h-4 text-primary-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="primary-600" viewBox="0 0 20 20">
                                                    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                                                    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center">
                                        {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>

            )
        } else {
            return -1
        }

    }

    //Display contact msg
    contactMsg = (message, isReplyMsg) => {
        let isLeft = message.send_to_id !== ""
        let positionDiv = isLeft ? 'left' : 'right'
        let color, text, darkColor
        if (isLeft) {
            color = 'bg-gray-200'
            text = 'text-black'
            darkColor = 'bg-gray-600'
        } else {
            color = 'bg-primary-600'
            text = 'text-white'
            darkColor = 'bg-primary-600'
        }
        if (this.checkMsg(message, this.state.searchDate, 'Contacts', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2" : "flex items-start justify-end gap-2.5 my-2"}>
                            <div id={message.id} className={`flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-200 ${color} rounded-[20px] dark:${darkColor}`}>
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className={`text-sm font-semibold${text} dark:text-white`}>{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span id={message.id} title={formatTime(message.timestamp)} className={`text-sm font-normal ${text} dark:text-white`}>{formatClock(message.timestamp)}</span>
                                </div>
                                <div id={message.id} className={`text-sm font-normal py-2.5 ${text} dark:text-white word-break : break-word`} style={{ wordBreak: 'break-word' }}>
                                    <div id={message.id} className="flex items-center p-4 mb-4 text-sm text-primary-800 border border-blue-300 rounded-lg bg-white dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800" role="alert">
                                        <svg id={message.id} className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span id={message.id} className="sr-only">Info</span>
                                        <div id={message.id}>
                                            {message.contact.map((element, index) => {
                                                let ans = <div key={index} ><span id={message.id} className="font-medium"> {this.t('Contactar con:')}</span> {element.phones[0].phone}<br /></div>
                                                index++
                                                return ans
                                            })}
                                            {/* <span id={message.id} className="font-medium"> {this.t('Contactar con:')}</span> {message.contact} */}
                                        </div>
                                    </div>

                                    {message.replyTo !== undefined && message.replyTo !== null ? this.state.reply[message.replyTo] === undefined ? "" :
                                        <div id={message.id} className={`flex flex-col leading-1.5 p-4 border-gray-200 ${color} rounded-[20px] dark:${darkColor}`}>
                                            {<p id={message.id}>{this.t('Responder a: ')}</p>}
                                            {this.processMsg(this.state.reply[message.replyTo], true)}
                                        </div>
                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>

            )
        } else {
            return -1
        }
    }

    //Display template msg
    templateMsg = (message, isReplyMsg) => {
        let isLeft = message.send_to_id !== ""
        const templateName = message.templateName
        let templateData
        let position = isLeft ? { textAlign: 'left' } : { textAlign: 'right' }
        let positionDiv = isLeft ? 'left' : 'right'
        this.state.templateData.forEach(element => {
            //console.log(element.name, message.templateName)
            if (element.name === message.templateName) {
                templateData = element
            }
        });
        let index = 0
        if (this.checkMsg(message, this.state.searchDate, 'Contacts', this.state.searchItem, this.state.searchText, isReplyMsg)) {
            return (
                <AnimatePresence>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div id={message.id} className={isLeft ? "flex items-start gap-2.5 my-2" : "flex items-start justify-end gap-2.5 my-2"}>
                            <div id={message.id} className="flex flex-col max-w-[500px] leading-1.5 p-4 border-gray-200 bg-primary-600 rounded-[20px] dark:bg-primary-600">
                                <div id={message.id} className="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span id={message.id} className="text-sm font-semibold text-white dark:text-white">{isLeft ? this.t('Cliente') : message.agent !== '' && message.agent !== undefined ? message.agent : 'You'}</span>
                                    <span id={message.id} title={formatTime(message.timestamp)} className="text-sm font-normal text-white">{formatClock(message.timestamp)}</span>
                                </div>
                                <div id={message.id} className="text-sm font-normal py-2.5 text-white dark:text-white word-break : break-word" style={{ wordBreak: 'break-word' }}>
                                    <p id={message.id} >{templateData?.body}</p>
                                    <br />
                                    <p id={message.id} >{templateData?.footer}</p>
                                    <br />
                                    {templateData?.buttons.map(element => {
                                        let ans =
                                            (<button id={message.id}
                                                key={index}
                                                type="button"
                                                style={isLeft ? { marginRight: 'auto', display: 'flex' } : { marginLeft: 'auto', display: 'flex' }}
                                                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[20px] text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                {element}
                                            </button>)
                                        index++
                                        return ans
                                    })}
                                    {message.replyTo !== undefined && message.replyTo !== null ? this.state.reply[message.replyTo] === undefined ? "" :
                                        <div id={message.id} className="flex flex-col leading-1.5 p-4 border-gray-200 bg-gray-200 rounded-[20px] dark:bg-primary-600">
                                            {<p id={message.id} >{this.t('Responder a: ')}</p>}
                                            {this.processMsg(this.state.reply[message.replyTo], true)}
                                        </div>
                                        : ""}
                                </div>
                                <div className="flex justify-end items-center">
                                    {isLeft ? null : <span className="text-sm  font-normal text-white dark:text-gray-400">{this.displayStatus(message.status)}</span>}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>

            )

        } else {
            return -1
        }
    }
    //#endregion

    render() {
        return (
            <>
                <div>
                    <MsgSearch conversation={this.props.conversation} />
                    <div id="messages" className="w-[60%] 2xl:w-[60%] xl:w-[55%] lg:w-[50%] md:w-[45%] sm:w-[40%] scrollbar scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full w-96 mt-20" ref={this.messagesRef} style={{
                        position: 'fixed',
                        right: '0%',  // Set to right
                        top: '50px',    // Adjust as needed for positioning
                        bottom: '50px',
                        //width: '60%',  // Set a fixed width using percentage
                        // height: '100%', // Set a fixed height using viewport height units
                        padding: '10px',
                        overflowY: 'scroll',
                        maxHeight: '100%',  // Limit the height and make it scrollable
                    }}>
                        {this.state.updating
                            ?
                            <div className="flex justify-center items-center" style={{ height: '30%' }} ><LoadingComponent /></div>
                            : null
                        }
                        {'msg' in this.state ? this.state.msg.messages.map(([id, message]) => (this.processMsg(message, false))) : null}
                        {/* Si no hay mensajes, en el centro del bloque se muestra un mensaje que dice que selecciones una conversacion y abajo una card con las ultimas novedades de la funcionalidad de WhatsApp */}
                        {this.props.conversation.name === undefined ? 
                        <div className="flex justify-center items-center h-full flex-col">
                            <p className='text-base font-normal text-gray-500 md:text-xl dark:text-gray-400'>{this.t('Seleccione una conversación')}</p>
                            <div className='fade-out-container'>
                            <div role="status" class="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow dark:divide-gray-700 md:p-6 dark:border-gray-700 w-96 mt-6">
                                    <div class="flex items-center justify-between">
                                        <div className='flex items-center'>
                                            <div>
                                                <svg class="w-10 h-10 me-3 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                            </div>
                                        </div>
                                        {/* Pequeño circulo */}
                                        <div class="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                                    </div>
                                    <div class="flex items-center justify-between pt-4">
                                        <div className='flex items-center'>
                                            <div>
                                                <svg class="w-10 h-10 me-3 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                            </div>
                                        </div>
                                        {/* Pequeño circulo */}
                                        <div class="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                                    </div>
                                    <div class="flex items-center justify-between pt-4">
                                        <div className='flex items-center'>
                                            <div>
                                                <svg class="w-10 h-10 me-3 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                            </div>
                                        </div>
                                        {/* Pequeño circulo */}
                                        <div class="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                                    </div>
                                    <div class="flex items-center justify-between pt-4">
                                        <div className='flex items-center'>
                                            <div>
                                                <svg class="w-10 h-10 me-3 text-gray-300 dark:text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                                <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                                            </div>
                                        </div>
                                        {/* Pequeño circulo */}
                                        <div class="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                                    </div>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            </div>
                        : null}
                    </div>
                </div>
                <div ref={this.contextMenuRef} id='contextMenu' className="block" style={{ display: 'none', position: 'absolute', top: '', left: '' }}>
                    <div className="bg-white w-60 border border-gray-300 rounded-lg flex flex-col text-sm py-2 px-2 text-gray-500 shadow-lg">
                        <div onClick={() => { this.checkReply() }} className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default" >
                            <div className="text-gray-500 ml-2">{this.t('Responder')}</div>
                        </div>
                    </div>
                </div>
                <GoBottom />




            </>
        )
    }
}
