import React, { useState, useEffect } from 'react';

function GoBottom() {
    const [isVisible, setIsVisible] = useState(false)
    const [haveNewMsg,setHaveNewMsg] =useState(false)

    useEffect(() => {
        PubSub.subscribe('GoBottomVisible', handleChange)
    }, []);

    const goToButtom = () => {

        PubSub.publish('GoToBottom', null)
    }
    const handleChange = (_, data) => {
        if(data.state==null && data.haveNewMsg==null){
            return
        }
        if(data.state==null && data.haveNewMsg!=null){
            setHaveNewMsg(data.haveNewMsg)
            return
        }
        if(data.state!=null && data.haveNewMsg==null){
            setIsVisible(data.state)
            return
        }
        
        setIsVisible(data.state)
        setHaveNewMsg(data.haveNewMsg)
    }
    return (
        <button type="button" onClick={goToButtom} className={`transition-transform duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-[calc(200%)]'} absolute fixed bottom-[5.5%] right-[2%] opacity-[0.7] items-center justify-center p-3 text-sm font-medium text-center text-white bg-primary-500 rounded-full hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-12 h-12 `}>
            <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 25 25">
                <path fillRule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v16.19l6.22-6.22a.75.75 0 1 1 1.06 1.06l-7.5 7.5a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 1 1 1.06-1.06l6.22 6.22V3a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
            </svg>
            <span className="sr-only">Notifications</span>
            {haveNewMsg &&
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">!</div>
            }
        </button>
    );
}

export default GoBottom;
