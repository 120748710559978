import { useEffect, useState, Fragment } from 'react';
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { ApiRequestCalendarAbsences } from '../../api/ApiRequestCalendarAbsences';
import { Datepicker } from 'flowbite-react';
import { supabaseClient } from '../../config/supabase-clients';

const supabase = supabaseClient;

dayjs.locale('es');

export const AbsenceCalendar = () => {
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [calendarData, setCalendarData] = useState(null);
    const [events, setEvents] = useState([]);
    const MAX_VISIBLE_EVENTS = 5;

    const startOfMonth = currentDate.startOf('month');
    const endOfMonth = currentDate.endOf('month');
    const daysInMonth = currentDate.daysInMonth();

    useEffect(() => {
        const fetchCalendarData = async () => {
            const data = await ApiRequestCalendarAbsences();
            setCalendarData(data);
        };

        fetchCalendarData();
    }, []);


    useEffect(() => {
        if (calendarData) {
            let newEvents = [];
            calendarData.forEach(user => {
                user.absences.forEach(absence => {
                    const startDate = dayjs(absence.start_date);
                    const endDate = dayjs(absence.end_date);
                    const reason = absence.reason;
                    const file = absence.file;
                    for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date = date.add(1, 'day')) {
                        const formattedDate = date.format('YYYY-MM-DD');
                        newEvents.push({
                            date: formattedDate,
                            description: user.first_name,
                            avatar: user.profile_pic,
                            reason: reason,
                            file: file
                        });
                    }
                });
            });

            //Agregar los fesitvos desde supabase
            const fetchHolidays = async () => {
                const { data, error } = await supabase
                    .from('holidays')
                    .select('*');

                if (error) {
                    return;
                }

                if (data) {
                    const formattedData = data.map(record => ({
                        fecha: new Date(record.holiday_date),
                        nombre: record.name,

                    }));

                    formattedData.forEach(holiday => {
                        const formattedDate = dayjs(holiday.fecha).format('YYYY-MM-DD');
                        newEvents = newEvents.filter(event => event.date !== formattedDate);

                        newEvents.push({
                            date: formattedDate,
                            description: "🎉 " + holiday.nombre
                        });

                    });

                    setEvents(newEvents);
                }
            };
            fetchHolidays();
        }
    }, [calendarData]);

    const handleViewMore = (date) => {
        setSelectedDate(date);
    };

    const handleCloseModal = () => {
        //Esperar 5 milisegundos para que se cierre la ventana
        setSelectedDate(null);
    };

    const prevMonth = () => {
        setCurrentDate(currentDate.subtract(1, 'month'));
    };

    const nextMonth = () => {
        setCurrentDate(currentDate.add(1, 'month'));
    };

    const datePickerCalculate = (date) => {
        setCurrentDate(dayjs(date, 'MM/YYYY'));
    }

    const goToToday = () => {
        setCurrentDate(dayjs());
    };

    const handleEventClickInfo = (e) => {
        setSelectedEvent(e);
    };

    const handleCloseModalInfo = () => {
        setSelectedEvent(null);
    };

    const days = [];

    // Get the days of the previous month to fill the first row
    const startDayOfWeek = startOfMonth.day(); // 0 (Sunday) to 6 (Saturday)
    for (let i = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; i > 0; i--) {
        days.push({
            date: startOfMonth.subtract(i, 'day'),
            currentMonth: false
        });
    }

    // Get the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
        days.push({
            date: dayjs(`${currentDate.year()}-${currentDate.month() + 1}-${i}`),
            currentMonth: true
        });
    }

    // Get the days of the next month to fill the remaining cells
    const totalDays = days.length;
    const remainingCells = 42 - totalDays; // 42 cells for 6 weeks
    for (let i = 1; i <= remainingCells; i++) {
        days.push({
            date: endOfMonth.add(i, 'day'),
            currentMonth: false
        });
    }

    return (
        <div className="flex flex-col h-full dark:bg-gray-900">
            <header className="flex items-center justify-between border-gray-200 px-4 py-2 sm:px-6 sm:py-4 lg:flex-none dark:border-gray-700 rounded-t-lg bg-white dark:bg-gray-900">
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                    <time dateTime={currentDate.format('YYYY-MM')}>{currentDate.format('MMMM YYYY').charAt(0).toUpperCase() + currentDate.format('MMMM YYYY').slice(1)}</time>
                </h1>
                <div className="flex items-center space-x-2 sm:space-x-4">
                    <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-gray-800 dark:shadow-md">
                        <Datepicker value={currentDate.format('MM/YYYY')} onSelectedDateChanged={datePickerCalculate} showClearButton={false} labelTodayButton='Hoy' language="es-ES" weekStart={1} />
                    </div>
                    <div className="flex items-center space-x-1 sm:space-x-2">
                        <button
                            type="button"
                            onClick={prevMonth}
                            className="flex h-8 w-8 sm:h-9 sm:w-9 items-center justify-center rounded-full border border-gray-300 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                        >
                            <svg className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button
                            type="button"
                            onClick={goToToday}
                            className="hidden sm:block border border-gray-300 px-2.5 sm:px-3.5 text-xs sm:text-sm font-semibold text-gray-900 rounded-md hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700 py-1.5 sm:py-2"
                        >
                            Hoy
                        </button>
                        <button
                            type="button"
                            onClick={nextMonth}
                            className="flex h-8 w-8 sm:h-9 sm:w-9 items-center justify-center rounded-full border border-gray-300 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                        >
                            <svg className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
            <div className="shadow ring-1 ring-black ring-opacity-5 flex flex-auto flex-col dark:ring-gray-700 rounded-b-lg rounded-xl overflow-hidden">
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 flex-none dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300">
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Lun</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Mar</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Mié</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Jue</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Vie</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Sáb</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Dom</div>
                </div>
                <div className="flex bg-gray-200 text-sm leading-6 text-gray-700 flex-auto dark:bg-gray-900 dark:text-gray-300">
                    <div className="w-full grid grid-cols-7 grid-rows-6 gap-px">
                        {days.map(({ date, currentMonth }) => {
                            const dayEvents = events.filter(event => event.date === date.format('YYYY-MM-DD'));
                            return (
                                <div key={date.format('YYYY-MM-DD')} className={`relative h-24 sm:h-32 overflow-y-auto scrollbar scrollbar-thumb-primary-200 scrollbar-w-1 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full ${date.isSame(dayjs(), 'day') ? 'bg-primary-500 text-white' : currentMonth ? 'bg-white' : 'bg-gray-100 text-gray-400'} relative px-2 sm:px-3 py-2 sm:py-4 dark:${date.isSame(dayjs(), 'day') ? 'bg-primary-400 dark:bg-primary-900 text-white' : currentMonth ? 'bg-gray-800' : 'bg-gray-700 text-gray-500'}`}>
                                    <time dateTime={date.format('YYYY-MM-DD')} className="absolute top-1 left-1">{date.format('D')}</time>
                                    {/* Renderiza los eventos para el día */}
                                    {dayEvents.slice(0, MAX_VISIBLE_EVENTS).map((event, index) => (
                                        <div key={index} className="mt-1 ml-1 text-xs dark:text-gray-200 hover:text-primary-400 hover:bg-primary-50 dark:hover:bg-gray-600 rounded-xl cursor-pointer" onClick={() => handleEventClickInfo(event)}>
                                            <div className="flex items-center">
                                                {event.avatar ? (
                                                    <img src={event.avatar} alt="avatar" className="w-4 h-4 sm:w-6 sm:h-6 rounded-full mr-1" />
                                                ) : null}
                                                <div>
                                                    {event.description ? event.description : 'No disponible'}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {dayEvents.length > MAX_VISIBLE_EVENTS && (
                                        <button onClick={() => handleViewMore(date.format('YYYY-MM-DD'))} className="mt-1 text-xs text-blue-500 dark:text-blue-400">
                                            Ver más
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <Dialog open={selectedEvent !== null} onClose={handleCloseModalInfo} className="relative z-50">
                <div className="fixed inset-0 bg-black bg-opacity-50" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="bg-white dark:bg-gray-800 rounded-lg shadow-xl px-4 pt-4 pb-2 sm:max-w-sm sm:w-full">
                        <Dialog.Title className="text-lg font-semibold dark:text-gray-100">Información de la ausencia</Dialog.Title>

                        <div className="my-2 text-xs dark:text-gray-200">
                            {selectedEvent ? (
                                <>
                                    <div>
                                        <span className="font-semibold">Nombre:</span> {selectedEvent.description || 'No disponible'}
                                    </div>
                                    <div>
                                        <span className="font-semibold">Motivo:</span> {selectedEvent.reason || 'No disponible'}
                                    </div>
                                    {selectedEvent.file && (
                                        <div>
                                            <span className="font-semibold">Archivo:</span>{' '}
                                            <a href={selectedEvent.file} target="_blank" rel="noreferrer" className="text-blue-500 dark:text-blue-400">Descargar</a>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p>No hay información disponible.</p>
                            )}
                        </div>

                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleCloseModalInfo}
                                className="bg-primary-600 text-white font-semibold rounded-md px-4 py-2 hover:bg-primary-500 dark:bg-primary-500 dark:hover:bg-primary-400"
                            >
                                Cerrar
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>


            {selectedDate && (

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded-lg dark:bg-gray-800">
                        <h2 className="text-lg font-semibold dark:text-gray-100">Trabajadores de baja {dayjs(selectedDate).format('DD/MM/YYYY')}</h2>
                        <ul>
                            {events.filter(event => event.date === selectedDate).map((event, index) => (
                                <li key={index} className="mt-1 text-xs dark:text-gray-200">
                                    <div className="flex items-center ">
                                        {event.avatar ? (
                                            <img src={event.avatar} alt="avatar" className="w-4 h-4 sm:w-6 sm:h-6 rounded-full mr-1" />
                                        ) : null}
                                        <div className='hover:text-primary-400 hover:cursor-pointer' onClick={() => handleEventClickInfo(event)}>
                                            {event.description}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button onClick={handleCloseModal} className="mt-4 text-blue-500 dark:text-blue-400">Cerrar</button>
                    </div>
                </motion.div>
            )}
        </div>
    );
};