export const enDocumentManager = {
    translation: {
        "Gastos hipotecarios": "Mortgage expenses",
        "Prima única": "Single premium",
        "Reclamación gastos hipotecarios": "Mortgage expenses claim",
        "Declaración APUD": "APUD declaration",
        "Contrato gastos hipotecarios": "Mortgage expenses contract",
        "Prima única (Por defecto)": "Single premium (Default)",
        "(Prima única (CaixaBank)": "Single premium (CaixaBank)",
        "Prima única (Santander)": "Single premium (Santander)",
        "Contrato prima úncia": "Single premium contract",
        "Enviar": "Send",

    }   
}