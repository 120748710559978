import axios from 'axios';
import supabaseClient from '../utils/supabaseInit';
const {data} = await supabaseClient.auth.getSession()
// import {formatDate} from '../../utils/convertTimestamp'
const formatDate = (timestamp) => {

    let date = new Date(timestamp * 1000)
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return day + '-' + month + '-' + year
}
const getData = async (index, type, state) => {
    const access_token = data.session.access_token
    //设置请求头
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            'Authorization': access_token,
        }
    };

    //如果state正常 发送请求
    if (state.user !== undefined) {
        
        let apiUrl = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/conversations?wa_id=` + state.user + '&date=' + state.date[index];
        let data
        
        //等待请求结果
        try {
            const response = await axios.get(apiUrl, config);
            console.log(apiUrl)
            //取出response信息
            data = response.data
            let oldData
            let newData

            //设置新旧对比的数组
            if (state.msg && state.msg.messages !== undefined) {
                oldData = state.msg.messages
            } else {
                oldData = [[,]]
            }
            if (data[0] && data[0].messages !== undefined) {
                newData = Object.entries(data[0].messages)
            } else {
                newData = [[,]]
            }


            //切换getData的形式 接受新消息/加载老消息

            //加载老消息
            if (type === 'last') {

                //检查state的数据 数据为空
                if (!('msg' in state)) {
                    data[0].messages = Object.entries(data[0].messages)
                    data[0].messages.sort((a, b) => {
                        return a[1].timestamp - b[1].timestamp;
                    });
                    return { msg: data[0], shouldStick: false, oldmsg: 0 }
                }
                //如果不为空则更新数据
                else {
                    function uniqueElementsFromTwoArrays(array1, array2) {
                        const uniqueElements = new Map(); // 用于存储所有不同的元素
                    
                        // 遍历第一个二维数组，并将其中的元素添加到 Map 中
                        array1.forEach(([int, obj]) => {
                            uniqueElements.set(int, obj);
                        });
                    
                        // 遍历第二个二维数组，并将其中的元素添加到 Map 中
                        array2.forEach(([int, obj]) => {
                            uniqueElements.set(int, obj);
                        });
                    
                        // 创建一个新的二维数组，其中包含所有不同的元素
                        const resultArray = Array.from(uniqueElements, ([int, obj]) => [int, obj]);
                    
                        // 根据 timestamp 进行排序
                        resultArray.sort((a, b) => {
                            return parseInt(a[1].timestamp, 10) - parseInt(b[1].timestamp, 10);
                        });
                    
                        return resultArray;
                    }
                    const uniqueArray = uniqueElementsFromTwoArrays(oldData, newData);
                    let oldmsgobj = state.msg
                    let length = state.msg.messages.length
                    oldmsgobj.messages = uniqueArray
                    oldmsgobj.messages.sort((a, b) => {
                        return a[1].timestamp - b[1].timestamp;
                    });
                    return { msg: oldmsgobj, shouldStick: true, oldmsg: length }

                }
                //接受新消息
            } else if (type === 'new') {

                //整理返回的当日消息
                newData.sort((a, b) => {
                    return a[1].timestamp - b[1].timestamp;
                });
                //let Check = true

                //根据最新时间 检查重复

                if (newData[newData.length - 1][1]?.timestamp > oldData[oldData.length - 1][1]?.timestamp || newData[newData.length - 1][1]?.status !== oldData[oldData.length - 1][1]?.status) {
                    //不重复则插入

                    //如果state没有被初始化
                    if (!('msg' in state)) {
                        data[0].messages = Object.entries(data[0].messages)
                        data[0].messages.sort((a, b) => {
                            return a[1].timestamp - b[1].timestamp;
                        });
                        return { msg: data[0], shouldStick: false, oldmsg: 0 }
                    }

                    //如果state被初始化
                    else {
                        if ('msg' in state) {
                            let oldmsgobj = state.msg
                            let olgmsg = state.msg.messages
                            let length = olgmsg.length
                            let newmsg = Object.entries(data[0]?.messages);

                            const combinedArray = [
                                ...new Set([...olgmsg.map(msg => msg[0]), ...newmsg.map(msg => msg[0])])
                            ].map(id => {
                                const foundOldMsg = olgmsg.find(msg => msg[0] === id);
                                const foundNewMsg = newmsg.find(msg => msg[0] === id);

                                // 检查旧消息数组中是否包含当前 id，如果包含则返回旧消息对象，否则返回新消息对象
                                if (foundOldMsg) {
                                    // 如果在 olgmsg 中找到相同的 id 但是不同的 status，添加新消息
                                    if (foundNewMsg && foundNewMsg[1] !== foundOldMsg[1]) {
                                        return [foundNewMsg[0], foundNewMsg[1]]; // 返回新消息
                                    } else {
                                        return foundOldMsg; // 返回旧消息
                                    }
                                } else {
                                    return foundNewMsg; // 返回新消息
                                }
                            });


                            //尾端插入新消息
                            oldmsgobj.messages = combinedArray
                            oldmsgobj.messages.sort((a, b) => {
                                return a[1].timestamp - b[1].timestamp;
                            });

                            return { msg: oldmsgobj, shouldStick: false, oldmsg: length }

                        }
                    }
                }
            }
        } catch (error) {
            // Handle errors
            console.error('Error fetching data:', error);
        }
    }
}

export default getData

