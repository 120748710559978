export const enLogin = {

    translation: {
        "Inicia sesión en tu cuenta de Coventia Office": "Log in to your Coventia Office account",
       "Ha ocurrido un error inesperado": "An unexpected error has occurred",
       "Iniciar sesión en tu cuenta de Coventia Office": "Log in to your Coventia Office account",
       "Contraseña": "Password",
       "Recuérdame": "Remember me",
       "¿Has olvidado la contraseña?": "Have you forgotten your password?",
       "Iniciar sesión": "Log in",
       "Iniciando sesión...": "Logging in...",
    }

}