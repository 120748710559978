// contexts/ClockContext.js
import { createContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { createClient } from '@supabase/supabase-js';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';

dayjs.extend(duration);

const supabaseURL = 'https://uoclqlfzojdjrhcqhgef.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0';
const supabase = createClient(supabaseURL, supabaseKey);

export const ClockContext = createContext();

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

export const ClockProvider = ({ children }) => {
    const { userPublicData } = useAuth();
    const [isWorking, setIsWorking] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState('00:00');

    const fetchTodayShifts = async (userId, todayStr) => {
        const { data: todayShifts, error } = await supabase
            .from('shifts')
            .select('*')
            .eq('user_id', userId)
            .eq('work_date', todayStr)
            .order('shift_start', { ascending: true });

        if (error) {
            console.error("Error fetching today's shifts:", error);
            return [];
        }

        return todayShifts;
    };

    const createShift = async (userId, employerName, todayStr, now) => {
        const { data, error } = await supabase
            .from('shifts')
            .insert({
                user_id: userId,
                employer_name: employerName,
                work_date: todayStr,
                shift_start: now,
                shift_end: null,
            });

        if (error) {
            console.error('Error iniciando el turno:', error);
            return false;
        }

        return true;
    };

    const updateShift = async (shiftId, now) => {
        const { data, error } = await supabase
            .from('shifts')
            .update({ shift_end: now })
            .eq('shift_id', shiftId);

        if (error) {
            console.error('Error finalizando el turno:', error);
            return false;
        }

        return true;
    };

    const handleClockInOut = async () => {
        if (!userPublicData || !userPublicData.user_id) return;

        const todayStr = dayjs().format(DATE_FORMAT);
        const todayShifts = await fetchTodayShifts(userPublicData.user_id, todayStr);
        const lastShift = todayShifts.length ? todayShifts[todayShifts.length - 1] : null;
        const now = dayjs().format(TIME_FORMAT);

        if (!lastShift || lastShift.shift_end) {
            //Comprobar que no hayan 4 turnos en el día, si no devolver error
            if (todayShifts.length >= 4) {
                toast.error('No se puede iniciar un nuevo turno, ya se hay 4 turnos hoy.');
                return;
            }
            if (await createShift(userPublicData.user_id, userPublicData.first_name, todayStr, now)) {
                setStartTime(now);
                setIsWorking(true);
            }
        } else {
            if (await updateShift(lastShift.shift_id, now)) {
                setIsWorking(false);
                setElapsedTime('00:00');
                localStorage.removeItem('isWorking');
                localStorage.removeItem('startTime');
            }
        }
    };

    const checkOngoingShift = async () => {
        if (!userPublicData || !userPublicData.user_id) return;

        const todayStr = dayjs().format(DATE_FORMAT);
        const todayShifts = await fetchTodayShifts(userPublicData.user_id, todayStr);
        const lastShift = todayShifts.length ? todayShifts[todayShifts.length - 1] : null;

        if (lastShift && !lastShift.shift_end) {
            setIsWorking(true);
            setStartTime(lastShift.shift_start);
        }
    };

    useEffect(() => {
        checkOngoingShift();
    }, [userPublicData]);

    useEffect(() => {
        if (isWorking && startTime) {
            const interval = setInterval(() => {
                const now = dayjs();
                const currentDate = dayjs().format(DATE_FORMAT);
                const fullStartTime = dayjs(`${currentDate} ${startTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);

                if (!fullStartTime.isValid()) {
                    console.error("Invalid fullStartTime:", fullStartTime);
                    return;
                }

                const diff = now.diff(fullStartTime, 'minute');
                const hours = Math.floor(diff / 60).toString().padStart(2, '0');
                const minutes = (diff % 60).toString().padStart(2, '0');
                const calculatedElapsedTime = `${hours}:${minutes}`;

                setElapsedTime(calculatedElapsedTime);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isWorking, startTime]);

    useEffect(() => {
        const storedStartTime = localStorage.getItem('startTime');
        const storedIsWorking = localStorage.getItem('isWorking');

        if (storedStartTime && storedIsWorking) {
            setStartTime(storedStartTime);
            setIsWorking(JSON.parse(storedIsWorking));
        }
    }, []);

    useEffect(() => {
        if (startTime) {
            localStorage.setItem('startTime', startTime);
        }
        localStorage.setItem('isWorking', JSON.stringify(isWorking));
    }, [startTime, isWorking]);

    return (
        <ClockContext.Provider
            value={{
                setIsWorking,
                isWorking,
                startTime,
                elapsedTime,
                handleClockInOut,
            }}
        >
            {children}
        </ClockContext.Provider>
    );
};

ClockProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
