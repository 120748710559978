
    const states = [
    "Iniciado",
    "Pendiente de firma",
    "Firmado",
    "Enviado a Fidelidade",
    "Póliza creada",
    "Error al enviar a Fidelidade",
    "Contactado",
    "Finalizadas gestiones",
    "Test/Competencia",
    "Nueva Póliza",
    "Otras cuestiones",
    "Calcular mi ahorro",
    "Formulario Arengu",
    "Pendiente de llamada",
    "Contactado CRM",
    "Calcular presupuesto",
    "Corredores",
    "Anulada",
    "Prima unica",
    "Pendiente Agentes",
    "Enviado a revisión",
    "Enviado a Caser",
    "IRPH",
    "Revolving",
    "Gastos hipotecarios"
    ]

    export default states;
