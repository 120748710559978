import { useState } from 'react'
import { Link } from "react-router-dom";
import { NavBreadcrumb } from '../components/NavBreadcrumb';
import 'react-tooltip/dist/react-tooltip.css'
import { useColorScheme } from '../context/ColorSchemeContext';
import { BancoGraph } from '../components/graphs/BancoGraph';
import { AseguradoraGraph } from '../components/graphs/AseguradoraGraph';
import { useTranslation } from 'react-i18next';

export const SimularAhorro = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { colorScheme } = useColorScheme();
  const darkMode = colorScheme === 'dark';

  const url = `https://forms.coventia.es/form/2b9cb43ceead41e5a4124f5d94e6b128?primaryColor=103593&parentUrl=${window.location.href}${darkMode ? '&dark=true' : ''}`;

  document.title = "Coventia Office | Simuladores"

  const activeClass = "inline-block p-4 text-primary-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-primary-500";
  const noActiveClass = "inline-block p-4 rounded-t-lg hover:text-gray-600 bg-gray-50 dark:bg-gray-800 dark:hover:text-gray-300";

  return (
    <>
      <NavBreadcrumb currentPage={[{text:t("Simular ahorro"),url:""}]} />

      <div className="max-w-full mx-4 py-6 sm:mx-auto sm:6 lg:px-8">
        <div className="overflow-x-auto scrollbar-hide">
          <ul className="flex text-sm sm:text-base font-medium text-center text-gray-500 border-gray-400 dark:border-gray-700 dark:text-gray-400 whitespace-nowrap">
            <li className="mr-2 mb-1" >
              <Link aria-current="page" className={page == 0 ? activeClass : noActiveClass} onClick={() => setPage(0)}>{t('Calcular ahorro - Banco')}</Link>
            </li>
            <li className="mr-2 mb-1">
              <Link aria-current="page" className={page == 1 ? activeClass : noActiveClass} onClick={() => setPage(1)}>{t('Calcular ahorro - Aseguradora')}</Link>
            </li>
            <li className="mr-2 mb-1">
              <Link aria-current="page" className={page == 2 ? activeClass : noActiveClass} onClick={() => setPage(2)}>{t('Calcular hipoteca')}</Link>
            </li>
          </ul>
        </div>
        <div className={"w-full bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl shadow dark:bg-gray-800 p-4 md:p-6 " + (page == 0 ? "" : "hidden")}>

          <BancoGraph />

        </div>
        <div className={"w-full bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl shadow dark:bg-gray-800 p-4 md:p-6 " + (page == 1 ? "" : "hidden")}>

          <AseguradoraGraph />

        </div>
        <div className={"w-full bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl shadow dark:bg-gray-800 p-4 md:p-6 " + (page == 2 ? "" : "hidden")}>
          {/* Crear iframe centrado con la url https://forms.coventia.es/form/2b9cb43ceead41e5a4124f5d94e6b128?primaryColor=103593&parentUrl= + la url actual */}
          <iframe key={colorScheme} src={url} width="100%" height="500px">{t('Cargando…')}</iframe>
        </div>
      </div>
    </>
  )
}