export const enManager = {
    translation : {
        "Cargando..." : "Loading...",
        "No tienes permisos para acceder a esta página" : "You don't have permission to access this page",
        "Gestor de empleados y usuarios" : "Employee and user manager",
        "Usuario" : "User",
        "Último acceso" : "Last access",
        "DB Acceso" : "DB Access",
        "Manager" : "Manager",
        "Acciones" : "Actions",
        "No hay resultados" : "No results",
        "Crear usuario" : "Create user",
        "Rellena los campos para crear un nuevo usuario" : "Fill in the fields to create a new user",   
        "Nombre de usuairo" : "Username",
        "Nombre completo" : "Full name",
        "Contraseña" : "Password",
        "Enviar credenciales al usuario por email" : "Send credentials to the user by email",
        "El sistema envía un correo electrónico al usuario con sus credenciales de acceso y un enlace a la plataforma" : "The system sends an email to the user with their access credentials and a link to the platform",
        "Acceso total a la base de datos" : "Full access to the database",
        "Permite al usuario acceder a todos los proyectos de la base de datos" : "Allows the user to access all projects in the database",
        "Creando usuario..." : "Creating user...",
        "Cancelar" : "Cancel",
        "Editar usuario" : "Edit user",
        "Editando usuario..." : "Editing user...",
        "Rellena los campos para editar el usuario" : "Fill in the fields to edit the user",
        "Nombre de usuario actual" : "Current username",
        "Nuevo nombre de usuario" : "New username",
        "Nuevo email" : "New email",
        "Nueva contraseña" : "New password",
        "Acceso total a la BBDD" : "Full access to the DB",
        "Eliminar usuario" : "Delete user",
        "Eliminando usuario..." : "Deleting user...",
        "Escribe" : "Write",
        "para confirmar la eliminación del usuario" : "to confirm the deletion of the user",
        "Nombre de usuario" : "Username",
        "Editar" : "Edit",
        "Eliminar" : "Delete",
        "Estadísticas generales" : "General statistics",
        "Proyectos totales (Últimos 5 meses)" : "Total projects (Last 5 months)",
        "Proyectos según usuario / empleado (Últimos 5 meses)" : "Projects by user / employee (Last 5 months)",
        "Pólizas según usuario / empleado (Últimos 5 meses)": "Policies by user / employee (Last 5 months)",
    }
}