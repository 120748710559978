import MaintenanceImage from "../assets/img/maintenance.svg";
import { useTranslation } from "react-i18next";
export const MaintenanceComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col justify-center items-center px-6 mx-auto h-screen xl:px-0 dark:bg-gray-900">
            <div className="block mb-5 md:max-w-md">
                <img src={MaintenanceImage} alt="Maintenance" />
            </div>
            <div className="text-center xl:max-w-4xl">
                <h1 className="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white">{t('En mantenimiento')}</h1>
                <p className="mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400">{t('Disculpe las molestias, estamos realizando algunas tareas de mantenimiento en este momento. Si lo necesitas siempre puedes')} <a href="mailto:golcina@coventia.es" className="text-primary-700 hover:underline dark:text-primary-500">{t('ponerte en contacto con nosotros')}</a>, {t('de lo contrario, ¡estaremos de vuelta en breve!')}</p>
            </div>
        </div>
    )
}