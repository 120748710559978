export const enForgotPassword = {
    translation: {
        "El email parece no existir en nuestra base de datos": "The email seems not to exist in our database",
        "Ha ocurrido un error inesperado": "An unexpected error has occurred",
        "¿Has olvidado tu contraseña?": "Have you forgotten your password?",
        "¡No te preocupes! ¡Simplemente ingresa tu correo electrónico y te enviaremos un enlace para iniciar sesión! 😄": "Don't worry! Just enter your email and we'll send you a link to log in! 😄",
        "Enviar": "Send",
        "Volver": "Go back",
        "Fiuuuu ¡Hecho!": "Phew! Done!",
        "Si tu email existe en nuestra base de datos, te enviaremos un correo con un enlace para iniciar sesión.": "If your email exists in our database, we will send you an email with a link to log in.",
        "Recuerda cambiar tu contraseña desde la sección de configuración de tu perfil" : "Remember to change your password from the settings section of your profile"
        
    
    
    
    
    }

}