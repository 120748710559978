import React, { useState, useEffect, useTransition } from 'react';
import Datepicker from "tailwind-datepicker-react"
import PubSub from 'pubsub-js';
import { use } from 'i18next';
import { drop } from 'lodash';
import { useTranslation } from 'react-i18next';
import placeholder from "../../assets/img/placeholder.jpeg";


export default function MsgSearch(props) {
    const { t } = useTranslation()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDate2, setSelectedDate2] = useState('');
    const [selectedItem, setSelectedItem] = useState('All');
    const [inputValue, setInputValue] = useState('');
    const [isReversed, setIsReversed] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const options = {
        autoHide: true,
        todayBtn: true,
        clearBtn: false,
        inputDateFormatProp: {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        },
        defaultDate: null,
        theme: {
            background: "",
            todayBtn: "",
            clearBtn: "",
            icons: "",
            text: "",
            disabledText: "",
            input: "",
            inputIcon: "",
            selected: "",
        },
        language: t("es"),
        weekDays: [t("Lu"), t("Ma"), t("Mi"),t("Ju") ,t("Vi") , t("Sa"), t("Do")],
    };
    useEffect(() => {
        document.addEventListener('click', handleClick)
    }, [])
    const toggleReverse = () => {
        setIsReversed(!isReversed);
    };
    let checkInput
    const handleChange = (selectedDate) => {
        function formatDate(date) {
            const options = {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: '2-digit',

                timeZoneName: 'short'
            };
            return date.toLocaleString('en-US', options).replace(',', '');
        }


        let date = new Date(selectedDate);
        let date2 = new Date(selectedDate);
        setSelectedDate(formatDate(date));
        date2.setDate(date.getDate() + 1);
        setSelectedDate2(formatDate(date2))

        //toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })
        let data = {
            searchDate: [formatDate(date), formatDate(date2)],
            searchItem: selectedItem,
            searchText: inputValue
        }

        console.log('send', data)
        PubSub.publish('MsgSearch', data);
    };



    const handleClose = (state) => {
        console.log(state)
        setShow(state);
    };

    const handleClose2 = (state) => {
        setShow2(state);
    };
    const handleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }

    const handleClick = (event) => {
        // let searchDate
        // if (selectedDate === '' || selectedDate2 === '') {
        //     searchDate = ['', '']
        // } else {
        //     searchDate = [selectedDate, selectedDate2]
        // }
        if (event.target.id !== "dropdown") {
            if (event.target.id == 'dropdown-button') {

            } else {
                setDropdownVisible(false)
            }
        }
        if (event.target.tagName == 'SPAN' && event.target.id) {
            setShow(false)
            return
        }

        if (event.target.tagName == 'svg' || event.target.tagName == 'path') {
            return
        }

        if (event.target.type == undefined && event.target.tagName != 'SPAN' && event.target.type != 'button' && event.target.type != 'text') {

            setShow(false)
            return
        }




    }

    const handleSelectChange = (text) => {
        setSelectedItem(text)
        let data = {
            searchDate: [selectedDate, selectedDate2],
            searchItem: text,
            searchText: inputValue
        }

        console.log('send', data)
        PubSub.publish('MsgSearch', data);
    }

    const handleReset = () => {
        setSelectedDate('')
        setSelectedDate2('')
        setInputValue('')
        setSelectedItem('All')
        let data = {
            searchDate: ['', ''],
            searchItem: 'All',
            searchText: ''
        }
        PubSub.publish('MsgSearch', data);
    }

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        checkInput = newValue

        const data = {
            searchDate: [selectedDate, selectedDate2],
            searchItem: selectedItem,
            searchText: newValue
        };


        setTimeout(() => {
            if (newValue === checkInput) {
                console.log('send', data);
                PubSub.publish('MsgSearch', data);
            } else {
                console.log(newValue, checkInput);
            }
        }, 1000);
    };

    const translate = (text) => {
        switch (text) {
            case 'All':
                return 'Todo'
            case 'Image':
                return 'Imagen'
            case 'Audio':
                return 'Audio'
            case "Document":
                return 'Documento'
            default:
                return text
        }
    }
    return (
        <div style={{ display: 'flex', position: 'absolute', right: '0%', top: '59.98px', padding: '10px', zIndex: '2' }} className={`items-center border-b border-gray-200 dark:border-gray-600 justify-between w-[60%] 2xl:w-[60%] xl:w-[55%] lg:w-[50%] md:w-[45%] sm:w-[40%] transition-transform duration-300 ${isReversed ? 'translate-x-[calc(100%-24px)]' : 'translate-x-0 bg-white dark:bg-gray-900'}`}>
            <div className='inline-flex items-center'>
                {props.conversation.name && <img className="h-12 w-12 flex-none rounded-full bg-gray-50 " src={placeholder} alt="" /> }
                <p className="text-m font-semibold leading-6 text-gray-900 dark:text-white ml-3">{props.conversation.name}</p>
                {/* Tag con el nombre del agente (si es que lo hay) */}
                {props.conversation.agent && <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 ml-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">{props.conversation.agent}</span>}
                {props.conversation.state == "ai" && <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 ml-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-500">{t('AI')}</span>}
            </div>
            <div className='flex'>

            <Datepicker options={options} onChange={handleChange} show={show} setShow={handleClose} classNames='w-[45px] mr-3'>
                <div className="relative w-[50%]">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg className="w-4 h-4 text-primary-600 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                    </div>
                    <input type="text" className="bg-gray-50 w-[45px] h-[42px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onClick={() => setShow(!show)} readOnly />
                </div>
            </Datepicker>
            <div className='max-w-lg mx-auto mr-5'>
                <div className=" flex ">
                    <label htmlFor="search-dropdown" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"></label>
                    <button id="dropdown-button" onClick={handleDropdown} data-dropdown-toggle="dropdown" className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">{t(translate(selectedItem))}
                        <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    <div id="dropdown" className={`z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${dropdownVisible ? 'block' : 'hidden'}`}
                        style={{
                            position: 'absolute',
                            margin: '0px',
                            right: '145px',
                            transform: 'translate3d(31.2px, 62.4px, 0px)'
                        }}>
                        <ul id="dropdown" className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
                            <li id="dropdown">
                                <button id="dropdown" onClick={() => handleSelectChange('All')} type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Todo')}</button>
                            </li>
                            <li id="dropdown" >
                                <button id="dropdown" onClick={() => handleSelectChange('Audio')} type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Audio')}</button>
                            </li>
                            <li id="dropdown">
                                <button id="dropdown" onClick={() => handleSelectChange('Image')} type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Imagen')}</button>
                            </li>
                            <li id="dropdown">
                                <button id="dropdown" onClick={() => handleSelectChange('Document')} type="button" className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{t('Documento')}</button>
                            </li>
                        </ul>
                    </div>
                    <div className="relative w-full">
                        <input value={inputValue} onChange={handleInputChange} type="search" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-primary-500" placeholder="" required />
                        <button onClick={handleReset} className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-primary-700 rounded-e-lg border border-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            <svg className="w-4 h-4 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}
