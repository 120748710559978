import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
    enCountries,
    enRootLayout,
    enProfile,
    enHome,
    enCrearPresupuesto,
    enDatabase,
    enGenerarDocumentos,
    enSimularAhorro,
    enManager,
    enRecuperarPresupuesto,
    enDocumentManager,
    enFormGastos,
    enFormGastos2,
    enFormPrima,
    enFormPrima2,
    enAseguradoraGraph,
    enToast,
    enForgotPassword,
    enLogin,
    enUpdateNotification,
    enWhatsapp,
    enSidebar,
    enMsgbox,
    enChat,
    enToobar,
    enMsgSearch,
    enSendbar,
} from './locales/en/'

const resources = {
    en: {
        translation: {
            ...enRootLayout.translation,
            ...enHome.translation,
            ...enCrearPresupuesto.translation,
            ...enDatabase.translation,
            ...enGenerarDocumentos.translation,
            ...enSimularAhorro.translation,
            ...enManager.translation,
            ...enRecuperarPresupuesto.translation,
            ...enProfile.translation,
            ...enForgotPassword.translation,
            ...enLogin.translation,
            ...enWhatsapp.translation,

            //Components
            ...enDocumentManager.translation,
            ...enFormGastos.translation,
            ...enFormGastos2.translation,
            ...enFormPrima.translation,
            ...enFormPrima2.translation,
            ...enAseguradoraGraph.translation,
            ...enUpdateNotification.translation,
            ...enSidebar.translation,
            ...enMsgbox.translation,
            ...enChat.translation,
            ...enToobar.translation,
            ...enMsgSearch.translation,
            ...enSendbar.translation,

            //Jsons
            ...enCountries.translation,

            //Toast
            ...enToast.translation,

        },
    },
};

const defaultLanguage = "es";

const savedLanguage = localStorage.getItem("language") || defaultLanguage;

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
