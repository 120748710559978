export const enCountries = {
    translation : {
        'Afganistán' : 'Afghanistan',
        'Albania' : 'Albania',
        'Alemania': 'Germany',
        'Andorra': 'Andorra',
        'Angola': 'Angola',
        'Antigua y Barbuda': 'Antigua and Barbuda',
        'Arabia Saudita': 'Saudi Arabia',
        'Argelia': 'Algeria',
        'Argentina': 'Argentina',
        'Armenia': 'Armenia',
        'Australia': 'Australia',
        'Austria': 'Austria',
        'Azerbaiyán': 'Azerbaijan',
        'Bahamas': 'Bahamas',
        'Bangladés': 'Bangladesh',
        'Barbados': 'Barbados',
        'Baréin': 'Bahrain',
        'Bélgica': 'Belgium',
        'Belice': 'Belize',
        'Benín': 'Benin',
        'Bielorrusia': 'Belarus',
        'Birmania/Myanmar': 'Burma/Myanmar',
        'Bolivia': 'Bolivia',
        'Bosnia y Herzegovina': 'Bosnia and Herzegovina',
        'Botsuana': 'Botswana',
        'Brasil': 'Brazil',
        'Brunéi': 'Brunei',
        'Bulgaria': 'Bulgaria',
        'Burkina Faso': 'Burkina Faso',
        'Burundi': 'Burundi',
        'Bután': 'Bhutan',
        'Cabo Verde': 'Cape Verde',
        'Camboya': 'Cambodia',
        'Camerún': 'Cameroon',
        'Canadá': 'Canada',
        'Catar': 'Qatar',
        'Chad': 'Chad',
        'Chile': 'Chile',
        'China': 'China',
        'Chipre': 'Cyprus',
        'Ciudad del Vaticano': 'Vatican City',
        'Colombia': 'Colombia',
        'Comoras': 'Comoros',
        'Corea del Norte': 'North Korea',
        'Corea del Sur': 'South Korea',
        'Costa de Marfil': 'Ivory Coast',
        'Costa Rica': 'Costa Rica',
        'Croacia': 'Croatia',
        'Cuba': 'Cuba',
        'Dinamarca': 'Denmark',
        'Dominica': 'Dominica',
        'Ecuador': 'Ecuador',
        'Egipto': 'Egypt',
        'El Salvador': 'El Salvador',
        'Emiratos Árabes Unidos': 'United Arab Emirates',
        'Eritrea': 'Eritrea',
        'Eslovaquia': 'Slovakia',
        'Eslovenia': 'Slovenia',
        'España': 'Spain',
        'Estados Unidos': 'United States',
        'Estonia': 'Estonia',
        'Etiopía': 'Ethiopia',
        'Filipinas': 'Philippines',
        'Finlandia': 'Finland',
        'Fiyi': 'Fiji',
        'Francia': 'France',
        'Gabón': 'Gabon',
        'Gambia': 'Gambia',
        'Georgia': 'Georgia',
        'Ghana': 'Ghana',
        'Granada': 'Grenada',
        'Grecia': 'Greece',
        'Guatemala': 'Guatemala',
        'Guinea ecuatorial': 'Equatorial Guinea',
        'Guinea-Bisáu': 'Guinea-Bissau',
        'Guinea': 'Guinea',
        'Guyana': 'Guyana',
        'Haití': 'Haiti',
        'Honduras': 'Honduras',
        'Hungría': 'Hungary',
        'India': 'India',
        'Indonesia': 'Indonesia',
        'Irak': 'Iraq',
        'Irán': 'Iran',
        'Irlanda': 'Ireland',
        'Islandia': 'Iceland',
        'Islas Marshall': 'Marshall Islands',
        'Islas Salomón': 'Solomon Islands',
        'Israel': 'Israel',
        'Italia': 'Italy',
        'Jamaica': 'Jamaica',
        'Japón': 'Japan',
        'Jordania': 'Jordan',
        'Kazajistán': 'Kazakhstan',
        'Kenia': 'Kenya',
        'Kirguistán': 'Kyrgyzstan',
        'Kiribati': 'Kiribati',
        'Kuwait': 'Kuwait',
        'Laos': 'Laos',
        'Lesoto': 'Lesotho',
        'Letonia': 'Latvia',
        'Líbano': 'Lebanon',
        'Liberia': 'Liberia',
        'Libia': 'Libya',
        'Liechtenstein': 'Liechtenstein',
        'Lituania': 'Lithuania',
        'Luxemburgo': 'Luxembourg',
        'Macedonia del Norte': 'North Macedonia',
        'Madagascar': 'Madagascar',
        'Malasia': 'Malaysia',
        'Malaui': 'Malawi',
        'Maldivas': 'Maldives',
        'Malí': 'Mali',
        'Malta': 'Malta',
        'Marruecos': 'Morocco',
        'Mauricio': 'Mauritius',
        'Mauritania': 'Mauritania',
        'México': 'Mexico',
        'Micronesia': 'Micronesia',
        'Moldavia': 'Moldova',
        'Mónaco': 'Monaco',
        'Mongolia': 'Mongolia',
        'Montenegro': 'Montenegro',
        'Mozambique': 'Mozambique',
        'Namibia': 'Namibia',
        'Nauru': 'Nauru',
        'Nepal': 'Nepal',
        'Nicaragua': 'Nicaragua',
        'Níger': 'Niger',
        'Nigeria': 'Nigeria',
        'Noruega': 'Norway',
        'Nueva Zelanda': 'New Zealand',
        'Omán': 'Oman',
        'Países Bajos': 'Netherlands',
        'Pakistán': 'Pakistan',
        'Palaos': 'Palau',
        'Panamá': 'Panama',
        'Papúa Nueva Guinea': 'Papua New Guinea',
        'Paraguay': 'Paraguay',
        'Perú': 'Peru',
        'Polonia': 'Poland',
        'Portugal': 'Portugal',
        'Reino Unido': 'United Kingdom',
        'República Centroafricana': 'Central African Republic',
        'República Checa': 'Czech Republic',
        'República del Congo': 'Republic of the Congo',
        'República Democrática del Congo': 'Democratic Republic of the Congo',
        'República Dominicana': 'Dominican Republic',
        'República Sudafricana': 'South Africa',
        'Ruanda': 'Rwanda',
        'Rumania': 'Romania',
        'Rusia': 'Russia',
        'Samoa': 'Samoa',
        'San Cristóbal y Nieves': 'Saint Kitts and Nevis',
        'San Marino': 'San Marino',
        'San Vicente y las Granadinas': 'Saint Vincent and the Grenadines',
        'Santa Lucía': 'Saint Lucia',
        'Santo Tomé y Príncipe': 'Sao Tome and Principe',
        'Senegal': 'Senegal',
        'Serbia': 'Serbia',
        'Seychelles': 'Seychelles',
        'Sierra Leona': 'Sierra Leone',
        'Singapur': 'Singapore',
        'Siria': 'Syria',
        'Somalia': 'Somalia',
        'Sri Lanka': 'Sri Lanka',
        'Suazilandia': 'Eswatini',
        'Sudán del Sur': 'South Sudan',
        'Sudán': 'Sudan',
        'Suecia': 'Sweden',
        'Suiza': 'Switzerland',
        'Surinam': 'Suriname',
        'Tailandia': 'Thailand',
        'Tanzania': 'Tanzania',
        'Tayikistán': 'Tajikistan',
        'Timor Oriental': 'East Timor',
        'Togo': 'Togo',
        'Tonga': 'Tonga',
        'Trinidad y Tobago': 'Trinidad and Tobago',
        'Túnez': 'Tunisia',
        'Turkmenistán': 'Turkmenistan',
        'Turquía': 'Turkey',
        'Tuvalu': 'Tuvalu',
        'Ucrania': 'Ukraine',
        'Uganda': 'Uganda',
        'Uruguay': 'Uruguay',
        'Uzbekistán': 'Uzbekistan',
        'Vanuatu': 'Vanuatu',
        'Venezuela': 'Venezuela',
        'Vietnam': 'Vietnam',
        'Yemen': 'Yemen',
        'Yibuti': 'Djibouti',
        'Zambia': 'Zambia',
        'Zimbabue': 'Zimbabwe'
    }
};