export const enFormPrima2 = {
    translation : {
        "Enviar documentos": "Send documents",
        "para firma": "for signature",
        "al cliente": "to the client",
        "Volver atrás": "Go back",
        "PDF Reclamación de prima única": "PDF Single Premium Claim",
        "¿Quieres modificar el contrato de prima única?": "Do you want to modify the single premium contract?",
        "Enviar": "Send",
        "Enviando...": "Sending...",
        "En breve el cliente recibirá por email los documentos para firmar": "The client will soon receive the documents to sign by email",
        "¡Listo!": "Got it!",
    }
};