export const enFormGastos = {
    translation : {
        "Volver atrás": "Go back",
        "Cantidad de personas": "Number of people",
        "Número de negocio": "Business number",
        "Teléfono": "Phone",
        "Nº de Protocolo": "Protocol number",
        "Fecha de inicio del préstamo": "Loan start date",
        "Notario": "Notary",
        "Nombre y apellidos": "Name and surname",
        "Nombre y apellidos 2": "Name and surname 2",
        "Dirección": "Address",
        "Código Postal": "Postal code",
        "Nombre del banco": "Bank name",
        "Teléfono 2": "Phone 2",
        "Gastos de Notaría (100%)": "Notary expenses (100%)",
        "Gastos de Registro": "Registration expenses",
        "Gastos de Gestoría": "Management expenses",
        "Gastos de Tasación": "Appraisal expenses",
        "Porcentaje de cobro": "Collection percentage",
        "Cambiar cuando sea distinto al 12%": "Change when different from 12%",
        "Imagen Notaría": "Notary image",
        "Imagen Registro": "Registration image",
        "Imagen Gestoría": "Management image",
        "Imagen Tasación": "Appraisal image",
        "Siguiente": "Next",
        "Cargando...": "Loading...",
        "Nombre y Apellidos": "Name and Surname",
        "Agregar archivo adicional": "Add additional file",
    }
}
