/* eslint-disable no-unused-vars */
import { React, useEffect, useState, Fragment, useCallback, useRef } from 'react';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { CircleStackIcon, DocumentChartBarIcon, FunnelIcon, PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { createClient } from '@supabase/supabase-js';
import { debounce } from 'lodash';
import axios from 'axios';
import { useAuth } from "../hooks/useAuth";
import { NavBreadcrumb } from '../components/NavBreadcrumb';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { GetUsuarioXCorreduria } from "../data/GetUsuarioXCorreduria";
import { initFlowbite } from "flowbite";
import states from '../data/states.js';

export const Database = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [num, setNum] = useState(1);
    const navigate = useNavigate();
    const { correduria, user } = useAuth();
    const [open, setOpen] = useState(false);
    const [csvOpen, setCsvOpen] = useState(false);
    const [csvEmail, setCsvEmail] = useState("");
    const [selectedItem, setSelectedItem] = useState({});
    const [page, setPage] = useState(1);
    const [usuarioxcorreduria, setUsuarioxcorreduria] = useState({});
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const [length, setLength] = useState(10);
    const [data, setData] = useState([]);
    const lengthRef = useRef(length);

    const [labels, setLabels] = useState([]);
    const [labelsData, setLabelsData] = useState([{ comando: "WHERE", atributo: "email", condicion: "LIKE", valor: "" }]);

    const [isFilterSearch, setIsFilterSearch] = useState(false);




    useEffect(() => {
        document.title = "Coventia Office | Base de datos"
        initFlowbite();
        const getInitialData = async () => {
            try {
                const supabaseClient = createClient(
                    'https://uoclqlfzojdjrhcqhgef.supabase.co',
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                )
                const { data, error } = await supabaseClient.auth.getSession()
                const access_token = data.session.access_token
                let headers = {
                    'Authorization': access_token,
                    'Content-Type': 'application/json',
                }
                await axios.get('https://api.office.coventia.es/api/bbdd/get_records_datatables/?start=0&length=10&search[value]=&search[filters]=[{"comando":"WHERE","atributo":"email","condicion":"LIKE","valor":""}]', { headers: headers }).then((response) => {
                    if (response.status == 200) {
                        setData(response.data.data)
                        // Hemos recibido response.data.recordsTotal y response.data.recordsFiltered
                        // Debemos sacar cuantas páginas hay en total
                        setTotalPages(Math.ceil(response.data.recordsFiltered / 10))
                        async function getUsuarioXCorreduria() {
                            const usuarioxcorreduria = await GetUsuarioXCorreduria();
                            setUsuarioxcorreduria(usuarioxcorreduria);
                        }

                        if (user) {
                            getUsuarioXCorreduria();
                        }
                        setLoading(false);
                    } else {
                        toast.error(t("Ha ocurrido un error inesperado"))
                        setLoading(false);
                    }
                }).catch((error) => {
                    console.log(error)
                    toast.error(t("Ha ocurrido un error inesperado"))
                    setLoading(false);
                })

            } catch (error) {
                console.log(error)
                toast.error(t("Ha ocurrido un error inesperado"))
                setLoading(false);
            }
        }
        getInitialData()
    }, []);

    const getData = useCallback(async (start, length, search, filters) => {
        try {
            setLoading(true)
            const supabaseClient = createClient(
                'https://uoclqlfzojdjrhcqhgef.supabase.co',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
            )
            const { data, error } = await supabaseClient.auth.getSession()
            const access_token = data.session.access_token
            let headers = {
                'Authorization': access_token,
                'Content-Type': 'application/json',
            }
            let new_start = (start * length) - length
            await axios.get(`https://api.office.coventia.es/api/bbdd/get_records_datatables/?start=${new_start}&length=${length}&search[value]=${search}&search[filters]=${JSON.stringify(filters) || JSON.stringify(labelsData)}`, { headers: headers }).then((response) => { //valor por defecto (TODO?)
                if (response.status == 200) {
                    setData(response.data.data)
                    setTotalPages(Math.ceil(response.data.recordsFiltered / length))
                    setLoading(false);

                    const hasActiveFilters = filters.length > 1 || (filters.length === 1 && filters[0].valor !== "");
                    setIsFilterSearch(hasActiveFilters);
                } else {
                    toast.error(t("Ha ocurrido un error inesperado"))
                    setLoading(false);
                    setIsFilterSearch(false);
                }
            }).catch((error) => {
                console.log(error)
                toast.error(t("Ha ocurrido un error inesperado"))
                setLoading(false);
                setIsFilterSearch(false);
            })
        } catch (error) {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado"))
            setLoading(false);
            setIsFilterSearch(false);
        }

    }, []);

    useEffect(() => {
        lengthRef.current = length;
    }, [length]);

    console.log('asdasdadad', JSON.stringify(labelsData))

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        setPage(1);
        getData(1, length, search, labelsData.length > 0 ? labelsData : []);
    }, [getData, setPage, length, search, labelsData]);

    const debouncedHandleInputChange = useCallback(
        debounce((search, setPage, getData, length, labelsData) => {
            setPage(1);
            getData(1, length, search, labelsData.length > 0 ? labelsData : []);
        }, 500),
        []
    );

    const handleInputChange = useCallback((event) => {
        const searchString = event.target.value;
        setSearch(searchString);
        debouncedHandleInputChange(searchString, setPage, getData, length, labelsData);
    }, [getData, setSearch, setPage, length, labelsData, debouncedHandleInputChange]);

    function getBadgeColor(status) {
        switch (status) {
            case 'Prima unica':
                return 'bg-blue-100 text-blue-800 border-blue-400';
            case 'Calcular presupuesto':
                return 'bg-orange-100 text-orange-800 border-orange-400';
            case 'Lead':
                return 'bg-cyan-100 text-cyan-800 border-cyan-400';
            case 'Formulario Arengu':
                return 'bg-cyan-100 text-cyan-800 border-cyan-400';
            case 'Pendiente de firma':
                return 'bg-cyan-100 text-cyan-800 border-cyan-400';
            case 'Firmado':
                return 'bg-red-100 text-red-800 border-red-400';
            case 'Enviado a Fidelidade':
                return 'bg-yellow-100 text-yellow-800 border-yellow-400';
            case 'Póliza creada':
                return 'bg-yellow-100 text-yellow-800 border-yellow-400';
            default:
                return 'bg-gray-100 text-gray-800 border-gray-400';
        }
    }

    function getUnderlineColor(bool) {
        switch (bool) {
            case 'SI, Pedir Poliza':
                return 'decoration-green-500';
            case 'No debo pedir la póliza':
                return 'decoration-red-500';
            default:
                return 'decoration-gray-500';
        }
    }

    useEffect(() => {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            return;
        }
        var contextMenu = document.getElementById("contextMenu");

        function showContextMenu(event) {
            if (event.target.classList.contains("whitespace-nowrap") && event.target.parentElement.classList.contains("border-b")) {
                event.preventDefault();
                var totalDatabase = document.getElementById("total-database");
                var tbody = document.getElementById("tbody");
                var rectTotalDatabase = totalDatabase.getBoundingClientRect();
                var rectTbody = tbody.getBoundingClientRect();

                var contextMenuWidth = contextMenu.offsetWidth;
                var contextMenuHeight = contextMenu.offsetHeight;

                var x = event.clientX - rectTotalDatabase.left;
                var y = event.clientY - rectTotalDatabase.top;
                y -= 30;
                x -= 10;

                if (x + contextMenuWidth > rectTbody.right - rectTotalDatabase.left) {
                    x = rectTbody.right - rectTotalDatabase.left - contextMenuWidth;
                }
                if (y + contextMenuHeight > rectTbody.bottom - rectTotalDatabase.top) {
                    y = rectTbody.bottom - rectTotalDatabase.top - contextMenuHeight;
                }

                contextMenu.style.top = `${y}px`;
                contextMenu.style.left = `${x}px`;

                contextMenu.style.display = "block";

                // Necesito saber el id del elemento seleccionado
                var id = event.target.parentElement.getAttribute("data-id");
                setSelectedItem(data.find(item => item.id == id));
            }
        }

        function hideContextMenu(event) {
            if (contextMenu && event.target != contextMenu && contextMenu.style.display == "block") {
                contextMenu.style.display = "none";
            }
        }

        document.addEventListener("contextmenu", showContextMenu);
        document.addEventListener("click", hideContextMenu);

        // Limpiar los event listeners cuando el componente se desmonte
        return () => {
            document.removeEventListener("contextmenu", showContextMenu);
            document.removeEventListener("click", hideContextMenu);
        };
    }, [data]);

    async function sendCsvEmail() {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.auth.getSession()
        const access_token = data.session.access_token
        let headers = {
            'Authorization': access_token,
            'Content-Type': 'application/json',
        }
        let email = csvEmail
        if (email == "") {
            toast.error(t("Debes introducir un email"))
            return
        }
        toast.success(t("Se ha solicitado el CSV"))
        setCsvOpen(false)
        let params = {
            email: email
        }
        await axios.get('https://api.office.coventia.es/api/bbdd/send_csv_email/', { headers: headers, params: params }).then((response) => { }).catch((error) => {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado al enviar el CSV"))
        });
    }

    async function deleteRecord(id) {
        const supabaseClient = createClient(
            'https://uoclqlfzojdjrhcqhgef.supabase.co',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data, error } = await supabaseClient.auth.getSession()
        const access_token = data.session.access_token
        let headers = {
            'Authorization': access_token,
            'Content-Type': 'application/json',
        }
        await axios.get(`https://api.office.coventia.es/api/bbdd/delete_record/${id}/`, { headers: headers }).then((response) => {
            if (response.status == 200) {
                toast.success(t("Registro eliminado, recargando..."))
                getData(page, length, search)
            } else {
                toast.error(t("Ha ocurrido un error inesperado al eliminar el registro"))
            }
        }).catch((error) => {
            console.log(error)
            toast.error(t("Ha ocurrido un error inesperado al eliminar el registro"))
        });
    }

    return (
        <>
            <NavBreadcrumb currentPage={[{ text: t("Base de datos"), url: '' }]} />
            <div id='total-database' className="max-w-full mx-4 py-6 sm:mx-auto sm:6 lg:px-8">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                        <div className="w-full md:w-1/2">
                            <form className="flex items-center" onSubmit={handleSubmit}>
                                <label htmlFor="simple-search" className="sr-only">{t('Buscar')}</label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <input
                                        type="search"
                                        id="simple-search"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder={t("Buscar")}
                                        onChange={handleInputChange}
                                    >
                                    </input>
                                </div>

                                {/* FILTRO */}
                                <div className="flex items-center justify-center ml-2 space-x-4 z-10">
                                    <button
                                        id="dropdownDefault"
                                        data-dropdown-toggle="dropdown"
                                        className={`${isFilterSearch ? 'text-white bg-primary-700 hover:bg-primary-800 rounded-lg dark:bg-primary-600 dark:hover:bg-primary-700' : 'text-gray-900 dark:text-gray-400 hover:bg-gray-100 rounded-lg border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:hover:bg-gray-700'} font-medium text-sm px-4 py-2.5 text-center inline-flex items-center`}
                                        type="button"
                                    >
                                        {t("Filtro")}
                                        <FunnelIcon className={`${isFilterSearch ? 'text-white dark:text-white' : 'text-gray-500 dark:text-gray-400'} w-5 h-5 ml-1.5`} />
                                    </button>

                                    <div id="dropdown" className={`w-64 sm:w-96 md:w-96 lg:w-96 xl:w-auto 2xl:w-auto relative z-50 p-5 bg-white rounded-lg shadow dark:bg-gray-700 max-h-96 overflow-y-auto ${loading ? 'hidden' : ''} ${!loading || data.length === 0 ? 'hidden' : ''}`}>
                                        <div className='overflow-x-auto' style={{ display: labels ? "block" : "none" }}>
                                            {labels &&
                                                <>
                                                    {labelsData.map((label, index) => (
                                                        <ul key={index} className="space-y-2 text-sm mb-2" aria-labelledby="dropdownDefault">
                                                            <li className="flex items-center space-x-2 pt-2">
                                                                <div className="mb-4 flex justify-center"
                                                                    id={"label_designados" + index + "_comando"}
                                                                    onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, comando: e.target.value } : item))}
                                                                >
                                                                    {index === 0 ? (
                                                                        <div className='w-12 flex justify-center'>
                                                                            <a className='dark:text-white'>{t("Donde")}</a>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='w-12 flex justify-center'>
                                                                            <a className='dark:text-white'>{t("Y")}</a>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="mb-4">
                                                                    <select id={"label_designados" + index + "_atributo"} className="w-max bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                        onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, atributo: e.target.value } : item))}
                                                                        value={label.atributo}
                                                                    >
                                                                        {!loading && (
                                                                            <>
                                                                                <option value="email">{t("Email")}</option>
                                                                                <option value="estado">{t("Estado")}</option>
                                                                                <option value="fecha_creacion">{t("Fecha de creación")}</option>
                                                                                <option value="id">ID</option>
                                                                                <option value="phone">{t("Teléfono")}</option>
                                                                                <option value="pedir_poliza">{t("¿Debo pedir la póliza?")}</option>
                                                                                <option value="nombre">{t("Nombre")}</option>
                                                                                <option value="apellido_1">{t("Apellido 1")}</option>
                                                                                <option value="apellido_2">{t("Apellido 2")}</option>
                                                                                <option value="dni_texto">{t("DNI")}</option>
                                                                                <option value="fecha_nacimiento">{t("Fecha de nacimiento")}</option>
                                                                                <option value="fecha_fin_seguro">{t("Fecha fin del seguro")}</option>
                                                                                <option value="edad">{t("Edad")}</option>
                                                                                <option value="direccion">{t("Dirección")}</option>
                                                                                <option value="codigo_postal">{t("Código postal")}</option>
                                                                                <option value="importe_poliza_actual">{t("Importe póliza de origen")}</option>
                                                                                <option value="importe_seguro_nuevo">{t("Importe nueva póliza")}</option>
                                                                                <option value="importe_suma_asegurada">{t("Suma asegurada")}</option>
                                                                                <option value="prima_muerte">{t("Prima de muerte")}</option>
                                                                                <option value="prima_invalidez">{t("Prima de invalidez")}</option>
                                                                                <option value="factor_correccion">Factor</option>
                                                                                <option value="tipo_pago">{t("Tipo de pago")}</option>
                                                                                <option value="iban">{t("IBAN")}</option>
                                                                                <option value="cobertura">{t("Cobertura")}</option>
                                                                                <option value="presupuesto">{t("Archivo Presupuesto")}</option>
                                                                                <option value="dni">{t("Archivo DNI")}</option>
                                                                                <option value="dni_trasera">{t("Archivo DNI Trasera")}</option>
                                                                                <option value="poliza_actual">{t("Archivo Póliza Actual")}</option>
                                                                                <option value="recibo_prima_actual">{t("Archivo Recibo")}</option>
                                                                                <option value="sepa_firmado">{t("Archivo SEPA")}</option>
                                                                                <option value="solicitud_firmada">{t("Archivo solicitud")}</option>
                                                                                <option value="cancelacion_firmada">{t("Archivo cancelación")}</option>
                                                                                <option value="ccpp">{t("Archivo CCPP")}</option>
                                                                                <option value="nombre_agente">{t("Nombre Agente")}</option>
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                </div>

                                                                <div className="mb-4">
                                                                    <select
                                                                        id={"label_designados" + index + "_condicion"}
                                                                        className="w-40 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                        onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, condicion: e.target.value } : item))}
                                                                    >
                                                                        {["presupuesto", "dni", "dni_trasera", "poliza_actual", "recibo_prima_actual", "sepa_firmado", "cancelacion_firmada", "solicitud_firmada", "ccpp"].includes(label.atributo) ? (
                                                                            <>
                                                                                <option value="" disabled>{t("Seleccione una opción")}</option>
                                                                                <option value="IS NOT NULL">{t("no está vacío")}</option>
                                                                                <option value="IS NULL">{t("está vacío")}</option>
                                                                            </>
                                                                        ) : label.atributo === "edad" || label.atributo === "importe_poliza_actual" || label.atributo === "importe_seguro_nuevo" ||
                                                                            label.atributo === "importe_suma_asegurada" || label.atributo === "prima_muerte" || label.atributo === "prima_invalidez" ||
                                                                            label.atributo === "factor_correccion" ? (
                                                                            <>
                                                                                <option value="" disabled>{t("Seleccione una opción")}</option>
                                                                                <option value="=">{t("es igual a")}</option>
                                                                                <option value="<>">{t("no es igual a")}</option>
                                                                                <option value=">">{t("mayor que")}</option>
                                                                                <option value="<">{t("menor que")}</option>
                                                                                <option value=">=">{t("mayor o igual a")}</option>
                                                                                <option value="<=">{t("menor o igual a")}</option>
                                                                                <option value="IS NULL">{t("está vacío")}</option>
                                                                                <option value="IS NOT NULL">{t("no está vacío")}</option>
                                                                            </>
                                                                        ) : label.atributo === "fecha_creacion" || label.atributo === "fecha_fin_seguro" || label.atributo === "fecha_nacimiento" ? (
                                                                            <>
                                                                                <option value="" disabled>{t("Seleccione una opción")}</option>
                                                                                <option value="LIKE">{t("es igual a")}</option>
                                                                                <option value="NOT LIKE">{t("no es igual a")}</option>
                                                                                <option value="HOY">{t("es hoy")}</option>
                                                                                <option value="ANTES_HOY">{t("es antes de hoy")}</option>
                                                                                <option value="DESPUES_HOY">{t("es después de hoy")}</option>
                                                                                <option value="IS NULL">{t("está vacío")}</option>
                                                                                <option value="IS NOT NULL">{t("no está vacío")}</option>
                                                                            </>

                                                                        ) : (
                                                                            <>
                                                                                <option value="" disabled>{t("Seleccione una opción")}</option>
                                                                                <option value="LIKE">{t("contiene")}</option>
                                                                                <option value="NOT LIKE">{t("no contiene")}</option>
                                                                                <option value="=">{t("es igual a")}</option>
                                                                                <option value="<>">{t("no es igual a")}</option>
                                                                                <option value="IS NULL">{t("está vacío")}</option>
                                                                                <option value="IS NOT NULL">{t("no está vacío")}</option>
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                </div>


                                                                <div className="mb-4">
                                                                    {label.atributo === "estado" ? (
                                                                        <select
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        >
                                                                            <option value="" disabled selected>{t("Seleccione una opción")}</option>

                                                                            {!loading && states.map((state, index) => (
                                                                                <option key={index} value={state}>{state}</option>
                                                                            ))}
                                                                        </select>

                                                                    ) : label.condicion === "IS NULL" || label.condicion === "IS NOT NULL" || label.condicion === "HOY" ||
                                                                        label.condicion === "ANTES_HOY" || label.condicion === "DESPUES_HOY" ? (
                                                                        <input
                                                                            id={"label_designados" + index + "_valor"}
                                                                            type="text"
                                                                            className="invisible w-max bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                        />

                                                                    ) : label.atributo === "presupuesto" || label.atributo === "dni" || label.atributo === "dni_trasera" ||
                                                                        label.atributo === "poliza_actual" || label.atributo === "recibo_prima_actual" || label.atributo === "sepa_firmado" ||
                                                                        label.atributo === "cancelacion_firmada" || label.atributo === "solicitud_firmada" || label.atributo === "ccpp" ? (
                                                                        <input
                                                                            id={"label_designados" + index + "_valor"}
                                                                            type="text"
                                                                            className="invisible w-max bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                        />

                                                                    ) : label.atributo === "pedir_poliza" ? (
                                                                        <select
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        >
                                                                            <option value="" disabled selected>{t("Seleccione una opción")}</option>
                                                                            <option value="SI, Pedir Poliza">{t("Si, Pedir Poliza")}</option>
                                                                            <option value="NO">No</option>
                                                                        </select>

                                                                    ) : label.atributo === "cobertura" ? (
                                                                        <select
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        >
                                                                            <option value="" disabled selected>{t("Seleccione una opción")}</option>
                                                                            <option value="Fallecimiento por cualquier causa">{t("Fallecimiento por cualquier causa")}</option>
                                                                            <option value="Fallecimiento e invalidez por cualquier causa">{t("Fallecimiento e invalidez por cualquier causa")}</option>
                                                                        </select>

                                                                    ) : label.atributo === "tipo_pago" ? (
                                                                        <select
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        >
                                                                            <option value="" disabled selected>{t("Seleccione una opción")}</option>
                                                                            <option value="Anualmente">{t("Anualmente")}</option>
                                                                            <option value="Semestralmente">{t("Semestralmente")}</option>
                                                                            <option value="Trimestralmente">{t("Trimestralmente")}</option>
                                                                            <option value="Mensualmente">{t("Mensualmente")}</option>
                                                                        </select>

                                                                    ) : label.atributo === "fecha_creacion" || label.atributo === "fecha_fin_seguro" ? (
                                                                        <input
                                                                            type="date"
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        >
                                                                        </input>

                                                                    ) : label.atributo === "fecha_nacimiento" ? (
                                                                        <input
                                                                            type="datetime-local"
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                            max={new Date().toISOString().split("T")[0]} // Establece la fecha máxima a hoy
                                                                        >
                                                                        </input>

                                                                    ) : label.atributo === "importe_poliza_actual" || label.atributo === "importe_seguro_nuevo" ||
                                                                        label.atributo === "importe_suma_asegurada" || label.atributo === "prima_muerte" || label.atributo === "prima_invalidez" ||
                                                                        label.atributo === "factor_correccion" ? (
                                                                        <input
                                                                            type="number"
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                            step="0.01"
                                                                            min="0"
                                                                        >
                                                                        </input>

                                                                    ) : label.atributo === "edad" ? (
                                                                        <input
                                                                            type="number"
                                                                            id={"label_designados" + index + "_valor"}
                                                                            className="w-52 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                            min="0"
                                                                        >
                                                                        </input>

                                                                    ) : (
                                                                        <input
                                                                            id={"label_designados" + index + "_valor"}
                                                                            type="text"
                                                                            className="w-max bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 pr-10 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                                            placeholder={t("Valor")}
                                                                            onChange={(e) => setLabelsData(labelsData.map((item, i) => i === index ? { ...item, valor: e.target.value } : item))}
                                                                            value={label.valor}
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div className="flex flex-wrap items-center mb-4">
                                                                    <button
                                                                        type='button'
                                                                        onClick={() => {
                                                                            if (labelsData.length > 1) {
                                                                                // Eliminar el filtro
                                                                                const updatedLabelsData = labelsData.filter((item, i) => i !== index);
                                                                                // Verificar si el primer elemento ahora es "WHERE"
                                                                                if (updatedLabelsData[0].comando !== "WHERE") {
                                                                                    // Si no lo es, actualizar el primer elemento
                                                                                    updatedLabelsData[0].comando = "WHERE";
                                                                                }
                                                                                // Establecer los nuevos datos de los filtros
                                                                                setLabelsData(updatedLabelsData);
                                                                            }
                                                                        }}
                                                                        className="text-red-700 dark:text-red-700 xs:lg-4"
                                                                    >
                                                                        <TrashIcon className={`dark:text-white w-5 h-5" ${labelsData.length < 2 ? 'invisible' : ''}`} />
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </>
                                            }

                                        </div>
                                        <div className='mt-2 flex items-center w-max'>
                                            <PlusIcon className="w-5 h-5 mr-1 text-primary-600 dark:text-primary-500" />
                                            <a href="#" className="text-primary-600 dark:text-primary-500"
                                                onClick={() => {
                                                    if (labelsData.length < 10) {
                                                        setLabelsData([...labelsData, { comando: "AND", atributo: "email", condicion: "LIKE", valor: "" }]);
                                                    } else {
                                                        toast.error(t("No puedes añadir mas de 10 filtros"));
                                                    }
                                                }}>
                                                {t("Añadir Filtro")}
                                            </a>
                                        </div>
                                        <hr className='my-2' />
                                        <div className='flex justify-between items-end'>
                                            <div>
                                                <button
                                                    type="submit" className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                                >
                                                    {t("Buscar")}
                                                </button>
                                            </div>
                                            <div>
                                                <button type="reset" className="font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:text-white"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setLabelsData([{ comando: "WHERE", atributo: "email", condicion: "LIKE", valor: "" }]);
                                                    }}
                                                >
                                                    {t("Limpiar Filtros")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FILTRO */}
                            </form>
                        </div>

                        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                            <div className="flex items-center space-x-3 w-full md:w-auto">
                                {/* Input para cambiar cuantas filas quieres cargar. Deben haber las siguientes opciones: 10 (seleccionada por defecto), 25, 50 o 100 */}
                                <label htmlFor="rowsPerPage" className="text-sm font-medium text-gray-900 dark:text-white">{t('Mostrar')}</label>
                                <select id="rowsPerPage" name="rowsPerPage" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={(event) => { setLength(event.target.value); setPage(1); getData(1, event.target.value, search, labelsData) }} defaultValue={10} disabled={loading}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            {usuarioxcorreduria && usuarioxcorreduria.staff == true &&
                                <button className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => { setCsvOpen(true); setCsvEmail(user.email) }}>
                                    <DocumentChartBarIcon className="w-5 h-5 mr-1.5 text-gray-500 dark:text-gray-400" />
                                    {t('Solicitar CSV')}
                                </button>
                            }
                        </div>
                        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0 hidden">
                            <div className="flex items-center space-x-3 w-full md:w-auto">
                                <button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown" className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                    <svg className="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                    </svg>
                                    {t('Acciones')}
                                </button>
                                <div id="actionsDropdown" className="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                                    <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                                        <li>
                                            <a href="#" className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                                        </li>
                                    </ul>
                                    <div className="py-1">
                                        <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading &&
                        <>
                            <div className="flex justify-center items-center p-5">
                                <div role="status">
                                    <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">{t('Cargando')}</span>
                                </div>
                            </div>
                        </>
                    }
                    {!loading &&
                        <>
                            {data.length == 0 &&
                                <div className="flex justify-center items-center p-2">
                                    <div className="flex flex-col items-center">
                                        <CircleStackIcon className="w-12 h-12 text-gray-400 dark:white" />
                                        <span className="text-gray-400 dark:white">{t('No hay resultados')}</span>
                                    </div>
                                </div>
                            }
                            {data.length > 0 &&
                                <div className="overflow-x-auto scrollbar scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full">
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Email')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Estado')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Fecha de creación')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">ID</th>
                                                {(correduria.name == 'selfbank' || correduria.name == 'coventia') &&
                                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Web de origen')}</th>
                                                }
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Teléfono')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('¿Debo pedir la póliza?')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Nombre')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Apellido 1')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Apellido 2')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('DNI')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Fecha de nacimiento')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Fecha fin del seguro')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Edad')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Dirección')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Código Postal')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Importe póliza de origen')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Importe nueva póliza')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Suma asegurada')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Prima de muerte')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Prima de invalidez')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">Factor</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Fraccionamiento')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('IBAN')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Cobertura')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo Presupuesto')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo DNI')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo DNI Trasera')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo Póliza Actual')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo Recibo')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo SEPA')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo solicitud')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo cancelación')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Archivo CCPP')}</th>
                                                <th scope="col" className="px-4 py-3 whitespace-nowrap">{t('Propietario')}</th>
                                            </tr>
                                        </thead>
                                        <tbody id='tbody'>
                                            {data.map((item) => (
                                                <tr key={item.id} data-id={item.id} className="border-b dark:border-gray-700 hover:bg-gray-50 cursor-pointer dark:hover:bg-gray-700" onClick={() => { setSelectedItem(item); setOpen(true) }}>
                                                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">{item.email}</th>
                                                    <td className="px-4 py-3 whitespace-nowrap"><span className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 border ${getBadgeColor(item.estado)}`}>{item.estado}</span></td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.fecha_creacion}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.id}</td>
                                                    {(correduria.name == 'selfbank' || correduria.name == 'coventia') &&
                                                        <td className="px-4 py-3 whitespace-nowrap">{item.web_origen}</td>
                                                    }
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.telefono}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap"><span className={`underline ${getUnderlineColor(item.pedir_poliza)}`}>{item.pedir_poliza}</span></td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.nombre}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.apellido_1}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.apellido_2}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.dni}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.fecha_nacimiento}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.fin_seguro}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.edad}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.direccion}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.codigo_postal}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.importe_poliza_origen}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.importe_nueva_poliza}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.suma_asegurada}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.prima_muerte}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.prima_invalidez}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.factor}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.fraccionamiento}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.iban}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.cobertura}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_presupuesto == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_presupuesto} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_dni == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_dni} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_dni_trasera == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_dni_trasera} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_poliza_actual == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_poliza_actual} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_recibo_poliza_actual == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_recibo_poliza_actual} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_sepa == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_sepa} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_solicitud == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_solicitud} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_cancelacion == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_cancelacion} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.archivo_ccpp == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">{t('Archivo no subido')}</span> : <a href={item.archivo_ccpp} target="_blank" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500" onClick={(e) => e.stopPropagation()}>{t('Ver archivo')}</a>}</td>
                                                    <td className="px-4 py-3 whitespace-nowrap">{item.nombre_agente}</td>
                                                    <td className="px-4 py-3 flex items-center justify-end">
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </>
                    }
                    {/* nav debe ser hidden si esta loading o su data.length es 0 */}
                    <nav className={"flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4 " + (loading || data.length == 0 ? "hidden" : "")}>
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                            {t('Mostrando página')} <span className="font-semibold text-gray-900 dark:text-white">{page}</span> {t('de')} <span className="font-semibold text-gray-900 dark:text-white">{totalPages}</span>
                        </span>
                        <ul className="inline-flex -space-x-px text-sm h-8">
                            <li className={`${page <= 1 ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page - 1); getData(page - 1, length, search, labelsData) }}>Anterior</Link>
                            </li>
                            <li className={`${page <= 2 ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page - 2); getData(page - 2, length, search, labelsData) }}>{page - 2}</Link>
                            </li>
                            <li className={`${page <= 1 ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page - 1); getData(page - 1, length, search, labelsData) }}>{page - 1}</Link>
                            </li>
                            <li>
                                {/* Si los anteriores elementos estan ocultos, debemos añadir bordes redondeados a la izquierda. Y si los dos siguientes elementos estan ocultos, debemos añadir bordes redondeados a la derecha */}
                                <Link aria-current="page" className={"flex items-center justify-center px-3 h-8 text-primary-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white " + (page == 1 ? "rounded-l-lg" : "") + (totalPages == page ? "rounded-r-lg" : "")}>{page}</Link>
                            </li>
                            <li className={`${totalPages - page <= 0 ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page + 1); getData(page + 1, length, search, labelsData) }}>{page + 1}</Link>
                            </li>
                            <li className={`${totalPages - page <= 1 ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page + 2); getData(page + 2, length, search, labelsData) }}>{page + 2}</Link>
                            </li>
                            <li className={`${totalPages == page ? "hidden" : ""}`}>
                                <Link className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => { setPage(page + 1); getData(page + 1, length, search, labelsData) }}>{t('Siguiente')}</Link>
                            </li>
                        </ul>
                    </nav>
                    <div id='contextMenu' className="block" style={{ display: 'none', position: 'absolute' }}>
                        <div className="bg-white w-60 border border-gray-300 rounded-lg flex flex-col text-sm py-2 px-2 text-gray-500 shadow-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-400">
                            <div className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-600" onClick={() => { setOpen(true) }}>
                                <svg className="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M5 7.8C6.7 6.3 9.2 5 12 5s5.3 1.3 7 2.8a12.7 12.7 0 0 1 2.7 3.2c.2.2.3.6.3 1s-.1.8-.3 1a2 2 0 0 1-.6 1 12.7 12.7 0 0 1-9.1 5c-2.8 0-5.3-1.3-7-2.8A12.7 12.7 0 0 1 2.3 13c-.2-.2-.3-.6-.3-1s.1-.8.3-1c.1-.4.3-.7.6-1 .5-.7 1.2-1.5 2.1-2.2Zm7 7.2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                </svg>
                                <div className="text-gray-500 ml-2 dark:text-white">{t('Abrir registro')}</div>
                            </div>
                            <div className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-600" onClick={() => { navigate(`/${correduria.name}/recuperar_presupuesto`, { state: { id: selectedItem.id } }) }}>
                                <svg className="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v9.3l-2-2a1 1 0 0 0-1.4 1.4l.3.3h-6.6a1 1 0 1 0 0 2h6.6l-.3.3a1 1 0 0 0 1.4 1.4l2-2V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z" clipRule="evenodd" />
                                </svg>
                                <div className="text-gray-500 ml-2 dark:text-white">{t('Recuperar presupuesto')}</div>
                            </div>
                            {correduria.name == 'coventia' && usuarioxcorreduria.staff == true &&
                                <div className="flex hover:bg-gray-100 py-1 px-2 rounded cursor-default dark:hover:bg-gray-600" onClick={() => { deleteRecord(selectedItem.id) }}>
                                    <svg className="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                                    </svg>
                                    <div className="text-gray-500 ml-2 dark:text-white">{t('Eliminar registro')}</div>
                                </div>
                            }
                            {/* <hr className="my-3 border-gray-300" /> */}
                        </div>
                    </div>
                </div>
                <Dialog open={open} onClose={() => setOpen(false)} transition className="relative z-50 transition duration-300 ease-out data-[closed]:opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                            <CircleStackIcon className="h-6 w-6 text-primary-600 dark:text-primary-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                {selectedItem.email}
                                            </DialogTitle>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    {selectedItem.id}
                                                </p>
                                            </div>
                                            <div className="mt-4">
                                                {/* Aquí deben ir el resto de datos con el título de la columna */}
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Estado:</b> <span className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border ${getBadgeColor(selectedItem.estado)}`}>{selectedItem.estado}</span>
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Fecha de creación:</b> {selectedItem.fecha_creacion}
                                                </p>
                                                {(correduria.name == 'selfbank' || correduria.name == 'coventia') &&
                                                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1" style={{ wordBreak: 'break-word' }}>
                                                        <b>Web de origen:</b> {selectedItem.web_origen}
                                                    </p>
                                                }
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Teléfono:</b> {selectedItem.telefono}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>¿Debo pedir la póliza?:</b> <span className={`underline ${getUnderlineColor(selectedItem.pedir_poliza)}`}>{selectedItem.pedir_poliza}</span>
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Nombre:</b> {selectedItem.nombre}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Apellido 1:</b> {selectedItem.apellido_1}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Apellido 2:</b> {selectedItem.apellido_2}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>DNI:</b> {selectedItem.dni}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Fecha de nacimiento:</b> {selectedItem.fecha_nacimiento}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Fecha fin del seguro:</b> {selectedItem.fin_seguro}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Edad:</b> {selectedItem.edad}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Dirección:</b> {selectedItem.direccion}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Código Postal:</b> {selectedItem.codigo_postal}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Importe póliza de origen:</b> {selectedItem.importe_poliza_origen}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Importe nueva póliza:</b> {selectedItem.importe_nueva_poliza}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Suma asegurada:</b> {selectedItem.suma_asegurada}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Prima de muerte:</b> {selectedItem.prima_muerte}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Prima de invalidez:</b> {selectedItem.prima_invalidez}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Factor:</b> {selectedItem.factor}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Fraccionamiento:</b> {selectedItem.fraccionamiento}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>IBAN:</b> {selectedItem.iban}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Cobertura:</b> {selectedItem.cobertura}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo Presupuesto:</b> {selectedItem.archivo_presupuesto == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_presupuesto} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo DNI:</b> {selectedItem.archivo_dni == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_dni} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo DNI Trasera:</b> {selectedItem.archivo_dni_trasera == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_dni_trasera} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo Póliza Actual:</b> {selectedItem.archivo_poliza_actual == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_poliza_actual} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo Recibo:</b> {selectedItem.archivo_recibo_poliza_actual == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_recibo_poliza_actual} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo SEPA:</b> {selectedItem.archivo_sepa == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_sepa} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo solicitud:</b> {selectedItem.archivo_solicitud == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_solicitud} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo cancelación:</b> {selectedItem.archivo_cancelacion == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_cancelacion} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Archivo CCPP:</b> {selectedItem.archivo_ccpp == '-' ? <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border bg-red-100 text-red-800 dark:border-gray-500">Archivo no subido</span> : <a href={selectedItem.archivo_ccpp} target="_blank" rel="noreferrer" className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border bg-green-100 text-green-800 dark:border-gray-500">Ver archivo</a>}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                    <b>Nombre Agente:</b> {selectedItem.nombre_agente}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-600"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
                <Dialog open={csvOpen} onClose={() => setCsvOpen(false)} transition className="relative z-50  transition duration-300 ease-out data-[closed]:opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl dark:bg-gray-800">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-800">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-primary-400">
                                            <DocumentChartBarIcon className="h-6 w-6 text-primary-600 dark:text-primary-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                {t('Solicitar un CSV completo por email')}
                                            </DialogTitle>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    {t('Te enviaremos al email que nos indiques un archivo CSV con todos los registros de la base de datos.')}
                                                </p>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                                                    {t('Depende del tamaño de tu base de datos, puedes tardar unos minutos en recibir el correo.')}
                                                </p>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="email" className="block text-sm font-semibold text-gray-700 dark:text-gray-300">Email</label>
                                                <input type="email" id="email" name="email" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:text-gray-300" placeholder="Email" defaultValue={user.email} onChange={(e) => setCsvEmail(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-700">
                                    <button
                                        type="submit"
                                        className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto dark:bg-primary-500 dark:hover:bg-primary-400"
                                        onClick={() => sendCsvEmail()}
                                    >
                                        {t('Solicitar CSV')}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                        onClick={() => setCsvOpen(false)}
                                    >
                                        {t('Cancelar')}
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}