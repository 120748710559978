
import React, { createContext, useContext, useEffect, useState } from 'react';

const ColorSchemeContext = createContext();

export function ColorSchemeProvider({ children }) {
  const [colorScheme, setColorScheme] = useState(localStorage.getItem('color-schema'));

  //Se ejecuta al cargar la página y cada vez que se cambia el color
  //Añade un listener al localStorage para seguir sus cambios
  useEffect(() => {
    const updateColorScheme = () => {
      setColorScheme(localStorage.getItem('color-schema'));
    };

    window.addEventListener('storage', updateColorScheme);

    return () => {
      window.removeEventListener('storage', updateColorScheme);
    };
  }, []);

  //Hook personalizado para poder cambiar el tema
  const changeColorScheme = (newColor) => {
    localStorage.setItem('color-schema', newColor);
    setColorScheme(newColor);
  };

  //ColorScheme se puede pasar como contexto a los componentes hijos de ColorSchemeProvider
  return (
    <ColorSchemeContext.Provider value={{ colorScheme, changeColorScheme }}>
      {children}
    </ColorSchemeContext.Provider>
  );
}

export function useColorScheme() {
  return useContext(ColorSchemeContext);
}