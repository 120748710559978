import { useState, useEffect } from 'react';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';


const ApiRequestCreatedPoliciesByUserGraph = () => {
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://api.office.coventia.es/api/bbdd/get_records/month_agentes_created/';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const supabaseClient = createClient(
          'https://uoclqlfzojdjrhcqhgef.supabase.co',
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
        )
        const { data } = await supabaseClient.auth.getSession();
        const access_token = data.session.access_token
        let headers = {
          'Authorization': access_token,
          'Content-type': 'application/json',
        };
        const response = await axios.get(apiUrl, {
          headers: headers
        });
        if (response.status === 200) {
          setApiData(response.data);
          setIsLoading(false);
        } else if (response.status === 500) {
          throw new Error('Error del servidor');
        } else if (response.status === 302) {
          throw new Error('Error de autorización');
        }
        else {
          throw new Error('Error desconocido');
        }
      } catch (error) {
        console.error('Error en la llamada a la API:', error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { apiData, isLoading, error };
};

export default ApiRequestCreatedPoliciesByUserGraph;
