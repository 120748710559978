import React, { useState, useEffect } from 'react';
import MsgBox from './Msgbox';
import SendBar from './Sendbar';
// import SendBar from '../SendBar';
// import MsgSearch from '../MsgSearch';
// import SrcFolder from '../SrcFolder';

import { useTranslation } from 'react-i18next';

const Chat = (props) => {

    const { t } = useTranslation();

    return (
        <div>
            <MsgBox t={t} conversation={props.conversation}/>
            <SendBar/>
            
        </div>
    );
};

export default Chat;
