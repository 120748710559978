/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { MagnifyingGlass } from 'react-loader-spinner'
import { NavBreadcrumb } from "../components/NavBreadcrumb";
import { createClient } from '@supabase/supabase-js';
import { CircleStackIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import 'moment/dist/locale/es';


export const Home = () => {
    const { t, i18n } = useTranslation();
    const { userPublicData, correduria } = useAuth();
    const location = useLocation();
    const [registerCount, setRegisterCount] = useState({});
    const [welcomeMessage, setWelcomeMessage] = useState("")
    moment.locale('es');

    useEffect(() => {
        // Comprueba si la URL contiene /#message=Confirmation+link+accepted.+Please+proceed+to+confirm+link+sent+to+the+other+email esto significa que el usuario ha confirmado su antiguo email y ahora tiene que confirmar el nuevo
        if (window.location.hash == "#message=Confirmation+link+accepted.+Please+proceed+to+confirm+link+sent+to+the+other+email") {
            toast.success(t("¡Ya casi está! Un nuevo email ha sido enviado a tu nuevo correo electrónico. Por favor, confirma tu nuevo email para aplicar el cambio de email."), {
                duration: 15000,
                position: 'top-center',
            })
        }

        const getData = async () => {
            try {
                const supabaseClient = createClient(
                    'https://uoclqlfzojdjrhcqhgef.supabase.co',
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0'
                )
                const { data, error } = await supabaseClient.auth.getSession()
                const access_token = data.session.access_token
                // Get backend url from .env
                let url;
                if (correduria.name == 'coventia') {
                    url = 'https://api.office.coventia.es/api/airtable/gestiones/'
                } else {
                    url = 'https://api.office.coventia.es/api/airtable/' + correduria.name + '/'
                }
                if (error) throw error;
                let headers = {
                    'Authorization': access_token,
                    'Content-Type': 'application/json',
                }
                await axios.get(url, { headers: headers }).then((response) => {
                    if (response.status == 200) {
                        setRegisterCount({
                            "budget": response.data.cp || '0',
                            "pending": response.data.er || '0',
                            "signed": response.data.pa || '0',
                            "policy": response.data.ef || '0',
                            "pending_5": response.data.pa5 || []
                        })
                    } else {
                        toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))
                    }
                }).catch((error) => {
                    toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))
                });

            } catch (error) {
                toast.error(t('Ha ocurrido un error inesperado al cargar los datos de la página.'))
                console.log(error)
            }
        }
        getData()

        var randomWelcomeMessageList = [
            t("Nos alegra verte de nuevo en tu panel de Coventia Office"),
            t("¿Qué tal estás hoy?"),
            t("¿Cómo te va?"),
            t("¿Qué tal va el") + " " + moment().format('dddd') + "?"
        ];

        setWelcomeMessage(randomWelcomeMessageList[Math.floor(Math.random() * randomWelcomeMessageList.length)]);

    }, []);

    document.title = "Coventia Office | Inicio";

    return (
        <>
            <NavBreadcrumb currentPage={[{text:t("Inicio"),url:''}]} />
            <div className="max-w-full mx-4 py-6 sm:mx-auto sm:6 lg:px-8">
                <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">👋&nbsp;&nbsp;{t('Bienvenido de nuevo')} {userPublicData.first_name}.</h2>
                <p className="text-base leading-7 text-gray-600 mb-10 md:mb-0 dark:text-gray-400">{welcomeMessage}</p>
            </div>
            <div className="max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8">
                <h1 className="text-1xl font-semibold text-gray-900 -mb-3 dark:text-white">{t('Número de registros según estado')}</h1>
                <div className="sm:flex sm:space-x-4 sm:mt-0 mt-10">
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 dark:bg-gray-800 dark:border dark:border-gray-600">
                        <div className="bg-white p-5 dark:bg-gray-800">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">{t('Calcular presupuesto')}</h3>
                                    {registerCount.budget ? (
                                        <p className="text-3xl font-bold text-black dark:text-white">{registerCount.budget}</p>
                                    ) : (
                                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 mt-2 w-full animate-pulse"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 dark:bg-gray-800 dark:border dark:border-gray-600">
                        <div className="bg-white p-5 dark:bg-gray-800">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">{t('Pendiente de firma')} </h3>
                                    {registerCount.pending ? (
                                        <p className="text-3xl font-bold text-black dark:text-white">{registerCount.pending}</p>
                                    ) : (
                                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 mt-2 w-full animate-pulse"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 dark:bg-gray-800 dark:border dark:border-gray-600">
                        <div className="bg-white p-5 dark:bg-gray-800">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">{t('Firmado')}</h3>
                                    {registerCount.signed ? (
                                        <p className="text-3xl font-bold text-black dark:text-white">{registerCount.signed}</p>
                                    ) : (
                                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 mt-2 w-full animate-pulse"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 dark:bg-gray-800 dark:border dark:border-gray-600">
                        <div className="bg-white p-5 dark:bg-gray-800">
                            <div className="sm:flex sm:items-start">
                                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">{t('Póliza creada')}</h3>
                                    {registerCount.policy ? (
                                        <p className="text-3xl font-bold text-black dark:text-white">{registerCount.policy}</p>
                                    ) : (
                                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 mt-2 w-full animate-pulse"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8">
                <h1 className="text-1xl font-semibold text-gray-900 mb-8 dark:text-white">{t('Últimos 5 registros pendientes de firma')}</h1>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t('Nombre')}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    {t('Teléfono')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {registerCount.pending_5 && registerCount.pending_5.length == 0 &&
                                <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                    <td colSpan="4">
                                        <div className="flex flex-col justify-center items-center my-8">
                                            <CircleStackIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                                            <span className="text-gray-400 dark:text-gray-600">{t('No hay resultados')}</span>
                                        </div>
                                    </td>
                                </tr>
                            }
                            {registerCount.pending_5 ? registerCount.pending_5.map((register) => (
                                <tr key={register.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {register.email}
                                    </th>
                                    <td className="px-6 py-4">
                                        {register.nombre}
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="inline-flex items-center rounded-md bg-primary-50 px-2 py-1 text-xs font-medium text-primary-600 ring-1 ring-inset ring-primary-500/10 dark:text-primary-900 dark:bg-primary-200">{register.id}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        {register.phone}
                                    </td>
                                </tr>
                            )) :
                                <>
                                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                        <td colSpan="4">
                                            <div className="flex justify-center items-center my-8">
                                                <MagnifyingGlass
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="MagnifyingGlass-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="MagnifyingGlass-wrapper"
                                                    glassColor='#deeaff'
                                                    color='#5b73f9'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}