import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavBreadcrumb } from '../components/NavBreadcrumb'
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from 'react-i18next';
import PubSub, { publish } from 'pubsub-js';
import { useAuth } from "../hooks/useAuth";
import axios from 'axios';
import Sidebar from '../components/whatsapp/Sidebar';
import Chat from '../components/whatsapp/Chat';
import supabaseClient from '../utils/supabaseInit';
const urlUpdateMsg = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/updatemsg`
const urlUserDate = `https://${import.meta.env.VITE_COVENTIA_WA_URL}/userdate`
const { data } = await supabaseClient.auth.getSession()

export const Whatsapp = () => {
  const access_token = data.session.access_token
  const { t, i18n } = useTranslation();
  const [chatHead, setChatHead] = useState('');
  const [user, setUser] = useState('');
  const [notAllowed, setNotAllowed] = useState(true);
  const { correduria, userPublicData } = useAuth();
  const [agent, setAgent] = useState('');
  const { wa_id } = useParams();
  const [conversation, setConversation] = useState("");
  document.title = "Coventia Office | WhatsApp";
  document.documentElement.style.overflowX = 'hidden';

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const wa_id = queryParams.get('wa_id');
    if (wa_id != undefined) {
      axios.get(urlUserDate, {
        params: {
          wa_id: wa_id
        },
        headers: {
          Authorization: access_token
        }
      })
        .then(response => {
          changeChat(wa_id, response.data[0].message_date, response.data[0].agent)
          setConversation({
            name: (response.data[0].username ? response.data[0].username : "Nombre desconocido") + " (" + wa_id + ")",
            agent: response.data[0].agent,
            state: response.data[0].state
          });
        })
    }
  }, []);
  useEffect(() => {
    if (correduria.name == 'coventia' && userPublicData.whatsapp == true) {
      setNotAllowed(false);
    } else {
      setNotAllowed(true);
    }
  }, [correduria.name, userPublicData]);
  const changeChat = async (user, date, agent) => {
    console.log('changechat to', user)
    setChatHead(`${t("Chat con")} ${user}`);
    setUser(user);
    setAgent(agent);
    //console.log(date,'changeagentdate')
    PubSub.publish('ChangeChat', { user: user, date: date, agent: agent });

    //console.log(response);
  };
  return (
    <>
      {notAllowed &&
        <div className="flex justify-center items-center h-96">
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
              <span className="text-gray-400 dark:text-gray-600">{t('No tienes permisos para acceder a esta página')}</span>
            </div>
          </div>
        </div>
      }
      {!notAllowed &&
        <div style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
        <div style={{ zIndex: '1', position: 'relative' }}>
  <NavBreadcrumb currentPage={[{ text: "WhatsApp", url: '' }]}/>
</div>
          <div className="grid grid-cols-3">
            <div className="col-span-0">
              <Sidebar changeChat={changeChat} conversation={conversation} setConversation={setConversation} />
            </div>
            <div>
              <Chat conversation={conversation} setConversation={setConversation} />
            </div>
          </div>
        </div>
      }
    </>

  )

}
