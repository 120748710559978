import { useState } from "react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

export const FileInputWithCheckbox = ({ name, id, label, disabled }) => {
    const [showAdditionalFileInput, setShowAdditionalFileInput] = useState(false);
    const { t } = useTranslation();

    const variants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
    }

    return (
        <div className="col-span-2 sm:col-span-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={id}>{label}</label>
            <input disabled={disabled} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name={name} id={id} type="file"></input>
            <div className="block my-2 text-sm font-medium text-gray-900 dark:text-white">
                <label className="relative inline-flex mt-2 items-center cursor-pointer text-xs text-gray-600 dark:text-gray-400" htmlFor={`${id}_checkbox`}>
                    <input type="checkbox" id={`${id}_checkbox`} name={`${name}_checkbox`} defaultChecked={false} className="sr-only peer" onChange={(e) => setShowAdditionalFileInput(e.target.checked)} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3">{t("Agregar archivo adicional")}</span>
                </label>
            </div>
            <AnimatePresence>
                {showAdditionalFileInput && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ ease: "easeOut", duration: 0.3 }}
                        className="my-2"
                    >
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={`${id}_additional`}>{`${label} Adicional`}</label>
                        <input disabled={disabled} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name={`${name}_additional`} id={`${id}_additional`} type="file"></input>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

FileInputWithCheckbox.propTypes = {
    name: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    disabled: propTypes.bool,
};
