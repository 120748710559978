import { createClient } from "@supabase/supabase-js";

const supabaseUrl: string = 'https://uoclqlfzojdjrhcqhgef.supabase.co';
const supabaseKey: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0';

export const supabaseClient = createClient(supabaseUrl, supabaseKey, {
    auth: {
        autoRefreshToken: true,
        persistSession: true
    }
});
