export const enAseguradoraGraph = {
    translation: {
        "Selecciona la prima de origen": "Select the original premium",
        "Ahorro estimado para una persona de 45 años y que mantiene su seguro hasta los 65 años": "Estimated savings for a 45-year-old person who maintains their insurance until they are 65 years old",
        "Mínimo": "Minimum",
        "Máximo": "Maximum",
        "Precio actual": "Current price",
        "Precio con Coventia": "Price with Coventia",
        "Ahorro": "Savings",
    }
};