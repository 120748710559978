/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import propTypes from 'prop-types';

export const FormPrima2 = ({ apudFile, contratoFile, formValues, setStep, hubspotData }) => {
    const { t } = useTranslation();
    const [step2Loading, setStep2Loading] = useState(false)
    const [editFileInput, setEditFileInput] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        try {
            axios.post('https://hook.eu1.make.com/8a87h4uoguautx0cm15clsjj8ebtbwve', hubspotData, {
            })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        catch (error) {
            console.log(error)
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setStep2Loading(true)

        if (!e.target.reclamacion_prima.files[0]) {
            toast.error(t("Debes subir un archivo de reclamación de prima única"))
            setStep2Loading(false)
            return
        }

        var formData = new FormData()

        if (e.target.contrato_prima.files[0] == undefined) {
            if (contratoFile.blob instanceof Blob) {
                formData.append('contrato', contratoFile.blob, contratoFile.name)
            } else {
                console.error('contratoFile.blob no es un Blob');
                console.log('contratoFile.blob', contratoFile.blob)
                setStep2Loading(false)
            }
        } else {
            formData.append('contrato', e.target.contrato_prima.files[0])
        }
        formData.append('reclamacion', e.target.reclamacion_prima.files[0])
        
        formData.append('cantidad_personas', formValues.cantidad_personas)
        formData.append('nombre', formValues.nombre)
        formData.append('email', formValues.email)
        formData.append('id_negocio', formValues.id_negocio)

        try {
            var response = await axios.post('https://api.office.coventia.es/enviar-solicitud-firma-prima/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    toast.success(t("Solicitud enviada correctamente"))
                    if (response.status == 200) {
                        setSuccess(true);
                        setStep2Loading(false)
                    }
                    else {
                        toast.error(t("Error al enviar la solicitud"))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    toast.error(t("Error al enviar la solicitud"))
                })
                .finally(() => {
                    setStep2Loading(false)
                })
            return response;
        }
        catch (error) {
            console.log(error)
            toast.error(t("Error al enviar la solicitud"))
        }
    }

    return (
        <>
            <div className={(success ? "hidden" : "")}>
                <form onSubmit={handleSubmit} >
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 mb-3">
                        <h2 className="col-span-2 sm:col-span-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🖊&nbsp;&nbsp;{t('Enviar documentos')} <span className=" underline decoration-primary-500">{t('para firma')}</span>, {t('al cliente')}</h2>
                        <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                            {/* Aquí deben ir un botón para crear un nuevo usuario */}
                            <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setStep(1) }}>
                                {t("Volver atrás")}
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 sm:col-span-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('PDF Reclamación de prima única')}</label>
                            <input disabled={step2Loading} accept=".pdf" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" name="reclamacion_prima" id="reclamacion_prima" type="file"></input>
                        </div>

                        <div className="col-span-2 sm:col-span-3 mt-5">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input name="checkbox-visibility" type="checkbox" className="focus:ring-primary-600 h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={() => setEditFileInput(!editFileInput)}></input>
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="checkbox-visibility" className="font-medium text-gray-700 dark:text-gray-400">{t('¿Quieres modificar el contrato de prima única?')}</label>
                                </div>
                            </div>
                        </div>

                        <div className={"col-span-2 sm:col-span-3 " + (editFileInput ? "" : "hidden")}>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">{t('PDF Contrato de prima única')}</label>
                            <input
                                disabled={step2Loading}
                                accept=".pdf"
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                name="contrato_prima"
                                id="contrato_prima"
                                type="file"
                            ></input>
                        </div>
                    </div>

                    <div className="col-span-2 sm:col-span-3 mt-10">
                        <div className={"col-span-2 sm:col-span-3 " + (step2Loading ? "hidden" : "")}>
                            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300">
                                {t('Enviar')}
                            </button>
                        </div>
                        <div className={"col-span-2 sm:col-span-2 " + (step2Loading ? "" : "hidden")}>
                            <button disabled type="button" className={"w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"}>
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                {t('Enviando...')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={"col-span-2 sm:col-span-3 " + (success ? "" : "hidden")}>
                <div className={"max-w-full mx-4 py-14 sm:mx-auto sm:px-6 md:px-8 lg:px-10 xl:px-10 "}>
                    <div className="flex flex-col items-center justify-center mt-12">
                        <svg className="w-12 h-12 text-gray-800 dark:text-white mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <h3 className="text-2xl font-bold text-gray-800 dark:text-white">{t('Solicitud enviada correctamente')}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{t('En breve el cliente recibirá por email los documentos para firmar')}</p>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row items-stretch md:items-center justify-end  flex-shrink-0">
                        <button className="w-full flex justify-center mt-10 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300" onClick={(e) => { e.preventDefault(); setStep(1); setSuccess(false); }}>
                            {t('¡Listo!')}
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}

FormPrima2.propTypes = {
    apudFile: propTypes.object,
    contratoFile: propTypes.object,
    formValues: propTypes.object,
    setStep: propTypes.func,
    hubspotData: propTypes.object
}
