import { createClient } from "@supabase/supabase-js";

const supabaseURL = 'https://uoclqlfzojdjrhcqhgef.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVvY2xxbGZ6b2pkanJoY3FoZ2VmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MDEzMDMsImV4cCI6MjAxMzI3NzMwM30.6VY6hK2MWHngYFt3AP9MkV_1hC0xOIuX0CbYIsC5DT0';
const supabase = createClient(supabaseURL, supabaseKey);

export const ApiRequestCalendarInfo = async () => {
    const { data: users, error } = await supabase
        .from('users')
        .select('user_id, first_name, profile_pic');

    const { data: vacations, error2 } = await supabase
        .from('vacations')
        .select('user_id, start_date, end_date')
        .eq('status', 'Aceptadas');  

    if (error || error2) {
        console.log(error);
        console.log(error2);
    }

    // Filtrar usuarios que tienen vacaciones
    const data = users
        .map(user => {
            const userVacations = vacations
                .filter(vacation => vacation.user_id === user.user_id)
                .map(({ user_id, ...rest }) => rest); // Excluir user_id de vacations
            return {
                ...user,
                vacations: userVacations
            };
        })
        .filter(user => user.vacations.length > 0);

    return data;
}