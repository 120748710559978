import { useState, useEffect } from 'react';
import { Datepicker } from 'flowbite-react';
import { motion, AnimatePresence } from 'framer-motion';
import toast from "react-hot-toast";
import { useAuth } from "../../hooks/useAuth";
import { createClient } from '@supabase/supabase-js';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { supabaseClient } from '../../config/supabase-clients';
import { apiVacationCount } from '../../api/apiVacationCount';

const supabase = supabaseClient;

const RequestVacation = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userVacations, setUserVacations] = useState([]);
    const [festivos, setFestivos] = useState([]);
    const { userPublicData } = useAuth();

    const [records, setRecords] = useState([]);

    useEffect(() => {

        const fetchFestivos = async () => {
            const { data, error } = await supabase
                .from('holidays')
                .select('*');

            if (error) {
                console.error('Error fetching holidays:', error);
                toast.error('Error al obtener los festivos', error);
            } else {
                setFestivos(data);
            }
        }

        fetchFestivos();

    }, []);

    const fetchVacationBalance = async () => {
        const { data, error } = await supabase
            .from('vacation_balance')
            .select('total_days, used_days, remaining_days')
            .eq('user_id', userPublicData.user_id);

        if (error) {
            toast.error('Error al obtener el balance de vacaciones');
        } else {
            setUserVacations(data.map(item => ({
                totalVacations: item.total_days,
                usedVacations: item.used_days,
                remainingVacations: item.remaining_days
                
            })));
        }
    };

    const fetchVacationRecords = async () => {
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1).toISOString();
        const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59).toISOString();

        const { data, error } = await supabase
            .from('vacations')
            .select('*')
            .eq('user_id', userPublicData.user_id)
            .gte('start_date', startOfYear)
            .lte('start_date', endOfYear);


        if (error) {
            console.error('Error fetching vacation records:', error);
            toast.error('Error al obtener los registros de vacaciones');
        } else {
            const parsedData = data.map(record => ({
                ...record,
                startDate: new Date(record.start_date),
                endDate: new Date(record.end_date),

            }));
            setRecords(parsedData);
        }
    };

    useEffect(() => {
        fetchVacationBalance();
        fetchVacationRecords();
    }, [userPublicData.user_id]);

    const countBusinessDays = (startDate, endDate) => {
        let count = 0;
        let currentDate = new Date(startDate);
    
        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();
            // Ver que numero tiene cada día de la semana
            if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Excluir domingos (0) y sábados (6)
                count++;
            }
    
            // Excluir festivos rescatados del useState en el rango de fechas a no ser que estén en fin de semana
            festivos.forEach(festivo => {
                if (currentDate.toISOString().split('T')[0] === festivo.holiday_date && dayOfWeek !== 0 && dayOfWeek !== 6) {
                    count--;
                }
            });
    
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        return count;
    };

    const handleSubmit = async () => {
        const businessDays = countBusinessDays(new Date(startDate), new Date(endDate));

        const existingRecord = records.find(record => {
            const checkStartDate = record.startDate;
            const checkEndDate = record.endDate;
            const checkNewStartDate = startDate;
            const checkNewEndDate = endDate;

            const recordStart = dayjs(checkStartDate);
            const recordEnd = dayjs(checkEndDate);
            const newStart = dayjs(checkNewStartDate);
            const newEnd = dayjs(checkNewEndDate);

            return (newStart.isBetween(recordStart, recordEnd, null, '[]') || newEnd.isBetween(recordStart, recordEnd, null, '[]') || recordStart.isBetween(newStart, newEnd, null, '[]') || recordEnd.isBetween(newStart, newEnd, null, '[]'));
        });

        if (existingRecord) {
            toast.error('Ya existe un registro de ausencia que se solapa con las fechas proporcionadas');
            return;
        }

        if (userVacations[0].totalVacations - userVacations[0].usedVacations < businessDays) {
            toast.error('No tienes suficientes días de vacaciones restantes');
            return;
        }

        if (startDate > endDate) {
            toast.error('La fecha de vuelta no puede ser anterior a la fecha de salida');
            return;
        }

        try {
            // Formatear las fechas antes de enviarlas a la base de datos
            startDate.setHours(23, 59, 59, 999);
            endDate.setHours(23, 59, 59, 999);
            const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
            const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

            // Inserta la solicitud de vacaciones en la tabla Vacations
            const { data: createdVacations, error: vacationError } = await supabase
                .from('vacations')
                .insert([
                    { user_id: userPublicData.user_id, start_date: formattedStartDate, end_date: formattedEndDate, status: 'Pendientes' }
                ])
                .select();

            if (vacationError) {
                throw vacationError;
            }

            // Actualiza el estado local con el ID del nuevo registro llamando a los metodos fetch
            fetchVacationBalance();
            fetchVacationRecords();

            toast.success('Solicitud de vacaciones enviada con éxito');
        } catch (error) {
            console.error('Error al solicitar vacaciones:', error);
            toast.error('Error al solicitar vacaciones');
        }
    };

    const handleDelete = async (index) => {

        try {
            // Eliminar la solicitud de vacaciones
            const { error } = await supabase
                .from('vacations')
                .delete()
                .eq('id', records[index].id);

            if (error) {
                throw error;
            }

            // Fetch de nuevo los registros y el balance de vacaciones
            fetchVacationBalance();
            fetchVacationRecords();
            apiVacationCount();
            toast.success('Solicitud de vacaciones eliminada con éxito');
        } catch (error) {
            console.error('Error al eliminar solicitud de vacaciones:', error);
            toast.error('Error al eliminar solicitud de vacaciones', error);
        }
    };

    return (
        <div className="rounded-md px-4 md:px-8">
            {/* Mostrar el balance de vacaciones */}
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 1 }}
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-2 mb-10 w-full justify-items-center">
                <div className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs">
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Días utilizados</span>
                        <span className="text-2xl py-2 text-black dark:text-white">{userVacations[0]?.usedVacations ?? 0}</span>
                    </div>
                </div>

                <div className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs">
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Días restantes</span>
                        <span className="text-2xl py-2 text-black dark:text-white">{userVacations[0]?.remainingVacations ?? 0}</span>
                    </div>
                </div>

                <div className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs">
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Vacaciones al año</span>
                        <span className="text-2xl py-2 text-black dark:text-white">{userVacations[0]?.totalVacations ?? 0}</span>
                    </div>
                </div>
            </motion.div>

            {/* Tabla de registros de vacaciones */}
            <div className="overflow-x-auto overflow-y-hidden shadow-md relative mt-4 rounded-lg">
                <motion.table
                    key={'tableVacation'}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.5 }}
                    className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de inicio</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de fin</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Días usados</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Estado</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Acciones</th>
                        </tr>
                    </thead>
                    <AnimatePresence>
                        <tbody>
                            {records.length > 0 ? (
                                records.map((registro, index) => (
                                    <motion.tr
                                        key={index + 'estado'}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <td className="py-4 px-4 md:px-6">{new Date(registro.startDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-4 md:px-6">{new Date(registro.endDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-4 md:px-6">{countBusinessDays(registro.startDate, registro.endDate)}</td>
                                        <td className="py-4 px-4 md:px-6">
                                            {registro.status === 'Aceptadas' ? (
                                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 dark:text-green-900 dark:bg-green-200">Aceptadas</span>
                                            ) : registro.status === 'Rechazadas' ? (
                                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10 dark:text-red-900 dark:bg-red-200">Rechazadas</span>
                                            ) : (
                                                <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-600 ring-1 ring-inset ring-yellow-500/10 dark:text-yellow-900 dark:bg-yellow-200">Pendientes</span>
                                            )}
                                        </td>
                                        <td className="py-4 px-4 md:px-6">
                                            {(registro.status === 'Pendientes' || registro.status === 'Rechazadas') && (
                                                <button onClick={() => handleDelete(index)} className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                                    Eliminar
                                                </button>
                                            )}
                                        </td>
                                    </motion.tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="py-4 px-4 md:px-6 text-center">No tienes ningún periodo de vacaciones todavía</td>
                                </tr>
                            )}
                        </tbody>
                    </AnimatePresence>
                </motion.table>
            </div>

            {/* Solicitar vacaciones */}
            <h2 className='text-1xl p-2 font-semibold text-gray-500 dark:text-white mt-2'>Solicitar vacaciones</h2>
            <div className="flex flex-col md:flex-row items-center p-4 bg-gray-100 dark:bg-gray-800 rounded-md shadow-md space-y-4 md:space-y-0 md:space-x-4">
                <Datepicker
                    language='es-ES'
                    className="w-full md:w-1/2"
                    weekStart={1}
                    showClearButton={false}
                    minDate={new Date()}

                    onSelectedDateChanged={(e) => {
                        setStartDate(e);
                    }}
                    labelTodayButton="Hoy"
                />
                <span className="text-gray-500 dark:text-gray-400 mx-2 hidden md:block"> - </span>
                <Datepicker
                    language='es-ES'
                    format="dd/MM/yyyy"
                    labelTodayButton="Hoy"
                    showClearButton={false}
                    minDate={new Date()}
                    className="w-full md:w-1/2"
                    weekStart={1}
                    onSelectedDateChanged={(e) => {
                        setEndDate(e);
                    }}
                />
                <button className="bg-primary-600 text-white px-4 py-2 rounded w-full md:w-auto" onClick={handleSubmit}>Solicitar</button>
            </div>
        </div>
    );
};

export default RequestVacation;
