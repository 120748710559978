export const enFormGastos2 = {
    translation : {
        "Enviar documentos": "Send documents",
        "para firma": "for signature",
        "al cliente": "to the client",
        "Volver atrás": "Go back",
        "PDF Reclamación de gastos": "PDF Claim for expenses",
        "¿Quieres modificar el PDF de APUD o el contrato de gastos?": "Do you want to modify the APUD PDF or the expense contract?",
        "PDF APUD gastos": "PDF APUD expenses",
        "PDF Contrato de prima única": "PDF Expense contract",
        
        "Enviar": "Send",
        "Enviando...": "Sending...",
        "Solicitud enviada correctamente": "Request sent correctly",
        "En breve el cliente recibirá por email los documentos para firmar": "Shortly the client will receive by email the documents to sign",
        "¡Listo!": "Got it!",

    }
}
