export const enCrearPresupuesto = {
    translation : {
        "Nombre" : "Name",
        "Género" : "Gender",
        "Primer apellido" : "First surname",
        "Segundo apellido" : "Second surname",
        "Fecha de nacimiento" : "Date of birth",
        "Teléfono" : "Phone",
        "Email" : "Email",
        "Documento de identidad" : "Identity document",
        "Nacionalidad" : "Nationality",
        "Prima anual actual (en euros)" : "Current annual premium (in euros)",
        "Suma asegurada (en euros)" : "Insured sum (in euros)",
        "Garantía" : "Guarantee",
        "Compañía de origen" : "Origin company",
        "Fecha de efecto" : "Effective date",
        "¿Generar presupuesto?" : "Generate budget?",
        "Siguiente" : "Next",
        "Cancelar" : "Cancel",
        "Enviando..." : "Sending...",
        "Prima anual actual" : "Current annual premium",
        "Beneficiario": "Beneficiary",
        "Nombre del beneficiario": "Beneficiary name",
        "DNI del beneficiario": "Beneficiary ID",
        "Porcentaje del beneficiario": "Beneficiary percentage",
        "Añadir beneficiario": "Add beneficiary",
        "Eliminar beneficiario": "Delete beneficiary",
        "No puedes eliminar el último beneficiario": "You can't delete the last beneficiary",
        "No puedes añadir más de ": "You can't add more than ",
        " beneficiarios": " beneficiaries",
        "Anualmente": "Annually",
        "Semestralmente": "Semi-annually",
        "Trimestralmente": "Quarterly",
        "Mensualmente": "Monthly",
        "En este orden:": "In this order:",
        "1: Cónyuge del Asegurado/a no separado por resolución judicial.": "1: Spouse of the Insured not separated by court order.",
        "2: Hijos supérstites del Asegurado/a a partes iguales.": "2: Surviving children of the Insured in equal parts.",
        "3: Padres supérstites del Asegurado/a a partes iguales.": "3: Surviving parents of the Insured in equal parts.",
        "4: Herederos del asegurado/a.": "4: Heirs of the insured.",

        //Opciones formulario
        "Fallecimiento e Invalidez por cualquier causa" : "Death and Disability from any cause",
        "Fallecimiento por cualquier causa" : "Death from any cause",
        "Hombre" : "Male",
        "Mujer" : "Female",
        "Cláusula estandar" : "Standard clause",

        //Placeholders
        
    }
};