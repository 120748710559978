import React from 'react'
import { useUpdateCheck } from 'react-update-notification'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next';

export const UpdateNotification = () => {
    const { t, i18n } = useTranslation();
    const { status, reloadPage } = useUpdateCheck({
        type: 'interval',
        interval: 10000,
    });

    if (status === 'checking' || status == 'current') {
        return null;
    }

    return (
        <div className="fixed bottom-0 right-0 p-4" style={{zIndex: '999999'}}>
            <div className="bg-white shadow-lg rounded-lg p-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        <p className="ml-2 text-sm text-gray-700">{t('Hay una nueva versión disponible')}</p>
                    </div>
                    <button onClick={reloadPage} className="text-sm text-white bg-primary-500 hover:bg-primary-700 ml-2 py-1 px-2 rounded">
                        {t('Actualizar ahora')}
                    </button>
                </div>
            </div>
        </div>
    )
}