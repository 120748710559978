export const formatDate = (timestamp) => {

    let date = new Date(timestamp * 1000)
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return day + '-' + month + '-' + year


}
export const formatTime = (timestamp) => {

    let date = new Date(timestamp * 1000)
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
    if (min < 10) {
        min = 0 + min.toString()
    }
    return day + '/' + month + '/' + year + ',  ' + hour + ':' + min


}
export const formatClock = (timestamp) => {

    let date = new Date(timestamp * 1000)
    var hour = date.getHours();
    var min = date.getMinutes();
    if (min < 10) {
        min = 0 + min.toString()
    }
    return hour + ':' + min


}
export const formatTimestamp = (date) => {
    const dateString = date;
    const dateObject = new Date(dateString);

    const timestamp = dateObject.getTime() / 1000; 

    return timestamp 
}

// module.exports = {
//     formatDate, formatTime,formatTimestamp,formatClock
// }