const list_countries = [
    "Alemania",
    "Austria",
    "Bélgica",
    "Bulgaria",
    "Chipre",
    "Croacia",
    "Dinamarca",
    "Eslovenia",
    "Estonia",
    "Finlandia",
    "Francia",
    "Grecia",
    "Hungría",
    "Irlanda",
    "Italia",
    "Letonia",
    "Lituania",
    "Luxemburgo",
    "Malta",
    "Países Bajos",
    "Polonia",
    "Portugal",
    "República Checa",
    "Eslovaquia",
    "Rumanía",
    "Suecia",
    "Islandia",
    "Liechtenstein",
    "Noruega",
    "Suiza"
  ];
  
  export default list_countries;
  