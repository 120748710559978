import { useState, useRef, useEffect } from 'react'
import React from 'react'
import ColorPiker from './ColorPiker'
import { Link } from "react-router-dom";
import axios from 'axios';
import supabaseClient from '../../utils/supabaseInit';
import PubSub from 'pubsub-js';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';

const baseURL = `https://${import.meta.env.VITE_COVENTIA_WA_URL}`;
const { data } = await supabaseClient.auth.getSession()

export default function Toolbar(props) {
    const access_token = data.session.access_token
    const { t } = useTranslation(); // React-i18next hook for translation
    const [isVisible, setIsVisible] = useState(false);
    const [selectedColor, setSelectedColor] = useState('')
    const [userColor, setUserColor] = useState('')
    const [userName, setUserName] = useState('')
    const [inputValue, setInputValue] = useState('')
    const popoverRef = useRef(null);


    //const [isPopoverOpen, SetPopoverOpen] = useState(false)
    useEffect(() => {
        // 定义全局点击事件处理程序
        getColor()

        // 添加全局点击事件监听器
        document.addEventListener('click', handleClick);
        // 在组件卸载时清除事件监听器
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []); // 只在组件挂载和卸载时执行

    const handleClick = (event) => {
        if (event.target.id !== 'addbutton') {
            setIsVisible(false)
        }
    }
    const popoverClick = () => {
        setIsVisible(!isVisible)
    }
    const getColor = async () => {
        try {

            const { data } = await supabaseClient.auth.getSession();
            const token = data.session.access_token
            const axiosInstance = axios.create({
                baseURL,
                headers: {
                    'Authorization': access_token,
                },
                params: {
                    token,
                },
            });
            const response = await axiosInstance.get('/getuserinfo');
            setSelectedColor(response.data.color)
            setUserColor(response.data.color)
            setUserName(response.data.first_name)
            PubSub.publish('AgentName', response.data.first_name)
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
        }
    }

    const addUser = async (wa_id) => {
        if (wa_id == '') {
            return
        }
        let regex = /^(?:(?:\+|00)?34[\s-]?)?[6-9]\d{8}$/;
        let regularWa_id = wa_id.replace(/\+/g, "").replace(/[\s-]/g, "")
        if (!regularWa_id.startsWith("34")) {
            regularWa_id = '34' + regularWa_id
        }

        if (!regex.test(regularWa_id)) {
            return
        }
        const param = {
            wa_id: regularWa_id,
        };
        const headers = {

            'authorization': access_token,

        };

        try {
            const response = await axios.post(baseURL + '/createuser', param, { headers });

            if (response.status === 200) {
                // 处理200状态码的响应
                PubSub.publish('moveToNewUser', { wa_id: regularWa_id })
                props.setConversation({
                    name: `Nombre desconocido (${regularWa_id})`,
                    agent: '',
                    state: 'agent'
                  });
                const textToDisplay = t('¡El usuario ha sido creado! ahora estás hablando con')
                toast(`${textToDisplay} ${regularWa_id}`)
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {

                    toast.error(t('El usuario Existe!'))
                } else {
                    toast.error(`Error`)
                }
            } else {
                toast.error(`Error`)
            }
        }
    }
    const handelInputChange = (event) => {
        setInputValue(event.target.value)
    }
    // const setPopover = () => {
    //     const popover = popoverRef.current;
    //     if (popover) {
    //         const popoverRect = popover.getBoundingClientRect();
    //         const newLeft = popoverRect.left - 70; // 向右偏移 20 像素
    //         popover.style.left = `${newLeft}px`;
    //     }
    // }
    //style={{ display: 'flex', position: 'absolute', left: '0%', top: '87vh', padding: '10px', }}


    return (
        <div >
            {/*             
       
               <button  id='popover1' onClick={togglePopover} data-popover-trigger="click" type="button" className="text-white w-[70px] bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-0 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Agent</button>
                {isVisible && (
                    <div data-popover id="popover1" role="tooltip" className="absolute top-0 left-0  mt-[-140px] z-10 inline-block w-100 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                        <div id="popover1" className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                            <h3 id='popover1' className="font-semibold text-gray-900 dark:text-white">Agent info:</h3>
                        </div>
                        <div id="popover1" className="px-3 py-2 w-[300px]">
                            <ColorPiker id="popover1" currentColor={getCurretColor} initDisplay={userColor} />
                            <div id='popover1' style={{ display: 'flex' }}>
                                <div id='popover1' className="relative w-[80%] mt-2">
                                    <div id='popover1' className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        {userColor === ''
                                            ?
                                            <div id='popover1' role="status">
                                                <svg id='popover1' aria-hidden="true" className="w-[20px] h-[20px] mb-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>

                                            </div>
                                            :
                                            <svg id='popover1' className='mb-2' width="20" height="20">
                                                <rect width="20" height="20" rx="4" ry="4" fill={selectedColor} />
                                            </svg>
                                        }

                                    </div>
                                    <Link id="popover1" to="/auth/profile">
                                        <input 
                                            type="text"
                                            id='popover1'
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder={userName === '' ? 'Loading...' : userName}
                                            required
                                        />
                                    </Link>
                                </div>
                                <button id="popover1" onClick={() => { updateUser() }} type="submit" className="mt-2 mb-2 p-2.5 ms-2 text-sm font-medium text-white bg-primary-700 rounded-lg border border-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                    Save
                                </button>
                            </div>
                        </div>
                        <div data-popper-arrow></div>
                    </div>
                )}
                */}


            {/* {Add button} */}
            <button id='addbutton' data-popover-trigger="" type="button" onClick={() => popoverClick()} className=" ml-2 bg-gray-50 w-[40px] h-[40px] xl:w-[50px] xl:h-[50px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-[100%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
        
                <svg id='addbutton' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-white dark:text-gray-600 fill-primary-600 dark:fill-primary-400">
                    <path id='addbutton' fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                </svg>

            </button>
            <div data-popover id='addbutton1' role="tooltip"
                className={`${isVisible ? 'visble' : 'invisible'} absolute left-[500px] z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'} `}
            >
                <div id='addbutton' className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <h3 id='addbutton' className="font-semibold text-gray-900 dark:text-white">{t('Agregar nueva conversación')}</h3>
                </div>
                <div id='addbutton' className="px-3 py-2">
                    <label id='addbutton' htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{t('Buscar')}</label>
                    <div id='addbutton' className="relative">
                        <div id='addbutton' className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg id='addbutton' className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path id='addbutton' fillRule="evenodd" d="M7 2a2 2 0 0 0-2 2v1a1 1 0 0 0 0 2v1a1 1 0 0 0 0 2v1a1 1 0 1 0 0 2v1a1 1 0 1 0 0 2v1a1 1 0 1 0 0 2v1a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H7Zm3 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm-1 7a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3 1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <input id='addbutton' onChange={handelInputChange} value={inputValue} type="search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={t("Agregar un número de teléfono")} required />
                        <button id='addbutton' onClick={() => { addUser(inputValue) }} type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">+</button>
                    </div>
                </div>
                <div id='addbutton' data-popper-arrow></div>
            </div>

        </div>

    )
}


